<template>
  <div class="home">
    <div class="main-container">
      <div class="flex-container">
        <div class="sidebar" style="height: 100vh!important;">
          <div class="header" style="height: 5vh!important;">
            <div style="display: flex;height: 5vh">
              <div style="width: 10%" title="退出">
                <p class="logout" @click="logout">
                  <img src="../assets/logout.svg" style="align-self: center; transform: rotate(180deg);">
                </p>
              </div>
              <div style="width: 18.5%;" title="会员等级">
                <p class="limitePoints" v-if="level === 0"><i class="el-icon-timer"></i>：null</p>
                <p class="limitePoints" v-else-if="level === 1"><i class="el-icon-timer"></i>：vip</p>
                <p class="limitePoints" v-else-if="level === 2"><i class="el-icon-timer"></i>：svip</p>
              </div>
              <div style="width: 18.5%;" title="点数">
                <p class="points" v-if="point.totalPoints > 50000"><i class="el-icon-coin"></i>：<span style="font-size: 20px;">∞</span></p>
                <p class="points" v-else><i class="el-icon-coin"></i>：{{ point.totalPoints }}</p>
              </div>
              <div style="width: 18.5%;" title="大图点数">
                <p class="points"><i class="el-icon-star-off"></i>：{{ point.bigPoints }}</p>
              </div>
              <div style="width: 18.5%;"  @click="showQuest" title="当前模型">
                <p class="moxing"><i class="el-icon-s-help"></i>：nai3</p>
              </div>
              <div style="width: 8%;" @click="openPersonalCenterForm" title="个人中心">
                <p class="moxing"><i class="el-icon-s-home"></i></p>
              </div>
              <div style="width: 8%;"  @click="showQuest" title="帮助">
                <p class="moxing"><i class="el-icon-question"></i></p>
              </div>
            </div>
          </div>
          <div class="hear-container-left-top" style="height: 85vh!important;">
            <div class="header-item-Prompt">
              <div style="height: 37vh!important;">
                <div class="divprompt">
                  <textarea
                      placeholder="正面提示词Prompt:"
                      v-model="form.input"
                      ref="input"
                      class="prompt"
                      @blur="onBlur"
                  ></textarea>
                </div>
                <div class="divUndesired">
                  <textarea placeholder="负面提示词Undesired Content:" v-model="form.parameters.negative_prompt" class="Undesired"></textarea>
                </div>
              </div>
              <!--              style="overflow:scroll"-->
              <div style="height: 48vh!important; overflow: auto;">
                <div class="div-hear-step">
                  <div style="height: 4vh;display: flex;    box-sizing: border-box;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;">
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="saveDiaryDialog" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        保存tag到日记
                      </button>
                    </div>
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="unUserd" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        一键负面
                      </button>
                    </div>
                    <div>
                      <button @click="clearAllpromot" style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        一键清空
                      </button>
                    </div>
                    <div>
                      <!--                      <button @click="clearInput" style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;"  onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">-->
                      <!--                        看图tag-->
                      <!--                      </button>-->
                      <button @click="showImageExitra" style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;"  onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        看图tag
                      </button>
                    </div>
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="downloadImage" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        <span style="color: cyan;">下载图片</span>
                      </button>
                    </div>
<!--                    <div>-->
<!--                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="downloadImage" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">-->
<!--                        <span style="color: cyan;">下载图片2</span>-->
<!--                      </button>-->
<!--                    </div>-->
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border-style: solid; border-color: #646970; border-width: 2px 4px 2px 2px; padding-left: 1px; padding-right: 1px;" @click="downloadAllImages" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        <span style="color: cyan;">打包下载</span>
                      </button>
                    </div>
                    <div @click="showShopping=true" style="border-style: solid; border-color: #646970; border-width: 2px 4px 2px 2px;display: flex; justify-content: center; align-items: center; height: 100%;">
                      <el-icon name="s-shop" style="
    font-size: 22px;
    cursor: pointer;
    color: white;
    font-weight: bold;" ></el-icon>
                    </div>
                    <div @click="showShengDuChaoFeng=true" style="display: flex; justify-content: center; align-items: center; height: 100%;padding-top: 2px;">
                      <el-icon name="s-check" style="
    font-size: 22px;
    cursor: pointer;
    color: white;
    font-weight: bold;" ></el-icon>
                    </div>
                    <div @click="showAutoQueue=true" style="display: flex; justify-content: center; align-items: center; height: 100%;padding-top: 2px;">
                      <el-icon name="s-order" style="
    font-size: 22px;
    cursor: pointer;
    color: white;
    font-weight: bold;" ></el-icon>
                    </div>
                  </div>
                  <div style="height: 4vh;display: flex;    box-sizing: border-box;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;">
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="setOfficeRandom" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        设置随机tag
                      </button>
                    </div>
                    <div>
                      <el-checkbox v-model="customRandom" @change="handleRandomCheckChange" class="custom-checkbox">
                        自定义随机tag
                      </el-checkbox>
                    </div>
                    <div>
                      <button style="overflow: hidden;height: 4vh; background-color: transparent; color: white; border: 2px solid #646970; padding-left: 1px; padding-right: 1px;" @click="setprefixAndSuffix" onmouseover="this.style.color='#409EFF';" onmouseout="this.style.color='white';">
                        抽取/队列中固定前后缀
                      </button>
                    </div>
                    <div>
                      <el-checkbox v-model="bigPicture" @change="handleCheckChange" class="custom-checkbox">
                        大图模式
                      </el-checkbox>
                    </div>
                    <div @click="showSuitcase=true" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                      <el-icon name="suitcase" style="
    font-size: 22px;
    cursor: pointer;
    color: white;
    font-weight: bold;" ></el-icon>
                    </div>
                  </div>
                  <div class="div-em-step">
                    <div class="div-in-step">
                      <h3 class="h3-step">Step :</h3>
                    </div>
                    <div class="step-div">
                      <el-slider class="step-slider" :min=1 :max="sliderMax" v-model="form.parameters.steps"></el-slider>
                    </div>
                  </div>
                  <div class="div-div-guidance">
                    <div class="div-guidance">
                      <h6 class="h6-guidance">Prompt Guidance:</h6>
                    </div>
                    <div class="div-guidance-slider">
                      <el-slider class="slider-guidance" :min=1 :max=100 v-model="guidance" :format-tooltip="formatTooltip"></el-slider>
                    </div>
                  </div>
                  <div class="div-div-el-seed">
                    <div>
                      <el-row class="el-row-seed">
                        <el-col :span="6">
                          <div class="el-col-seed">
                            <input placeholder="Seed:" v-model="form.parameters.seed" class="seed">
                          </div>
                        </el-col>
                        <el-col :span="7">
                          <div class="div-select-size">
                            <select v-show="!bigPicture" v-model="size" @change="handleSizeChange" class="select-size">
                              <option value="长图" style="color: white;">832x1216</option>
                              <option value="宽图" style="color: white;">1216x832</option>
                              <option value="正方形" style="color: white;">1024x1024</option>
                              <option value="等比1080长图" style="color: white;">1280x768</option>
                              <option value="等比1080竖图" style="color: white;">768x1280</option>
                              <option value="等比1024竖图小图" style="color: white;">768x1024</option>
                              <option value="等比1024长图小图" style="color: white;">1024x768</option>
                              <option value="1:2长图" style="color: white;">1280x640</option>
                              <option value="1:2宽图" style="color: white;">640x1280</option>
                              <option value="抱枕1:3" style="color: white;">576x1728</option>
                              <option value="抱枕1:2.5" style="color: white;">640x1600</option>
                              <option value="竖条1500" style="color: white;">704x1472</option>
                              <option value="横条1500" style="color: white;">1472x704</option>
                              <option value="测试" style="color: white;">128x128</option>
                            </select>
                            <select v-show="bigPicture" v-model="size" @change="handleBigSizeChange" class="select-size">
                              <option value="长图一" style="color: white;">3072x1024</option>
                              <option value="宽图一" style="color: white;">1024x3072</option>
                              <option value="正方一" style="color: white;">1728x1728</option>
                              <option value="长图二" style="color: white;">4096x768</option>
                              <option value="宽图二" style="color: white;">768x4096</option>
                              <option value="长图三" style="color: white;">2048x1536</option>
                              <option value="宽图三" style="color: white;">1536x2048</option>
                              <option value="宽图四"  selected style="color: white;">1088x1920</option>
                              <option value="长图四" style="color: white;">1920x1088</option>
                              <option value="正方二" style="color: white;">1472x1472</option>
                              <option value="长图1:2" style="color: white;">2432x1216</option>
                              <option value="宽图1:2" style="color: white;">1216x2432</option>
                              <option value="抱枕1:3" style="color: white;">960x2880</option>
                            </select>
                          </div>
                        </el-col>
                        <el-col :span="7">
                          <div>
                            <select v-model="form.parameters.sampler" @change="handleBigSamplerChange" class="select-euler">
                              <option value="k_euler" style="color: white;">Euler</option>
                              <option value="k_euler_ancestral" style="color: white;">Euler Ancestral</option>
                              <option value="k_dpmpp_2s_ancestral" style="color: white;">DPM++ 2S Ancestral</option>
                              <option value="ddim_v3" style="color: white;">DDIM</option>
                              <option value="k_dpmpp_2m" style="color: white;">DPM++2M</option>
                              <option value="k_dpmpp_sde" style="color: white;">DPM++SDE</option>
                              <option value="k_dpmpp_2m_sde" style="color: white;">DPM++2MSDE</option>
                            </select>
                          </div>
                        </el-col>
                        <el-col :span="4">
                          <div class="div-upload-icon">
                            <div class="upload-icon">
                              <i class="el-icon-setting" style="color: white;" @click="officeInputSet"></i> <!-- 设置图标颜色为白色 -->
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="div-smea-checkbox">
                    <el-row class="div-smea-row">
                      <el-col :span="6">
                        <el-row>
                          <el-col :span="12">
                            <div>
                              <input type="checkbox" id="smeaCheckbox" v-model="form.parameters.sm" @change="toggleDynCheckbox" style="width: 10px; height: 10px;">
                              <label for="smeaCheckbox" class="labal-smea" style="font-size: 12px;">SMEA</label>
                            </div>
                          </el-col>
                          <el-col :span="12" v-if="form.parameters.sm">
                            <div>
                              <input type="checkbox" id="dynCheckbox" v-model="form.parameters.sm_dyn" :disabled="!form.parameters.sm" style="width: 10px; height: 10px;">
                              <label for="dynCheckbox" class="labal-dyn" style="font-size: 12px;">DYN</label>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="6">
                        <div style="border-left: 2px solid #646970; height: 5vh; display: flex; align-items: center; justify-content: center;">
                          <button style="background: transparent; border: none; color: white" @click="advanceSet">高级设置</button>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="div-div-zhidong">
                          <div style="margin: auto;">
                            <div v-if="queueModeEnabled">
                              <input type="checkbox" id="queueModeCheckbox" v-model="isqueueModeEnabled">
                              <label for="queueModeCheckbox">队列模式{{isnonEmptyQueueLength}}队/{{isqueueRepeatCount}}个</label>
                            </div>
                            <div v-else>
                              <input type="checkbox" id="liangXuCheckbox" v-model="isLiangXuChecked">
                              <label for="autoModeCheckbox" class="div-labal-zhidong">自动模式:{{ customZhidong }}/{{ zhidongSize }}</label>
                            </div>
                          </div>
                        </div>
                      </el-col>


                    </el-row>
                  </div>
                  <div class="div-smea-checkbox">
                    <el-row class="div-smea-row">
                      <el-col :span="12">
                        <el-row style="height: 5vh">
                          <el-col :span="12" style="height: 5vh;display: flex; justify-content: center; align-items: center;">
                            <div style="margin: auto ;">
                              <input type="checkbox" id="riQiCheckbox" v-model="riQi">
                              <label for="riQiCheckbox" class="div-labal-zhidong">日期为图名</label>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="div-div-zhidong">
                              <div style="margin: auto ;">
                                <input type="checkbox" id="zhidondownCheckbox" v-model="zhidonDown">
                                <label for="zhidondownCheckbox" class="div-labal-zhidong">自动下载</label>
                              </div>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="12">
                        <div class="div-div-zhidong">
                          <el-col :span="12" style="height: 5vh; width: 100%;display: flex; justify-content: center; align-items: center;">
                            <div class="">
                              <div style="align-self: center">
                                <input type="file" id="myFile" accept="image/*" style="display: none;" @change="extractMetadata">
                                <label for="myFile" id="fileLabel">提取图片tag</label>
                              </div>
                            </div>
                          </el-col>
                          <el-col :span="12" style="height: 5vh; display: flex; justify-content: center; align-items: center; font-size: 12px;">
                            <div class="div-div-zhidong">
                              <div style="margin: auto ;">
                                <input type="checkbox" id="seedCheckbox" v-model="isSeed">
                                <label for="seedCheckbox" class="div-labal-zhidong">提取seed和配置?</label>
                              </div>
                            </div>
                          </el-col>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="display: flex">
                  </div>
                  <div >
                    <el-row style="box-sizing: border-box;
                          border-width: 0px 0px 2px 0px;
                          border-style: solid;
                          border-color: #646970;">
                      <el-col :span="10">
                        <div class="divFangyidesired">
                          <textarea placeholder="被翻译内容:(百度翻译)" v-model="inputText" class="Undesired"></textarea>
                        </div>
                      </el-col>
                      <el-col :span="10">
                        <div class="divFangyidesired">
                          <textarea placeholder="翻译结果:" v-model="outputText" class="Undesired"></textarea>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div >
                          <div>
                            <button style="width: 100%; background-color: #13152c; color: white; transition: color 0.3s ease;" @click="translateText('zh', 'en')">中译英</button>
                          </div>
                          <div>
                            <button style="width: 100%; background-color: #13152c; color: white; transition: color 0.3s ease;" @click="translateText('en', 'zh')">英译中</button>
                          </div>
                          <div>
                            <button style="width: 100%; background-color: #13152c; color: white; transition: color 0.3s ease;" @click="inputPromt">结果放入promot</button>
                          </div>
                          <div>
                            <button style="width: 100%; background-color: #13152c; color: white; transition: color 0.3s ease;" @click="clearText">清空</button>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div style="height: 35vh; ">
                  <div style="display: flex;    box-sizing: border-box;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: #646970;">
                    <div style="width: 30%;">
                      <div style="padding-bottom: 3px; width: 100%;">
                        <input type="file" accept="image/*" id="uploadBtn" @change="handleUpload" style="display: none;">
                        <label for="uploadBtn" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">传图图生图</label>
                      </div> 
                      <div style="padding-bottom: 3px; width: 100%;">
                        <input type="file" accept="image/*" id="uploadBtnJu" @change="handleUpload" style="display: none;">
                        <label for="uploadBtnJu" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">传图局部重绘</label>
                      </div>
                      <div style="padding-bottom: 8px">
                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="toimg2img">对当前图进图生图</button>
                      </div>
                      <div style="padding-bottom: 8px">
                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="toinfill">对当前图局部重绘</button>
                      </div>
                      <div v-if="actionType==='infill'&&!bigInfillModeEnabled" style="padding-bottom: 12px;height: 2vh;" >
                        <div style="width: 100%;display: flex;background-color: #646970">
                          <div style="width: 80%">
                            <button @click="drawingModing" style="width: 100%;padding: 0px; border: 2px solid #646970;font-size: 12px;">点击使用画笔</button>
                          </div>
                          <i class="el-icon-setting" style="color: white; width: 20%; text-align: center;padding: 0px; border: 2px solid #646970;" @click="brushSet"></i>
                        </div>

                      </div>
                      <div v-if="bigInfillModeEnabled" style="padding-bottom: 12px;height: 2vh;" v-show="actionType==='infill'">
                        <div style="width: 100%;display: flex;background-color: #646970">
                          <div style="width: 40%">
                            <button @click="drawingModing" style="width: 100%;padding: 0px; border: 2px solid #646970;font-size: 12px;">用画笔</button>
                          </div>
                          <div v-show="bigInfillModeEnabled" style="width: 40%">
                            <button @click="drawSelection" style="width: 100%;padding: 0px; border: 2px solid #646970;font-size: 12px;">选取框</button>
                          </div>
                          <i class="el-icon-setting" style="color: white; width: 20%; text-align: center;padding: 0px; border: 2px solid #646970;" @click="brushSet"></i>
                        </div>

                      </div>
                      <div style="display: flex; align-items: center; padding-bottom: 8px">
                        <button style="width: 40%; padding: 1px; border: 2px solid #646970; margin-right: auto;" @click="clearimg2img">清空</button>
                        <button style="width: 40%; padding: 1px; border: 2px solid #646970; margin-left: 5px; margin-right: 5px;" @click="resetDrawing">重涂</button>
                        <button style="width: 20%; padding: 1px; border: 2px solid #646970; display: flex; justify-content: center; align-items: center;" @click="showBigInfill=true">
                          <el-icon name="setting" style="color: black; font-size: medium;"></el-icon>
                        </button>
                      </div>

                      <div style="padding-bottom: 8px">
                        <button style="width: 100%; padding: 1px; border: 2px solid #646970;" @click="sureXitu">细涂(测试中</button>
                      </div>
                      <div style="background-color: #646970">
                        <p style="font-size: 10px;color: white">自适应尺寸</p>
                        <input :value="i2iwidth" style="width: 30%; background-color: transparent; border: none; outline: none; color: white; text-align: right;">
                        <span style="color: white;padding-right: 5px;padding-left: 5px">x</span>
                        <input :value="i2iheight" style="width: 30%; background-color: transparent; border: none; outline: none;color: white">
                      </div>
                    </div>
                    <div id="imageContainer" style="width: 50%; height: 30vh; overflow: hidden;">
                    </div>
                    <div v-show="this.isLiveImage" style="width: 13%;height: 30vh;">
                      <div>
                        <p style="color: white">Strength</p>
                      </div>
                      <div class="block" style="width: 100%; display: flex; justify-content: center;padding-bottom: 10px">
                        <el-slider
                            v-model="Strength"
                            vertical
                            :min=1 :max=99 :format-tooltip="formatTooltipStrength"
                            height="200px">
                        </el-slider>
                      </div>
                    </div>
                    <div v-show="isLiveImage" style="width: 13%;height: 25vh;">
                      <div>
                        <p style="color: white;">Noise</p>
                      </div>
                      <div class="block" style="width: 100%; display: flex; justify-content: center;padding-bottom: 30px">
                        <el-slider
                            v-model="Noise"
                            vertical
                            :min=0 :max=99 :format-tooltip="formatTooltipNoise"
                            height="200px">
                        </el-slider>
                      </div>
                    </div>
                  </div>
                </div>

<!--                //vibeTransfer-->
                <div style="height: 35vh; padding-top: 50px">
                  <div style="display: flex;    box-sizing: border-box;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: #646970;">
                    <div style="width: 30%;">
                      <div style="display: flex; justify-content: space-between; align-items: flex-start; padding-bottom: 3px; width: 98%;">
                        <div>
                          <input type="file" accept="image/*" id="uploadBtnVibe" @change="handleUploadVibe" style="display: none;">
                          <label for="uploadBtnVibe" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">VibeTransfer</label>
                        </div>
                        <div style="width:30%; height: 20px">
                          <div @click="showPrefix=true" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">
                            <el-icon name="setting" style="color: white"></el-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="imageContainerVibe" style="width: 50%; height: 30vh; overflow: hidden;"></div>
                    <div id="allImagesContainer"></div>
                  </div>
                </div>


                <!--ImageProcessing-->
                <div style="height: 35vh;">
                  <div style="display: flex; box-sizing: border-box; border-width: 0px 2px 2px 2px; border-style: solid; border-color: #646970;">
                    <div style="width: 30%;">
                      <div style="padding-bottom: 3px; width: 100%;">
                        <input type="file" accept="image/*" id="uploadBtn5" @change="handleUploadUtil" style="display: none;">
                        <label for="uploadBtn5" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">传图处理</label>
                      </div>
                      <div v-if="showDefryAndPrompt" style="margin-top: 2px;">
                        <label style="color: white;">Defry 级别：</label>
                        <select v-model="utilsImageForm.defry" style="width: 100%; background-color: #808080; color: white; border: 1px solid #646970;">
                          <option v-for="n in 7" :key="n-1" :value="n-1">{{ n-1 }}</option>
                        </select>
                      </div>
                      <div v-if="showEmotionPreset" style="margin-top: 2px;">
                        <label style="color: white;">表情预设：</label>
                        <select v-model="selectedEmotion" @change="updatePrompt" style="width: 100%; background-color: #808080; color: white; border: 1px solid #646970;">
                          <option v-for="emotion in emotionPresets" :key="emotion" :value="emotion">{{ emotion }}</option>
                        </select>
                      </div>
                      <div v-if="showDefryAndPrompt" style="margin-top: 2px;">
                        <label style="color: white;">Prompt：</label>
                        <textarea v-model="utilsImageForm.prompt" style="width: 100%; height: 60px; background-color: #808080; color: white; border: 1px solid #646970;"></textarea>
                      </div>
                    </div>
                    <div id="imageContainerUtil" style="width: 50%; height: 30vh; overflow: hidden;"></div>
                    <div style="width: 20%; padding: 10px;">
                      <div v-for="option in utilOptions" :key="option.value" style="margin-bottom: 10px;">
                        <input
                            type="checkbox"
                            :id="option.value"
                            :value="option.value"
                            v-model="selectedUtil"
                            @change="handleUtilChange(option.value)"
                        >
                        <label :for="option.value" style="color: white; margin-left: 5px;">{{ option.label }}</label>
                      </div>
                    </div>
                  </div>
                </div>




<!--                <div style="height: 35vh; ">-->
<!--                  <div style="display: flex;    box-sizing: border-box;-->
<!--    border-width: 0px 2px 2px 2px;-->
<!--    border-style: solid;-->
<!--    border-color: #646970;">-->
<!--                    <div style="width: 30%;">-->
<!--                      <div style="padding-bottom: 3px; width: 100%;">-->
<!--                        <input type="file" accept="image/*" id="uploadBtnChaoFeng" @change="handleUploadChaoFeng" style="display: none;">-->
<!--                        <label for="uploadBtnChaoFeng" style="padding: 1px; width: 98%; border: 2px solid #646970; display: block;">传图深度超分</label>-->
<!--                      </div>-->
<!--                      <div style="padding-bottom: 8px">-->
<!--                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="toChaoFeng">对当前图深度超分</button>-->
<!--                      </div>-->
<!--                      <div style="padding-bottom: 8px">-->
<!--                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="chaoFengCS">测试</button>-->
<!--                      </div>-->
<!--                      <div style="padding-bottom: 8px">-->
<!--                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="chaoFengCS2">测试2</button>-->
<!--                      </div>-->
<!--                      <div style="padding-bottom: 8px">-->
<!--                        <button style=" width: 100%;padding: 1px; border: 2px solid #646970;font-size: 12px" @click="chaoFengKaiShi">GO</button>-->
<!--                      </div>-->
<!--                      <div style="display: flex; align-items: center; padding-bottom: 8px">-->
<!--                        <button style="width: 40%; padding: 1px; border: 2px solid #646970; margin-right: auto;" @click="clearimg2img">清空</button>-->
<!--                        <button style="width: 20%; padding: 1px; border: 2px solid #646970; display: flex; justify-content: center; align-items: center;" @click="this.enableChaoFeng=true">-->
<!--                          <el-icon name="setting" style="color: black; font-size: medium;"></el-icon>-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div style="display: flex; align-items: center; padding-bottom: 8px">&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button style="width: 40%; padding: 1px; border: 2px solid #646970; margin-right: auto;" @click="clearimg2img">清空</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <label class="switch" style="position: relative; display: inline-block; width: 40px; height: 20px;">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <input type="checkbox" v-model="showBigInfill" style="opacity: 0; width: 0; height: 0;">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <span class="slider round" style="position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;"></span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <span class="slider round" style="position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #2196F3; -webkit-transition: .4s; transition: .4s; opacity: 0;" :style="{opacity: showBigInfill ? 1 : 0}"></span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <span class="slider-knob round" style="position: absolute; content: ''; height: 16px; width: 16px; left: 2px; bottom: 2px; background-color: white; -webkit-transition: .4s; transition: .4s;" :style="{transform: showBigInfill ? 'translateX(20px)' : 'translateX(0)'}"></span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      </label>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div id="imageContainerChaoFeng" style="width: 50%; height: 30vh; overflow: hidden;">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div
              class="div-hear-gener" style="height: 10vh!important;">
            <div
                class="div-gener"
                @click="gener" :style="{ backgroundColor: buttonColor }">
              <div style="width: 100%">
                <h4 class="h4-gener">生成一张图片并且消耗1点点数
                  <i v-show="imageLoading" style="align-self: center; font-size: 20px; color: #007bff;" class="el-icon-loading">
                  </i></h4>
              </div>
            </div>
          </div>
        </div>
        <div class="img-background-div-div">
          <div class="img-background-div">
            <img class="img-gener" :src="generImage"  v-show="generImage" alt="网络波动请重试">
            <div style="align-self: center" v-show="(!generImage)">
              <img src="../assets/back.svg" style="align-self: center;">
            </div>
          </div>
          <img src="../assets/histo.svg" class="img-hisor" @click="drawer = true">
          <div @click="diaroyDrawer = true">
            <el-icon name="collection" style="position: absolute;
    top: 60px;
    right: 0;
    font-size: 22px;
    padding: 24px;
    cursor: pointer;
    color: white;
    font-weight: bold;" ></el-icon>
          </div>

          <!-- 历史图片-->
          <el-drawer
              size="10%"
              :modal="false"
              title="我是标题"
              :visible.sync="drawer"
              :with-header="false"
              @open="drawerOpened = true"
              @close="drawerOpened = false"
          >
            <div style="height: 100vh; width: 100%; background-color: #13152c">
              <ul
                  class="infinite-list"
                  v-infinite-scroll="loadMoreImages"
                  :infinite-scroll-disabled="isLoadingImages || visibleImages.length === generImages.length"
                  style="background-color: #13152c; overflow: auto; scrollbar-color: white transparent; scrollbar-width: thin;"
              >
                <li
                    v-for="(image, index) in visibleImages"
                    @click="onImageClick"
                    :key="index"
                    @mousedown="handleMouseDown(index, $event)"
                    @mouseup="handleMouseUp"
                    @mouseleave="handleMouseLeave"
                >
                  <img
                      :src="image.src"
                      alt="网络波动请重试"
                      style="width: 80%; padding-top: 5px;"
                      @click="selectImage(index)"
                      :class="{ selected: index === selectedIndex }"
                      :ref="`image-${index}`"
                  />
                </li>
              </ul>
            </div>
          </el-drawer>
          <el-drawer
              size="60%"
              :modal="false"
              title="我是标题"
              :visible.sync="diaroyDrawer"
              :with-header="false"
              @open="drawerOpened = true"
              @close="drawerOpened = false"
          >
            <div style="height: 100vh;">
              <div style="display: flex">
                <div @click="closeDairy" style="width: 5%; background-color: #3a3d41; display: flex; align-items: center; justify-content: center;">
                  <i style="color: white" class="el-icon-caret-right"></i>
                </div>
                <div style="width: 95%;">
                  <DeskDiary
                      ref="deskDiary"
                      :form="form"
                      @update:form="form = $event"
                      @category-random-changed="handleCategoryRandomChanged"
                  />
                </div>
              </div>
            </div>
          </el-drawer>
          <!-- 个人中心表单 -->
          <el-dialog title="个人中心" :visible.sync="personalCenterFormVisible" width="260px">
            <div>
              <div>
                <p>使用卡密</p>
              </div>
              <el-input placeholder="这里输入卡密" :loading="buttonkamilDisabled" v-model="kamil">

              </el-input>
              <el-button  @click="useKamil">确认使用</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="openChangePasswordForm">修改密码</el-button>
      </span>
          </el-dialog>

          <!-- 修改密码表单 -->
          <el-dialog title="修改密码" :visible.sync="changePasswordFormVisible" width="350px">
            <el-form ref="changePasswordForm" :model="passwordForm" @submit.native.prevent="changePassword">
              <el-form-item label="旧密码" prop="oldPassword" :rules="[{ required: true, message: '请输入旧密码', trigger: 'blur' }]">
                <el-input type="password" v-model="passwordForm.oldPassword" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="newPassword" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }, { min: 8, message: '密码不得少于8位数', trigger: 'blur' }]">
                <el-input type="password" v-model="passwordForm.newPassword" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirm" :rules="[{ required: true, message: '请再次输入密码', trigger: 'blur' }, { validator: validatePass, trigger: 'blur' }]">
                <el-input type="password" v-model="passwordForm.confirm" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button @click="changePasswordFormVisible = false">取消</el-button>
      <el-button type="primary" :loading="buttonDisabled" @click="changePassword">确认修改</el-button>
    </span>
          </el-dialog>

          <el-dialog title="tag标题" :visible.sync="tagsDiaryVisible" width="260px">
            <div>
              <el-input placeholder="输入标题" v-model="tagsDiarytitle">
              </el-input>
              <el-button  @click="saveDiary">确认保存</el-button>
            </div>
          </el-dialog>
          <el-dialog title="额外设置" :visible.sync="tagsOffice" width="260px">
            <h3 style="padding-bottom:6px;">官方tag设置</h3>
            <div>
              <el-checkbox v-model="useOfficialPositiveWords">是否添加官方正面词</el-checkbox>
              <el-checkbox v-model="useOfficialNegativeWords">是否添加官方负面词</el-checkbox>
            </div>
            <h3 style="padding-bottom:6px;">step设置</h3>
            <div>
              <el-checkbox v-model="enableSteps">开启step最高50步</el-checkbox>
            </div>
            <h3 style="padding-bottom:6px;">定义自动跑图数量</h3>

            <div>
              <el-slider v-model="customZhidong" :min=2 :max=customZhidongMax></el-slider>
            </div>
            <!--  外挂功能仅测试服务器显示并且允许使用    -->
<!--            <div>-->
<!--              <el-checkbox v-model="isWaiGua">是否添开启外挂api-keys</el-checkbox>-->
<!--            </div>-->
            <h3 style="padding-bottom:6px;">保存当前设置到服务器</h3>
            <p>step,guidance,官方正负,高级设置,自动数目</p>
            <el-button @click="savePrivateSet">确认保存当前设置到服务器</el-button>
            <div style="padding-top: 8px;"></div>
            <el-button @click="saveOfficeTagSet">确认保存</el-button>
          </el-dialog>
          <el-dialog title="平台随机tag设置" :visible.sync="OfficeRandomTags" width="260px">
            <div>
              <div>
                <el-checkbox v-model="officeRandom">开启普通随机</el-checkbox>
                <div v-show="officeRandom">
                  <el-checkbox v-model="hairRandom">发型随机</el-checkbox>
                  <el-checkbox v-model="clothingRandom">服装随机</el-checkbox>
                  <el-checkbox v-model="bodyRandom">体形随机</el-checkbox>
                  <el-checkbox v-model="backgroundRandom">背景随机</el-checkbox>
                  <el-checkbox v-model="styleRandom">风格随机</el-checkbox>
                  <el-checkbox v-model="viewRandom">视角随机</el-checkbox>
                  <el-checkbox v-model="expressionRandom">表情随机</el-checkbox>
                  <el-checkbox v-model="fiveRandom">五官随机</el-checkbox>
                  <el-checkbox v-model="actionRandom">动作随机</el-checkbox>
                </div>
              </div>
              <div style="height: 2px; background-color: blue;"></div> <!-- 这是新添加的div，作为一条粗的蓝线 -->
              <div style="padding-top: 10px">
                <el-checkbox v-model="officeRandomArtist">开启画师随机</el-checkbox>
                <div v-show="officeRandomArtist">
                  <el-slider
                      v-model="valueArt"
                      range
                      show-stops
                      @change="updateLowTop"
                      :max="30">
                  </el-slider>
                  <el-checkbox v-model="artRandom883">Exception_0x0194大佬精选库</el-checkbox>
                  <el-checkbox v-model="artRandom6482">6482个art词上库</el-checkbox>
                  <el-checkbox v-model="artRandom12660">12660个art词下库</el-checkbox>
                  <el-checkbox v-model="artPrefix">使用artist:前缀</el-checkbox>
                  <el-checkbox v-model="artRandomPower">开启随机调权</el-checkbox>
                  <el-checkbox v-model="artRandomInsert">开启随机插入(开发中</el-checkbox>
                  <el-checkbox v-model="artRandomInPreMid">开启前中插入(开发中</el-checkbox>
                </div>
              </div>
              <el-button @click="saveOfficeRandomSet">确认保存</el-button>
            </div>
          </el-dialog>
          <el-dialog title="设置前缀和后缀" :visible.sync="prefixAndSuffix" width="460px">
            <div>
              <el-input type="textarea" v-model="RandomPrefix" placeholder="请输入正面提示词Prompt前缀" class="my-textarea"></el-input>
              <el-input type="textarea" v-model="RandomSuffix" placeholder="请输入正面提示词Prompt后缀" class="my-textarea"></el-input>
              <el-button @click="savePrefixAndSuffix">确认保存</el-button>
            </div>
          </el-dialog>
          <el-dialog title="Advanced Setting" :visible.sync="addvanceSetting" width="260px">
            <div>
              <div class="block">
                Prompt Guidance Rescale:
                <p>提示重新缩放：</p>
                <el-slider v-model="promptRescale" :format-tooltip="formatTooltipRescale"></el-slider>
              </div>

              <el-divider></el-divider>

              <div class="block">
                Dynamic Thresholding:
                <p>动态阈值：(有时开启后画面光影更好)</p>
                <el-switch
                    v-model="form.parameters.dynamic_thresholding"
                    active-text="开启"
                    inactive-text="关闭"
                ></el-switch>
              </div>

              <el-divider></el-divider>

              <div class="block">
                Noise Schedule:
                <p>噪声调度：</p>
                <el-radio-group v-model="form.parameters.noise_schedule">
                  <el-radio label="exponential">Exponential</el-radio>
                  <el-radio label="polyexponential">Polyexponential</el-radio>
                  <el-radio label="native">Native</el-radio>
                  <el-radio label="karras">Karras</el-radio>
                </el-radio-group>
              </div>

              <el-divider></el-divider>
              <div class="block">
                Variety+:
                <p>画面多样性：(丰富画面)</p>
                <p style="font-size: 9px; color: #666; margin-bottom: 2px;">镜像站特别优化</p>
                <el-switch
                    v-model="varietySwitch"
                    active-text="开启"
                    inactive-text="关闭"
                    @change="handleVarietySwitch"
                ></el-switch>
              </div>
              <el-divider></el-divider>
              <div class="block">
                add_orgin_image:
                <p>局部重绘是否添加原图</p>
                <p style="font-size: 9px; color: #666; margin-bottom: 2px;">开启后多次重绘容易变糊，不开容易出现小方块</p>
                <el-switch
                    v-model="form.parameters.add_original_image"
                    active-text="开启"
                    inactive-text="关闭"
                ></el-switch>
              </div>
              <el-divider></el-divider>
              <el-button @click="saveAddvanceSet">确认保存</el-button>
            </div>
          </el-dialog>


        </div>


      </div>
    </div>

    <!-- 新增的浮动窗口 选词 -->
    <div v-show="showFloatingWindow" class="floating-window">
      <div v-if="filteredTags.length > 0" class="tag-list">
        <div v-for="tag in filteredTags" :key="tag.key" class="tag-item" :title="`${tag.key}: ${tag.value}`" @click="replaceInput(tag.key)">
          <span class="tag-key">{{ tag.key }}:</span>
          <span class="tag-value">{{ tag.value }}</span>
        </div>
      </div>
      <div v-else class="loading">无匹配结果</div>
    </div>

    <el-dialog title="查看当前图片的元数据" :visible.sync="showNowImageExtra" width="560px">
      <div>
        <el-form>
          <el-form-item label="Prompt">
            <el-input type="textarea" :value="nowImageExtra.prompt" class="custom-textarea" readonly></el-input>
          </el-form-item>
          <el-form-item label="负面">
            <el-input type="textarea" :value="nowImageExtra.uc" class="custom-textarea" readonly></el-input>
          </el-form-item>
          <div style="display: flex">
            <P style="width: 50px">seed:</P>
            <el-input :value="nowImageExtra.seed" readonly v-if="true"></el-input>
          </div>
          <div style="display: flex">
            <div>
              <el-button @click="saveDiaryDialog">保存到日记</el-button>
            </div>
            <div>
              <el-button  @click="pushToUse">推送到使用</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="商城" :visible.sync="showShopping" width="560px">
      <div>
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: brown;">普通</span>点数卡(该点数永久不过期)：<span style="color: red;">15r</span>1000点</div>
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: brown;">普通</span>月卡：<span style="color: red;">25r</span>3000点并且最后10天每天送50，一共<span style="color: cyan;">3500</span>点</div>
        <!--        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: dodgerblue;">vip</span>月卡：<span style="color: red;">50r</span>7500点并且最后10天每天送50，一共<span style="color: cyan;">8000</span>点,并送<span style="color: cyan;">大图点数39</span>点！</div>-->
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: dodgerblue;">(限时)vip</span>月卡：<span style="color: red;">50元</span>（限时）无限点,并送<span style="color: cyan;">大图点数50</span>点！</div>
        <!--        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: red;">svip</span>月卡：<span style="color: red;">75r</span><span style="color: cyan;">无限</span>点数，<span style="color: cyan;">大图点数65</span>点!</div>-->
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: brown;">普通附加卡</span>：<span style="color: red;">25r</span><span style="color: cyan;">3500</span>点<span style="color: rosybrown;">注意：该卡必须是已经使用普通月卡并且普通月卡时间未过期才允许买</span></div>
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: brown;">单独购入超份点数</span>：<span style="color: red;">30r</span><span style="color: cyan;">480</span>点<span style="color: rosybrown;">此为超份点数仅用于深度超份</span></div>
        <div style="margin-top: 10px; margin-bottom: 10px;"><span style="color: brown;">单独购入大图点数</span>：<span style="color: red;">30r</span><span style="color: cyan;">120</span>点<span style="color: rosybrown;">此为大图点数用于制作大图</span></div>
<!--        <div style="margin-top: 10px; margin-bottom: 15px;"><span style="color: rebeccapurple;">官网礼品码</span>：<span style="color: red;">120r，已经绝版了还剩下几个</span><span style="color: cyan;">官方账无限套餐礼品码</span><span style="color: #1e2d2b;">注意：此码请用在nai3，不是用在这个镜像站</span></div>-->
        <div style="margin-top: 10px; margin-bottom: 15px;"><span style="color: rebeccapurple;">官网25美刀礼品码</span>：<span style="color: red;">价格95元，自己提供账号或者我帮你开新好，解锁官方无限套餐一个月</span><span style="color: cyan;">官方账无限套餐礼品码</span><span style="color: #1e2d2b;">注意：此号是官号，不是用在这个镜像站</span></div>
      </div>
      <div style="padding-top: 10px">
        注意： 速度正常在10-30s/张图，有级别速度都和官网一样，月卡生效当天到30天后过期,过期时未用完的点数会被清空;
        大图点数和小图点数都是一点一张，(不论是小图模式还是大图模式只要步数超过28步就必定额外扣一点*大图点数*!!)
      </div>
      <div>
        <div style="padding-top: 30px; display: flex; justify-content: center; align-items: center; height: 5vh;">
          <div>
            <p>输入卡密</p>
          </div>
          <div>
            <el-input placeholder="购买后在这里输入卡密,使用后记得刷新页面" :loading="buttonkamilDisabled" v-model="kamil">
            </el-input>
          </div>
          <div>
            <el-button @click="useKamil">确认使用</el-button>
          </div>
        </div>
        <div>
          过期时间:{{this.expireTime}}
        </div>
      </div>
      <div style="padding-top: 30px">
        <div>1，进Q群(三群)：185217995找管理雷神或者群主<span style="color: red;">(如果私聊没有回,请一定要在群里@群主或者管理)</span></div>
        <div>或2，咸鱼搜用户：<span style="color: deeppink;">粉蝶初开</span>找到商品</div>
      </div>
    </el-dialog>

    <div v-show="showDetailInfill" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h6>局部重绘·细涂抹</h6>
          <button class="close" @click="handleClose">保存并且重绘</button>
          <div>画笔大小:</div>
          <div style="width: 100px">
            <el-slider :min=1 :max=50 v-model="brushSize"></el-slider>
          </div>
          <div>
            <button style="width: 50px; padding: 1px; border: 2px solid #646970; margin-left: 5px; margin-right: 5px;" @click="resetDrawing">重涂</button>
          </div>
          <div class="modal-footer" style="display: flex">
            <div>
              <button @click="drawingModingXiTu" style="width: 100%;padding: 0px; border: 2px solid #646970;font-size: 12px;">用画笔</button>
            </div>
          </div>
          <div class="modal-footer" style="display: flex">
            <div>
              <button @click="drawSelectionXiTu" style="width: 100%;padding: 0px; border: 2px solid #646970;font-size: 12px;">点击选取框(多个框会取最后一次)</button>
            </div>
          </div>
        </div>
        <div class="modal-body" style="height: 80vh; border: 1px solid #000; display: flex; justify-content: center; align-items: center; position: relative;">
          <!-- 同步画 -->

        </div>
      </div>
    </div>

    <el-dialog title="工具箱" :visible.sync="showSuitcase" width="260px">
      <p>一键导入d站tag，请把d站左侧全部tag复制包括多余的xxk和多余的符号</p>
      <el-input type="textarea" v-model="droubout"></el-input>
      <el-button @click="sureInputFormDrou">确定</el-button>
      <p>=====================</p>
      <p>请上传包含tag的txt文件</p>
      <el-upload
          ref="upload"
          action=""
          :auto-upload="false"
          :on-change="handleFileChange"
          :show-file-list="false"
      >
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <p></p>
        <el-checkbox v-model="isLunXun">开启轮询测试模式</el-checkbox>

      </el-upload>
    </el-dialog>

    <el-dialog title="画风指导" :visible.sync="showPrefix" width="260px">
      <div style="margin-bottom: 10px;">
        <div style="color: cyan;">vibe默认值预设</div>
        <div style="color: cyan;">Information Extracted:</div>
        <div>
          <input
              type="range"
              min="0.01"
              max="1"
              step="0.01"
              v-model="defaultInformationExtracted"
              style="margin-left: 10px;"
          />
        </div>
        <span style="color: cyan;">{{ defaultInformationExtracted }}</span>
      </div>
      <div style="margin-bottom: 10px;">
        <div style="color: cyan;">Reference Strength:</div>
        <div>
          <input
              type="range"
              min="0.01"
              max="1"
              step="0.01"
              v-model="defaultReferenceStrength"
              style="margin-left: 10px;"
          />
        </div>
        <span style="color: cyan;">{{ defaultReferenceStrength }}</span>
      </div>
      <div>----------------------</div>
      <div v-for="(image, index) in form.parameters.reference_image_multiple" :key="index">
        <p>图片 {{ index + 1 }}:</p>
        <div style="width: 100%; height: 10vh;">
          Information Extracted
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span>{{ form.parameters.reference_information_extracted_multiple[index] }}</span>
            <input
                type="range"
                min="0.01"
                max="1"
                step="0.01"
                v-model="form.parameters.reference_information_extracted_multiple[index]"
            />
          </div>
        </div>
        <div style="width: 100%; height: 10vh;">
          Reference Strength
          <div style="display: flex; flex-direction: column; align-items: center;">
            <span>{{ form.parameters.reference_strength_multiple[index] }}</span>
            <input
                type="range"
                min="0.01"
                max="1"
                step="0.01"
                v-model="form.parameters.reference_strength_multiple[index]"
            />
          </div>
        </div>
        <el-button @click="removeImage(index)">移除当前指导图</el-button>
      </div>
      <div v-if="form.parameters.reference_image_multiple.length === 0">
        <div style="color: red;">当前没有指导图</div>
      </div>
      <el-button @click="clearAllImages">清理所有指导图</el-button>
      <el-button @click="showPrefix = false">确定</el-button>
    </el-dialog>

    <el-dialog title="局部重绘进阶设置" :visible.sync="showBigInfill" width="260px">

      <h5>对大图截取进行局部截取重绘</h5>
      <!-- 添加的复选框部分 -->
      <el-checkbox v-model="bigInfillModeEnabled">开启大图截取局部重绘模式</el-checkbox>

      <el-checkbox v-model="isLaSheng">开启640x640/384x384选框拉伸</el-checkbox>
      <!-- 添加的下拉框部分 -->
      <p style="padding-top: 10px;padding-bottom: 10px">教程：先涂抹，再设置截取框尺寸，然后点击用截取框，再在涂抹位置点击，此时完成截取，点击生图</p>
      <p style="color: cyan;">截取框大小</p>
      <el-select v-model="selectedSize" placeholder="请选择尺寸">
        <el-option label="640x640" value="640x640"></el-option>
        <el-option label="384x384" value="384x384"></el-option>
        <el-option label="1216x832" value="1216x832"></el-option>
        <el-option label="832x1216" value="832x1216"></el-option>
      </el-select>

      <h5>扩图功能</h5>
      <!-- 添加的复选框部分 -->
      <el-checkbox v-model="isKuoTu">开启局部重绘为扩图模式</el-checkbox>

      <h5>enhance 1.5x</h5>
      <!-- 添加的复选框部分 -->
      <el-checkbox v-model="isEnhance">开启图生图为enhance 1.5倍</el-checkbox>
      <p>放大小图消耗大图点数</p>
      <p>官网的Magnitude1-5为strength0.2-0.7</p>

      <el-button @click="showBigInfill=false">确定</el-button>
    </el-dialog>

    <el-dialog title="画笔大小设置" :visible.sync="showbrushSet" width="260px">
      <div>
        <el-slider :min=1 :max=50 v-model="brushSize"></el-slider>
      </div>
      <div>
        <el-checkbox v-model="reverseBrush" @change="resetDrawing">是否开启反向涂抹</el-checkbox>
      </div>
      <el-button @click="showbrushSet=false">确定</el-button>
    </el-dialog>

    <el-dialog title="深度超份(离线最多同时托管5张)" :visible.sync="showShengDuChaoFeng" width="600px" @open="fetchFileList">
      <div style="display: flex; align-items: center; margin-bottom: 15px;">
        <el-upload
            class="upload-demo"
            action="#"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
            multiple
            :limit="5"
            :file-list="fileList"
            :show-file-list="false"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <el-button size="small" type="info" @click="fetchFileList" style="margin-left: 10px;">刷新列表</el-button>
        <!-- 新增：强制删除开关 -->
        <el-switch
            v-model="allowForceDelete"
            style="margin-left: 10px;"
            active-text="允许强制删除(卡死情况下使用)"
        ></el-switch>
      </div>
      <div class="el-upload__tip">只能上传png文件，且不超过5MB/剩余超份点数: {{ extraPoints }},超份点数为0时候消耗大图点，下载上传分别消耗1点，点数不足会下载为空文件</div>

      <!-- 新增：深度级别选择 -->
      <div style="margin-bottom: 15px; margin-top: 15px;">
        <span>深度级别(上传前选，级别越大画面越崩越细节)</span>
        <el-radio-group v-model="shengduLevel">
          <el-radio :label="1">1级</el-radio>
          <el-radio :label="2">2级</el-radio>
          <el-radio :label="3">3级</el-radio>
          <el-radio :label="4">4级</el-radio>
          <el-radio :label="5">5级</el-radio>
          <el-radio :label="6">6级</el-radio>
        </el-radio-group>
      </div>

      <!-- 新增：提供光影开关 -->
      <div style="margin-bottom: 15px;">
        <span>提供光影：</span>
        <el-switch v-model="provideLighting" :active-value="1" :inactive-value="0"></el-switch>
      </div>


      <!-- 新增：更多细节级别选择 -->
      <div style="margin-bottom: 15px;">
        <span>更多细节级别：</span>
        <el-radio-group v-model="detailLevel">
          <el-radio :label="0">0</el-radio>
          <el-radio :label="1">1</el-radio>
          <el-radio :label="2">2</el-radio>
          <el-radio :label="3">3</el-radio>
        </el-radio-group>
      </div>

      <el-table :data="fileList">
        <el-table-column prop="fileName" label="文件名"></el-table-column>
        <el-table-column prop="fileStatus" label="状态">
          <template slot-scope="scope">
            {{ scope.row.fileStatus === 1 ? '处理中' : '完成' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="downloadFile(scope.row.fileName)" :disabled="scope.row.fileStatus === 1">下载</el-button>
            <el-button
                size="mini"
                type="danger"
                @click="deleteFile(scope.row.fileName)"
                :disabled="scope.row.fileStatus === 1 && !allowForceDelete"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
    <el-button @click="showShengDuChaoFeng = false">关闭</el-button>
  </span>
    </el-dialog>

    <el-dialog title="自动队列设置" :visible.sync="showAutoQueue" width="300px">
      <div class="queue-mode-switch">
        <span>队列模式：</span>
        <el-switch v-model="queueModeEnabled"></el-switch>
      </div>
      <div class="input-container" v-if="queueModeEnabled">
        <div v-for="(item, index) in queueItems" :key="index" class="input-item">
          <el-input
              type="textarea"
              v-model="queueItems[index]"
              placeholder="请输入队列项"
              :rows="2"
              resize="none"
          ></el-input>
          <el-button type="text" icon="el-icon-minus" @click="removeQueueItem(index)" :disabled="queueItems.length <= 1"></el-button>
          <span class="repeat-count">{{ calculateRepeatCount() }}</span>
          <el-radio-group
              v-model="queueWidthHeight[index]"
              @change="handleWidthHeightChange(index)"
              size="small">
            <el-radio-button label="1">832x1216</el-radio-button>
            <el-radio-button label="2">1216x832</el-radio-button>
            <el-radio-button label="3">1024x1024</el-radio-button>
            <el-radio-button label="4">1280x768</el-radio-button>
            <el-radio-button label="5">768x1280</el-radio-button>
            <el-radio-button label="6">768x1024</el-radio-button>
            <el-radio-button label="7">1024x768</el-radio-button>
            <el-radio-button label="8">1280x640</el-radio-button>
            <el-radio-button label="9">640x1280</el-radio-button>
          </el-radio-group>
        </div>
        <div class="add-button">
          <el-button type="text" icon="el-icon-plus" @click="addQueueItem">添加新项</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showAutoQueue = false">取消</el-button>
    <el-button type="primary" @click="saveQueueItems">确定</el-button>
  </span>
    </el-dialog>






  </div>
</template>

<script>
import axios from "axios";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import DeskDiary from "@/components/DeskDiary";
import {artistName12660} from "@/assets/data/artisName12660";
import {artistName6482} from "@/assets/data/artistName6482";
import {artistName883} from "@/assets/data/artistName883";


export default {
  components: {
    DeskDiary
  },
  name: 'HomeView',
  data() {
    return {
      varietySwitch: false, // 初始状态为关闭
      //任务队列
      isqueueRepeatCount: 1, //进行的第几个
      isnonEmptyQueueLength: 1, //进行的队列
      nonEmptyQueueLength: 0, // 新增：保存非空队列长度
      queueRepeatCount: 0 ,// 新增：保存计算得到的每个队列重复次数
      isqueueModeEnabled: false,  //启动队列
      showAutoQueue:false,  //显示队列框
      queueModeEnabled: false, //队列模式
      queueItems: ['', '', ''], // 初始化时包含3个空字符串
      queueWidthHeight: [1,1,1], //队列宽高

      filteredTags: [],
      showFloatingWindow: false, // 控制浮动窗口的显示和隐藏
      // 后台自动优化
      worker: null,
      //深度超份
      allowForceDelete: false,
      extraPoints: 0,
      showShengDuChaoFeng:false,
      fileList: [],
      userId:-1,
      shengduLevel:3,  //0.25 1 0.3 2 0.35 3 0.4 4 0.45 5  0.5 6
      provideLighting: 0, // 默认为0
      detailLevel: 0,

      enableImageUtils:false,
      showDefryAndPrompt: false,
      utilsImageForm: {
        image:'',
        defry:0,
        height:256,
        width:256,
        req_type:'',
        prompt:''
      },
      utilOptions: [
        { label: 'LineArt', value: 'lineart' },
        { label: 'Sketch', value: 'sketch' },
        { label: 'Colorize', value: 'colorize' },
        { label: 'Emotion', value: 'emotion' },
        { label: 'Declutter', value: 'declutter' }
      ],
      selectedUtil: [],
      showEmotionPreset: false,
      selectedEmotion: '',
      userPrompt: '',
      emotionPresets: [
        'neutral', 'happy', 'sad', 'angry', 'scared', 'surprised', 'tired', 'excited',
        'nervous', 'thinking', 'confused', 'shy', 'disgusted', 'smug', 'bored',
        'laughing', 'irritated', 'aroused', 'embarrassed', 'worried', 'love',
        'determined', 'hurt', 'playful'
      ],

      chacheInput:'',
      isLunXun: false, //轮询测试
      tags: [],  //数组
      firstAudio:true, //音乐后台
      enableSteps:false, //开启最大步数
      isEnhance:false, //enhance

      isCacheMask:false, //是否缓存上一次的Mask

      cacheMaske:'',//缓存上一次的Mask

      isKuoTu:false, //扩图
      defaultInformationExtracted: 1,
      defaultReferenceStrength: 0.6,

      vibe448:true, //vibe压缩

      uploadedImages: [], // 新增一个空数组来存储上传的图片

      //开启拉伸
      isLaSheng:false,

      //开启api外挂
      isWaiGua:false,

      base641To4Parts: [],
      base64Form4To1Parts: [],
      base641To16Parts: [],
      base64Form16To1Parts: [],

      i2iChaoFengWidth:64,
      i2iChaoFengHeight:64,
      base64PartsForInfill: [],
      img2imgedChaoFengImage:'',
      orangeChaoFengImage:'',
      enableChaoFeng:false,

      //长按删除
      longPressTimer: null, // 长按计时器
      longPressIndex: -1, // 长按的图片索引

      orangeMask:'',
      orangeImage:'',
      isLiveImage: false,
      croppedImage:'',
      croppedMask:'',
      canvasInitialized: false,
      mode:'drawing',
      centerXPercent:0,
      centerYPercent:0,
      uploadedImageNaturalWidth:1216,
      uploadedImageNaturalHeight:832,
      isSelectingBox: false, // 新增状态标志
      bigInfillModeEnabled: false, // 默认不开启
      selectedSize: '1216x832', // 默认选中的尺寸
      bigInfillSet:'640x640',
      bigInfillHeight:832,
      bigInfillWidth:1216,
      showBigInfill:false,
      showPrefix:false,
      referenceInformationExtracted:100,
      referenceStrength:60,
      isVeber:false,
      originalImageWidth: 0,
      originalImageHeight: 0,
      jingxitu:false,
      mask2:'',
      lastDrawing:'',
      image: null,
      droubout:'',
      showSuitcase:false,
      expireTime:'',
      valueArt:[3,5],
      artAfterPrex: false,
      artRandomInPreMid:false,
      artRandomInsert:false,
      artRandomPower:true,
      artPrefix: true,
      artLower: 2,
      artTop: 8,
      artRandom12660: false,
      artRandom6482: false,
      artRandom883:true,
      officeRandomArtist: false,
      names12660: artistName12660,
      names6482: artistName6482,
      names883: artistName883,
      reverseBrush: false,
      lastClickTime: null,
      doubleClickDelay: 200, // 双击的时间间隔，单位是毫秒
      sharedCanvas: null,
      sharedContext: null,
      lastX: 0,
      lastY: 0,
      showDetailInfill: false,
      isTipsInitialized: true, // 添加这个状态变量
      isInitialized: true, // 添加这个状态变量
      customZhidong:30,
      customZhidongMax:180,
      brushSize:8,
      showbrushSet:false,
      showShopping:false,
      nowImageExtra:{},
      showNowImageExtra:false,
      level:0,
      usepromptRescale:0,
      promptRescale:0,
      undesiredStrength:100,
      addvanceSetting:false,
      huodong: '0',
      customRodomParen:[],
      bigPicture:false,
      RandomSuffix:'',
      RandomPrefix:'',
      prefixAndSuffix:false,
      actionRandom:true,
      fiveRandom:true,
      expressionRandom:true,
      viewRandom:true,
      styleRandom:true,
      backgroundRandom:true,
      bodyRandom:false,
      clothingRandom:true,
      hairRandom:true,
      naiOfficeTag:[],
      outOfficeRandom:'',
      allOfficeRandom:{},
      customRandom: false,
      officeRandom:false,
      OfficeRandomTags:false,
      selectWidth:'832',
      selectHeight:'1216',
      cavenImageWidth:64,
      cavenImageHeight:64,
      actionType:'generate',
      isDrawing: false,
      canvas: null,
      ctx: null,
      mask: '',
      tagsOffice: false,
      useOfficialPositiveWords: true,
      useOfficialNegativeWords: true,
      tagsDiaryVisible:false,
      tagsDiarytitle:'',
      buttonDiaryDisabled:false,
      i2iwidth:64,
      i2iheight:64,
      Strength:70,
      Noise:0,
      buttonkamilDisabled: false,
      kamil: '',
      imageBase64:'',
      personalCenterFormVisible: false,
      changePasswordFormVisible: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirm: ''
      },
      buttonDisabled: false,
      diaroyDrawer:false,
      inputText: '',
      outputText: '',
      drawerOpened: false,
      selectedIndex: null,
      isSeed:false,
      intervalId: null,
      zhidongSize:30,
      zhidonDown:false,
      riQi: false,
      now : new Date(),
      generImages: [],  // 用来存储生成的图片

      //历史优化
      visibleImages: [],
      isLoadingImages: false,
      chunkSize: 500, // 每次加载的图片数量
      loadedChunks: 0, // 已加载的图片块数

      generImage:'',
      buttonColor: '#F5F3C2',
      subform: {
        input:'',
        action:'generate',
        parameters:{
          steps: 18,
          scale: 7,
          seed: '',
          width:832,
          height:1216,
          sampler :'k_euler_ancestral',
          sm: false,
          sm_dyn: false,
          negative_prompt:'',

          skip_cfg_above_sigma: null, // 初始值为 null
          // reference_image:'',
          // reference_information_extracted:1.0,
          // reference_strength:0.6,

          reference_image_multiple: [], // 存储上传的 vibe 图像的 base64 字符串
          reference_information_extracted_multiple: [], // 存储对应的 reference_information_extracted 值
          reference_strength_multiple: [], // 存储对应的 reference_strength 值

          add_original_image:false,

          n_samples: 1, // 添加新参数
          strength: 0.7, // 添加新参数
          noise: 0, // 添加新参数
          ucPreset: 0, // 添加新参数
          qualityToggle: true, // 添加新参数
          dynamic_thresholding: false, // 添加新参数
          controlnet_strength: 1, // 添加新参数
          legacy: false, // 添加新参数
          uncond_scale: 1.0, // 修改为后端的值 1.0
          cfg_rescale: 0.0, // 修改为后端的值 0.0
          noise_schedule: 'native', // 添加新参数
          legacy_v3_extend: false, // 添加新参数
          params_version: 1 // 添加新参数
        }
      },
      form: {
        input:'',
        action:'generate',
        parameters:{
          steps: 23,
          scale: 7,
          seed: '',
          width:832,
          height:1216,
          sampler :'k_euler_ancestral',
          sm: false,
          sm_dyn: false,
          negative_prompt:'',
          image:'',
          strength:0.7,
          noise:0,
          uncond_scale: 1,
          mask:'',

          skip_cfg_above_sigma: null, // 初始值为 null
          // reference_image:'',
          // reference_information_extracted:1.0,
          // reference_strength:0.6,

          reference_image_multiple: [], // 存储上传的 vibe 图像的 base64 字符串
          reference_information_extracted_multiple: [], // 存储对应的 reference_information_extracted 值
          reference_strength_multiple: [], // 存储对应的 reference_strength 值

          add_original_image:true,

          n_samples: 1, // 添加新参数
          ucPreset: 0, // 添加新参数
          qualityToggle: true, // 添加新参数
          dynamic_thresholding: false, // 添加新参数
          controlnet_strength: 1, // 添加新参数
          legacy: false, // 添加新参数
          cfg_rescale: 0.0, // 修改为后端的值 0.0
          noise_schedule: 'native', // 添加新参数
          legacy_v3_extend: false, // 添加新参数
          params_version: 1 // 添加新参数
        }
      },
      imageLoading: false,
      point:{
        totalPoints: 0,
        limitedPoints: 0,
        bigPoints: 0,
      },
      guidance: 50,
      drawer: false,
      count: 0,
      size: '长图', // 默认值为'长图'
      bigSize: '长图一',
      isLiangXuChecked: false,
      imageName:'xxx',
    };
  },
  created() {
    this.isInitialized = true; // 在组件创建时设置为 true
    this.diaroyDrawer = true;
    this.getUserInfo();
    this.getHuodongInfo();
    this.$message({
      // message: '活动已经结束,如遇红x请服务更新请退出重登,有需要的可以查看商城,教程更新,请查看教程!',
      // message: '更新日志：（接下来继续优化大图402问题）新增深度超份目前支持2种尺寸，上线测试，离线超份，一张消耗2点大图点数或者2点超份点数(商店更新)————小破站',
      // message: '公告：国际线路暂时出问题了导致出图慢，可以尝试开梯子解决————小破站',
      message: '公告：((更新队列分别设定尺寸，固定前后缀队列生效))$更新局部重绘是否添加原图${{http://io.nai3.art/和http://io.nai3.top/为备用站||https://nai3.art/和https://nai3.to/为主站}}遇到速度慢超过30s!!1，使用主站关闭梯子2，开启梯子使用备用站————小破站',
      // message: '官方已经崩了',
      // message: '活动中免费使用!请求过多服务器ip被封,两天后解决',
      // type: 'warning',
      type: 'success',
      duration: 7000  // 这里设置消息显示的时间为2000毫秒（即2秒）
    });

    this.getOfficeRandom();

    //用户浏览器缓存
    let storedForm = localStorage.getItem('form');
    if (storedForm) {
      let parsedForm = JSON.parse(storedForm);
      this.form.input = parsedForm.input;
      this.form.parameters.negative_prompt = parsedForm.parameters.negative_prompt;
    }

    // 在窗口即将被卸载时保存数据
    window.onbeforeunload = () => {
      localStorage.setItem('form', JSON.stringify({
        input: this.form.input,
        parameters: {
          negative_prompt: this.form.parameters.negative_prompt
        }
      }));
    };
  },
  beforeDestroy() {
    // 终止 Web Worker
    if (this.worker) {
      this.worker.terminate();
    }

    // 释放URL对象占用的内存
    this.generImages.forEach(image => {
      URL.revokeObjectURL(image.src);
    });

    // 在组件销毁前保存数据
    localStorage.setItem('form', JSON.stringify({
      input: this.form.input,
      parameters: {
        negative_prompt: this.form.parameters.negative_prompt
      }
    }));
  },

  watch: {
    'isqueueModeEnabled': function(newValue) {
      if (newValue) {
        this.startGenerating();
      } else {
        this.stopGenerating();
      }
    },
    'form.input': function(newVal) {
      // 获取 textarea 元素
      const textareaElement = this.$refs.input;

      // 检查 textarea 是否有焦点
      const isTextareaFocused = document.activeElement === textareaElement;

      if (!isTextareaFocused) {
        // 如果失去焦点，立即隐藏浮动窗口并清空过滤标签
        this.showFloatingWindow = false;
        this.filteredTags = [];
        return;
      }

      // 获取光标位置
      const cursorPosition = textareaElement.selectionStart;

      // 检查光标是否在末尾
      const isCursorAtEnd = cursorPosition === newVal.length;

      // 获取光标所在行的文本
      const lines = newVal.split('\n');
      let currentLineStart = 0;
      let currentLine = '';
      for (const line of lines) {
        if (currentLineStart + line.length >= cursorPosition) {
          currentLine = line.substring(0, cursorPosition - currentLineStart);
          break;
        }
        currentLineStart += line.length + 1; // +1 for the newline character
      }

      const lastWord = currentLine.split(/[,，\s]+/).pop().trim();

      if (isCursorAtEnd && this.shouldTriggerMatch(lastWord) && this.$configTags) {
        this.filteredTags = Object.entries(this.$configTags)
            .map(([key, value]) => {
              const matchScore = this.calculateMatchScore(lastWord, key, value);
              return { key, value, matchScore };
            })
            .filter(tag => tag.matchScore > 0)
            .sort((a, b) => b.matchScore - a.matchScore)
            .slice(0, 20);

        this.showFloatingWindow = this.filteredTags.length > 0;
      } else {
        this.showFloatingWindow = false;
        this.filteredTags = [];
      }
    },

    drawer(val) {
      if (val) {
        this.resetLoadState();
      }
    },
    'isLiangXuChecked': function(newValue) {
      if (newValue) {
        this.startGenerating();
      } else {
        this.stopGenerating();
      }
    },

    customZhidong(newVal) {
      this.zhidongSize = newVal;
    },
    selectedSize(newValue) {
      this.bigInfillSet = newValue;
      const sizes = newValue.split('x');
      this.bigInfillWidth = parseInt(sizes[0]);
      this.bigInfillHeight = parseInt(sizes[1]);
    },
    // 监视 bigInfillModeEnabled 的变化
    bigInfillModeEnabled(newValue, oldValue) {
      // 只有当值发生变化时，才调用 clearimg2img 方法
      if (newValue !== oldValue) {
        this.clearimg2img();
      }
    },
  },
  computed: {
    sliderMax() {
      return (this.bigPicture||this.enableSteps) ? 50 : 28;
    }
  },
  methods: {
    handleVarietySwitch(value) {
      if (value) {
        this.form.parameters.skip_cfg_above_sigma = 50;
      } else {
        this.form.parameters.skip_cfg_above_sigma = null;
      }
    },
    // 队列功能
    addQueueItem() {
      this.queueItems.push('');
      this.queueWidthHeight.push(1); // 添加新项时，默认宽高级别为1
    },
    removeQueueItem(index) {
      if (this.queueItems.length > 1) {
        this.queueItems.splice(index, 1);
        this.queueWidthHeight.splice(index, 1);
      }
    },
    saveQueueItems() {
      this.calculateQueueInfo();
      // 在这里处理保存逻辑
      console.log('保存的队列项:', this.queueItems);
      console.log('非空队列长度:', this.nonEmptyQueueLength);
      console.log('每个队列重复次数:', this.queueRepeatCount);
      this.showAutoQueue = false;
    },
    calculateRepeatCount() {
      return this.queueRepeatCount;
    },
    calculateQueueInfo() {
      const nonEmptyItems = this.queueItems.filter(item => item.trim() !== '');
      this.nonEmptyQueueLength = nonEmptyItems.length;
      this.queueRepeatCount = this.nonEmptyQueueLength > 0 ? Math.floor(this.customZhidong / this.nonEmptyQueueLength) : 0;
    },
    handleWidthHeightChange(index) {
      // 可以在这里添加额外的逻辑，如果需要的话
    },
    // 控制 选词隐藏
    onBlur() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.showFloatingWindow = false;
          this.filteredTags = [];
        });
      }, 500);
    },

    shouldTriggerMatch(word) {
      return word.length >= 2 || (word.length === 1 && /[\u4e00-\u9fa5]/.test(word));
    },
    calculateMatchScore(input, key, value) {
      if (!key || !value) return 0; // 如果 key 或 value 为空，直接返回 0 分

      const isChineseInput = /[\u4e00-\u9fa5]/.test(input);
      const target = isChineseInput ? value : key;
      const lowerInput = input.toLowerCase();
      const lowerTarget = target.toLowerCase();

      let score = 0;

      // 完全匹配得分最高
      if (lowerTarget === lowerInput) return 100;

      // 开头匹配得分次之
      if (lowerTarget.startsWith(lowerInput)) return 90;

      // 包含整个输入字符串得分再次之
      if (lowerTarget.includes(lowerInput)) return 80;

      // 剩下的情况，根据匹配的字符数量计分
      const inputChars = lowerInput.split('');
      let lastIndex = -1;
      for (const char of inputChars) {
        const index = lowerTarget.indexOf(char, lastIndex + 1);
        if (index > -1) {
          score += 10;
          lastIndex = index;
        }
      }

      return score;
    },

    replaceInput(key) {
      const words = this.form.input.split(/[,，\s]+/);
      const lastWord = words.pop().trim();
      const lastIndex = this.form.input.lastIndexOf(lastWord);

      // 替换最后一个词
      this.form.input = this.form.input.substring(0, lastIndex) + key + ', ';

      this.showFloatingWindow = false;
      this.$nextTick(() => {
        this.$refs.input.focus();
        // 将光标移动到末尾
        const len = this.form.input.length;
        this.$refs.input.setSelectionRange(len, len);
      });
    },
    addToInput(key) {
      // 如果输入框不为空且最后一个字符不是空格，则添加一个空格
      if (this.form.input && this.form.input.slice(-1) !== ' ') {
        this.form.input += ' ';
      }
      // 添加键和逗号空格
      this.form.input += `${key}, `;
      // 聚焦到输入框
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    fetchFileList() {
      this.getUserInfo();

      const userId = this.userId; // 假设您有一个方法来获取当前用户ID
      axios.get('/api/user-resources/list', { params: { userId } })
          .then(response => {
            if (response.data.code === 200) {
              this.fileList = response.data.data.fileList;
              this.extraPoints = response.data.data.extraPoints;
            } else {
              this.$message.error('获取文件列表失败');
            }
          })
          .catch(error => {
            console.error('获取文件列表出错:', error);
            this.$message.error('获取文件列表出错');
          });
    },

    deleteFile(fileName) {
      this.$confirm('确定要删除这个文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const userId = this.userId;
        axios.post('/api/user-resources/delete', {
          fileName: fileName,
          userId: userId
        }, {
          headers: {
            'Authorization': `Bearer ${this.$cookies.get('token')}`,
            'Content-Type': 'application/json'
          }
        })
            .then(response => {
              this.$message.success('文件删除成功');
              this.fetchFileList(); // 刷新文件列表
            })
            .catch(error => {
              this.$message.error('文件删除失败: ' + (error.response?.data?.msg || '未知错误'));
            });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    showTransferNotification(type) {
      return this.$notify({
        title: type === 'upload' ? '上传进度' : '下载进度',
        message: '0 MB/s',
        duration: 0,
        position: 'top-right'
      });
    },

    uploadFile(options) {
      const formData = new FormData();
      formData.append('file', options.file);
      formData.append('userId', this.userId);
      formData.append('shengduLevel', this.shengduLevel);
      formData.append('provideLighting',this.provideLighting);
      formData.append('detailLevel',this.detailLevel);

      const notification = this.showTransferNotification('upload');
      let startTime = Date.now();
      let lastLoaded = 0;

      axios.post('/api/user-resources/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authentication': this.$cookies.get('token')
        },
        onUploadProgress: (progressEvent) => {
          const currentTime = Date.now();
          const timeElapsed = (currentTime - startTime) / 1000; // 转换为秒
          const loaded = progressEvent.loaded;
          const speed = ((loaded - lastLoaded) / timeElapsed / (1024 * 1024)).toFixed(2);

          notification.message = `${speed} MB/s`;

          startTime = currentTime;
          lastLoaded = loaded;
        }
      })
          .then(response => {
            if (response.data && response.data.code !== 200) {
              throw new Error(response.data.msg || '上传失败');
            }
            this.$message.success('上传成功');
            this.fetchFileList();
            options.onSuccess();
          })
          .catch(error => {
            this.$message.error('上传失败: ' + (error.message || '未知错误'));
            options.onError(error);
          })
          .finally(() => {
            notification.close();
            this.getUserInfo();
          });
    },

    downloadFile(fileName) {
      const userId = this.userId;
      const token = this.$cookies.get('token');

      console.log('Downloading file:', fileName, 'for user:', userId);

      const notification = this.showTransferNotification('download');
      let startTime = Date.now();
      let lastLoaded = 0;

      axios({
        url: `/api/user-resources/download/${fileName}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Authentication': token,
          'Accept': 'image/png,application/octet-stream,application/json'
        },
        params: {
          userId: userId
        },
        onDownloadProgress: (progressEvent) => {
          const currentTime = Date.now();
          const timeElapsed = (currentTime - startTime) / 1000; // 转换为秒
          const loaded = progressEvent.loaded;
          const speed = ((loaded - lastLoaded) / timeElapsed / (1024 * 1024)).toFixed(2);

          notification.message = `${speed} MB/s`;

          startTime = currentTime;
          lastLoaded = loaded;
        }
      }).then(response => {
        const contentType = response.headers['content-type'];
        console.log('Response content type:', contentType);

        if (contentType && contentType.indexOf('application/json') !== -1) {
          // 如果是 JSON，可能是错误消息
          return response.data.text().then(text => {
            const error = JSON.parse(text);
            throw new Error(error.msg || '下载失败');
          });
        } else if (contentType && (contentType.indexOf('image/png') !== -1 || contentType.indexOf('application/octet-stream') !== -1)) {
          // 处理文件下载
          const disposition = response.headers['content-disposition'];
          let filename = fileName;
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          throw new Error('Unexpected content type: ' + contentType);
        }
      }).catch(error => {
        console.error('文件下载失败:', error);
        if (error.response) {
          // 服务器响应了，但状态码不在 2xx 范围内
          console.error('Error response:', error.response);
          if (error.response.data instanceof Blob) {
            error.response.data.text().then(text => {
              try {
                const jsonError = JSON.parse(text);
                this.$message.error('文件下载失败: ' + (jsonError.msg || '未知错误'));
              } catch (e) {
                this.$message.error('文件下载失败: ' + text);
              }
            });
          } else {
            this.$message.error('文件下载失败: ' + (error.response.data || '未知错误'));
          }
        } else if (error.request) {
          // 请求已经发出，但没有收到响应
          console.error('No response:', error.request);
          this.$message.error('文件下载失败: 服务器无响应');
        } else {
          // 在设置请求时发生了一些事情，触发了错误
          console.error('Error', error.message);
          this.$message.error('文件下载失败: ' + error.message);
        }
      }).finally(() => {
        notification.close();
        this.getUserInfo();
      });
    },





    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        console.log(this.extraPoints,this.point.bigPoints);
        if (this.extraPoints <0 && this.point.bigPoints < 0) {
          this.$message.error('大图点或者超份点不够!');
          reject(new Error('大图点或者超份点不够!'));
          return;
        }
        // 检查文件类型
        const isPNG = file.type === 'image/png';
        if (!isPNG) {
          this.$message.error('只能上传PNG格式的图片!');
          reject(new Error('只能上传PNG格式的图片!'));
          return;
        }

        // 检查文件大小
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过5MB!');
          reject(new Error('上传图片大小不能超过5MB!'));
          return;
        }

        // 检查图片尺寸
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const isValidSize = (width === 832 && height === 1216) || (width === 768 && height === 1280) ||
                (width === 1216 && height === 832) ||
                (width === 1280 && height === 768);
            if (!isValidSize) {
              this.$message.error('图片尺寸必须为832x1216或768x1280!');
              reject(new Error('图片尺寸必须为832x1216或768x1280!'));
            } else {
              resolve();
            }
          };
        };
      });
    },


    //实现图片处理功能
    handleUtilChange(value) {
      if (this.selectedUtil.includes(value)) {
        this.utilsImageForm.req_type = value;
        this.selectedUtil = [value];
        this.enableImageUtils = true;
        this.showDefryAndPrompt = (value === 'colorize' || value === 'emotion');
        this.showEmotionPreset = (value === 'emotion');
      } else {
        this.utilsImageForm.req_type = '';
        this.selectedUtil = [];
        this.enableImageUtils = false;
        this.showDefryAndPrompt = false;
        this.showEmotionPreset = false;
      }

      if (value !== 'colorize' && value !== 'emotion') {
        this.utilsImageForm.defry = 0;
        this.utilsImageForm.prompt = '';
        this.selectedEmotion = '';
        this.userPrompt = '';
      }
      this.updatePrompt();
    },
    updatePrompt() {
      let finalPrompt = '';
      if (this.selectedEmotion) {
        finalPrompt = this.selectedEmotion + ';;';
      }
      if (this.userPrompt) {
        finalPrompt += this.userPrompt;
      }
      this.utilsImageForm.prompt = finalPrompt.trim();
    },

    handleUploadUtil(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageSrc = e.target.result;

          // 创建图片元素
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            // 获取正确的容器元素
            const container = document.getElementById('imageContainerUtil');

            // 清空容器
            container.innerHTML = '';

            let newWidth, newHeight;

            if (!this.bigPicture) {
              // 非大图模式
              const standardSizes = [
                [832, 1216], [1216, 832], [1024, 1024], [1280, 768], [768, 1280]
              ];

              if (standardSizes.some(size => size[0] === img.naturalWidth && size[1] === img.naturalHeight)) {
                // 标准尺寸，直接赋值
                newWidth = img.naturalWidth;
                newHeight = img.naturalHeight;
              } else {
                // 非标准尺寸，等比缩放
                const aspectRatio = img.naturalWidth / img.naturalHeight;
                if (img.naturalWidth > img.naturalHeight) {
                  newWidth = Math.min(img.naturalWidth, 1024);
                  newHeight = Math.floor(newWidth / aspectRatio);
                } else {
                  newHeight = Math.min(img.naturalHeight, 1024);
                  newWidth = Math.floor(newHeight * aspectRatio);
                }

                // 调整为64的倍数
                newWidth = Math.round(newWidth / 64) * 64;
                newHeight = Math.round(newHeight / 64) * 64;
              }

              // 创建 canvas 元素来调整图片大小
              const canvas = document.createElement('canvas');
              canvas.width = newWidth;
              canvas.height = newHeight;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, newWidth, newHeight);

              // 将调整后的图片转换为 base64
              const resizedImageBase64 = canvas.toDataURL('image/jpeg');

              // 更新 utilsImageForm
              const base64Data = resizedImageBase64.split(',')[1];
              this.utilsImageForm.image = base64Data;
            } else {
              // 大图模式，直接使用原图
              newWidth = img.naturalWidth;
              newHeight = img.naturalHeight;

              // 直接使用原始图片数据
              this.utilsImageForm.image = imageSrc.split(',')[1];
            }

            // 创建新的图片元素并添加到容器
            const imgElement = document.createElement('img');
            imgElement.src = this.bigPicture ? imageSrc : `data:image/jpeg;base64,${this.utilsImageForm.image}`;
            imgElement.style.maxWidth = '100%';
            imgElement.style.maxHeight = '100%';
            imgElement.style.objectFit = 'contain';
            container.appendChild(imgElement);

            // 更新 utilsImageForm 的宽高
            this.utilsImageForm.width = newWidth;
            this.utilsImageForm.height = newHeight;

            console.log('图片已上传、处理并更新到 utilsImageForm，宽高已设置');
          };
        };

        reader.readAsDataURL(file);
      } else {
        this.$message.error('请选择一个图片文件');
      }
    },



    //====

// 处理图片上传后的逻辑
    handleImageUploadUtil(imageSrc) {
      // 这里可以添加上传图片后的处理逻辑
      // 例如，更新状态、发送到服务器等
      console.log('图片已上传:', imageSrc);

      // 如果需要在 allImagesContainerUtil 中显示所有上传的图片，可以这样做：
      const allImagesContainer = document.getElementById('allImagesContainerUtil');
      const imgElement = document.createElement('img');
      imgElement.src = imageSrc;
      imgElement.style.width = '50px';  // 设置缩略图大小
      imgElement.style.height = '50px';
      imgElement.style.objectFit = 'cover';
      imgElement.style.margin = '2px';
      allImagesContainer.appendChild(imgElement);
    },


    //读取tag数组
    handleFileChange(file, fileList) {
      this.file = file;
      if (this.file.raw.type === 'text/plain') {
        this.readAndParseTxtFile();
      } else {
        console.error('请上传txt格式的文件');
      }
    },
    readAndParseTxtFile() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        this.parseTxtFile(text);
      };
      reader.onerror = (e) => {
        console.error('文件读取失败', e);
      };
      reader.readAsText(this.file.raw);
    },
    // parseTxtFile(text) {
    //   this.tags = text.split('\n').map(tag => tag.trim()).filter(tag => tag !== '');
    //   console.log(this.tags);
    //   this.updateZhidongSize();
    //   // 在这里可以对解析后的tags数组进行进一步处理
    // },
    parseTxtFile(text) {
      this.tags = text.split('\n')
          .map(tag => tag.trim())
          .filter(tag => tag !== '')
          .slice(0, 50); // 只保留前50个字符串
      console.log(this.tags);
      this.updateZhidongSize();
      // 在这里可以对解析后的tags数组进行进一步处理
    },
    updateZhidongSize() {
      this.customZhidong = this.tags.length;
      this.zhidongSize = this.tags.length;
      this.customZhidongMax = this.tags.length;
    },

    //循环播放MP3
    initAudioPlay() {
      this.audioPlayer = new Audio('/mp3/silence.mp3');
      this.audioPlayer.loop = true; // 设置循环播放
      // console.log("播放成功");
      this.audioPlayer.play().catch(error => {
        console.error('自动播放失败:', error);
      });
    },
    //自动下载图片的下载方法
    // downloadImageDirectly(imageData, imageName) {
    //   const link = document.createElement('a');
    //   link.href = imageData;
    //   link.download = imageName;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },
    downloadImageDirectly(imageData, imageName) {
      const byteString = atob(imageData.split(',')[1]);
      const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    shrinkImageAndEmbedInCanvas(base64) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        // img.src = `data:image/png;base64,${base64}`;
        img.src = base64;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const shrunkWidth = img.width * 0.75;
          const shrunkHeight = img.height * 0.75;
          canvas.width = img.width;
          canvas.height = img.height;
          const offsetX = (img.width - shrunkWidth) / 2;
          const offsetY = (img.height - shrunkHeight) / 2;
          ctx.drawImage(img, 0, 0);
          ctx.drawImage(img, offsetX, offsetY, shrunkWidth, shrunkHeight);
          const embeddedImageData = canvas.toDataURL('image/png').replace(/^data:image\/(png|jpeg);base64,/, '');
          resolve(embeddedImageData);
        };
        img.onerror = reject;
      });
    },

    generateBlackWhiteImageWithBlackRect(base64) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        // img.src = `data:image/png;base64,${base64}`;
        img.src = base64;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const shrunkWidth = img.width * 0.70;
          const shrunkHeight = img.height * 0.70;
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          const offsetX = (img.width - shrunkWidth) / 2;
          const offsetY = (img.height - shrunkHeight) / 2;
          ctx.fillStyle = 'black';
          ctx.fillRect(offsetX, offsetY, shrunkWidth, shrunkHeight);
          const blackWhiteImageData = canvas.toDataURL('image/png').replace(/^data:image\/(png|jpeg);base64,/, '');
          resolve(blackWhiteImageData);
        };
        img.onerror = reject;
      });
    },

    loadImageChunk() {
      this.isLoadingImages = true;
      const startIndex = this.loadedChunks * this.chunkSize;
      const endIndex = startIndex + this.chunkSize;
      const newImages = this.generImages.slice(startIndex, endIndex);
      this.visibleImages = [...newImages, ...this.visibleImages];
      this.loadedChunks++;
      this.isLoadingImages = false;
    },
    loadMoreImages() {
      if (!this.isLoadingImages && this.loadedChunks * this.chunkSize < this.generImages.length) {
        this.loadImageChunk();
      }
    },
    addNewImage(newImage) {
      const updatedGenerImages = [newImage, ...this.generImages];
      this.generImages = updatedGenerImages;
      this.resetLoadState();
    },
    resetLoadState() {
      this.visibleImages = [];
      this.loadedChunks = 0;
      this.loadImageChunk();
    },

    /**
     * 从压缩包数据中提取图片数据
     * @param {ArrayBuffer} zipData 压缩包数据
     * @returns {Uint8Array|null} 图片数据,如果解压缩失败则返回 null
     */
    extractImageFromZip(zipData) {
      try {
        // 使用 jszip 库解压缩数据
        const zip = new JSZip();
        zip.loadAsync(zipData).then(function(zip) {
          // 查找压缩包中的第一个文件,假设它就是图片文件
          const firstFileName = Object.keys(zip.files)[0];
          if (firstFileName) {
            // 获取文件数据
            return zip.files[firstFileName].async("uint8array");
          }
          return null;
        }).then(function(imageData) {
          // 返回图片数据
          return imageData;
        });
      } catch (error) {
        // console.error("Error extracting image from zip:", error);
        return null;
      }
    },

    //超份
    async chaoFengKaiShi() {
      await this.generChaoFeng();
      // console.log("end...");
    },

    async generChaoFeng() {

      let requestSuccess = false; // 在此处声明 requestSuccess 变量

      //"使用官方正面词汇"和"使用官方负面词汇"
      this.naiOfficeTag = this.naiOfficeTag || []; // 确保naiOfficeTag是一个数组

      if (this.useOfficialPositiveWords === true) {
        if (!this.naiOfficeTag.includes(1)) { // 如果数组中没有数字1
          this.naiOfficeTag.push(1); // 向数组添加数字1
        }
      } else {
        let index = this.naiOfficeTag.indexOf(1); // 查找数字1在数组中的位置
        if (index !== -1) { // 如果找到了数字1
          this.naiOfficeTag.splice(index, 1); // 从数组中删除数字1
        }
      }

      if (this.useOfficialNegativeWords === true) {
        if (!this.naiOfficeTag.includes(2)) { // 如果数组中没有数字1
          this.naiOfficeTag.push(2); // 向数组添加数字1
        }
      } else {
        let index = this.naiOfficeTag.indexOf(2); // 查找数字1在数组中的位置
        if (index !== -1) { // 如果找到了数字1
          this.naiOfficeTag.splice(index, 2); // 从数组中删除数字1
        }
      }
      this.buttonDisabled = true; // Disable the button

      this.buttonColor = '#ffffffb3';
      this.imageLoading = true;

      //开始对4个图片进行图生图
      const base64Parts = this.base641To4Parts;

      const base64i2i4Parts = []; // 定义一个空数组来存储生成的图片
      const sendRequest = async (base64Part) => {
        requestSuccess = false; // 在函数内部重置 requestSuccess 为 false
        let retryCount = 0;
        const maxRetries = 5;

        while (!requestSuccess && retryCount < maxRetries) {
          this.form.parameters.image = base64Part;
          this.actionType = 'img2img';

          this.form.parameters.width = this.i2iChaoFengWidth * 2;
          this.form.parameters.height = this.i2iChaoFengHeight * 2;
          this.form.action = 'img2img';
          this.form.model = 'nai-diffusion-3';
          this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
          this.form.parameters.cfg_rescale = this.usepromptRescale;
          this.form.parameters.uncond_scale = this.undesiredStrength / 100;
          this.form.parameters.strength = 0.5; //0.45
          // 删除 this.form 的 mask 属性
          delete this.form.mask;

          //ddim采样不支持 smea
          if (this.form.parameters.sampler === 'ddim') {
            this.form.parameters.sm = false;
            this.form.parameters.sm_dyn = false;
          }

          this.form.naiOfficeTag = this.naiOfficeTag;

          this.form.input = this.processInput(this.form.input);

          try {
            const response = await axios.post('/api/user/ai/generate-image3', this.form, {
              headers: {
                'authentication': this.$cookies.get('token')
              }
            });

            if (response.data.code === 200) {
              requestSuccess = true; // 请求成功,退出循环
              const newImage = {
                src: 'data:image/png;base64,' + response.data.data,
                name: this.riQi ? this.formatDate(new Date()) : response.data.msg
              };

              this.addNewImage(newImage); // 生成的图添加到历史图片中

              if (!this.drawerOpened) {
                this.generImage = newImage.src;
                this.imageName = newImage.name;
              }

              // ===
              base64i2i4Parts.push(newImage.src); // 将新生成的图片存入base64i2i4Parts数组
              if (this.zhidonDown) {
                this.downloadImageDirectly(newImage.src, newImage.name);
              }
              this.getUserInfo();
            } else if (response.data.code === 500) {
              // Handle 500 error
            }
          } catch (error) {
            // Handle error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.status === 413) {
                this.$message.error('上传的图片过大，服务器无法处理。请尝试减小图片的大小。');
              } else if (error.response.status === 401) {
                this.$message.error('登录状态过期，请退出重新登录' + error.response.status);
              } else {
                this.$message.error('发生错误' + error.response.status);
              }
            } else if (error.request) {
              // The request was made but no response was received
              this.$message.error('服务器没有响应。请检查你的网络连接。');
            } else {
              // Something happened in setting up the request that triggered an Error
              this.$message.error('请求发送时出错。');
            }
            this.buttonColor = '#F5F3C2';
          } finally {

            retryCount++;
          }

          if (retryCount === maxRetries) {
            this.$message.error(`请求重试${maxRetries}次仍然失败,已跳过该请求。`);
            break;
          }
        }
      };

      for (const base64Part of base64Parts) {
        await sendRequest(base64Part);
        if (!requestSuccess) {
          this.$message.error('由于存在请求失败,已中止剩余请求。');
          break;
        }
      }

      //完成4个图的图生图后
      // 在循环结束后,合并base64i2i4Parts数组中的图片

      let mergedImage = '';
      if (base64i2i4Parts.length > 0) {
        mergedImage = await this.mergeBase64ImagesFrom4Parts(base64i2i4Parts);
        const mergedImageName = `${this.formatDate(new Date())}_nai3.art_deep_scale.png`;
        const newImage = {
          src: mergedImage,
          name: mergedImageName
        };

        this.addNewImage(newImage); // 生成的图添加到历史图片中
        if (!this.drawerOpened) {
          this.generImage = newImage.src;
          this.imageName = newImage.name;
        }

        //第一次，拆分合成图为16块，取关键块合成中等图用于局部重绘
        // 调用splitBase64ImageInto16Parts方法
        const base64Parts16 = await this.splitBase64ImageInto16Parts(mergedImage);

        // 将base64Parts16数组的元素按照指定规则分组
        const group1 = [base64Parts16[1], base64Parts16[2], base64Parts16[5], base64Parts16[6]];
        // const group2 = [base64Parts16[4], base64Parts16[5], base64Parts16[8], base64Parts16[9]];
        // const group3 = [base64Parts16[6], base64Parts16[7], base64Parts16[10], base64Parts16[11]];
        const group4 = [base64Parts16[9], base64Parts16[10], base64Parts16[13], base64Parts16[14]];

        const groups = [group1, group4];

        //创建对应mask
        // 遍历groups数组,合并每个子数组中的图片,并创建对应的黑白图片
        const mergedImages = [];
        const blackWhiteImages = [];
        for (let i = 0; i < groups.length; i++) {
          const group = groups[i];
          const mergedImage = await this.mergeBase64ImagesFrom4Parts(group);
          mergedImages.push(mergedImage);

          const blackWhiteImage = await this.createBlackWhiteImage(mergedImage, i);
          blackWhiteImages.push(blackWhiteImage);
        }

        // 将合并后的图片添加到抽屉中
        for (const mergedImage of mergedImages) {
          const newImage = {
            src: mergedImage,
            name: `${this.formatDate(new Date())}_nai3.art_deep_scale_merged.png`
          };

          this.addNewImage(newImage); // 生成的图添加到历史图片中
        }

        // console.log(mergedImages);
        // console.log(blackWhiteImages);
        // 将黑白图片添加到抽屉中
        for (const blackWhiteImage of blackWhiteImages) {
          const newImage = {
            src: blackWhiteImage,
            name: `${this.formatDate(new Date())}_nai3.art_deep_scale_black_white.png`
          };

          this.addNewImage(newImage); // 生成的图添加到历史图片中
        }


        // 开始处理上和下 2个 个对应的局部重绘
        const infilledImages = [];
        for (let i = 0; i < mergedImages.length; i++) {
          this.form.parameters.image = mergedImages[i];
          this.form.parameters.mask = blackWhiteImages[i];
          this.form.action = 'infill';

          let retryCount = 0;
          const maxRetries = 3;

          while (retryCount < maxRetries) {
            this.form.parameters.width = this.i2iChaoFengWidth * 2;
            this.form.parameters.height = this.i2iChaoFengHeight * 2;
            this.form.model = 'nai-diffusion-3-inpainting';
            this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.mask = this.form.parameters.mask.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.cfg_rescale = this.usepromptRescale;
            this.form.parameters.uncond_scale = this.undesiredStrength / 100;
            this.form.parameters.strength = 0.8;

            // ddim采样不支持 smea
            if (this.form.parameters.sampler === 'ddim') {
              this.form.parameters.sm = false;
              this.form.parameters.sm_dyn = false;
            }

            this.form.naiOfficeTag = this.naiOfficeTag;

            this.form.input = this.processInput(this.form.input);


            try {
              const response = await axios.post('/api/user/ai/generate-image3', this.form, {
                headers: {
                  'authentication': this.$cookies.get('token')
                }
              });

              if (response.data.code === 200) {
                requestSuccess = true; // 请求成功,退出循环
                const newImage = {
                  src: 'data:image/png;base64,' + response.data.data,
                  name: this.riQi ? this.formatDate(new Date()) : response.data.msg
                };

                this.addNewImage(newImage); // 生成的图添加到历史图片中
                if (!this.drawerOpened) {
                  this.generImage = newImage.src;
                  this.imageName = newImage.name;
                }

                // 处理其他逻辑
                // ...

                // 将新生成的图片添加到 infilledImages 数组中
                infilledImages.push(newImage.src);

                break;
              } else if (response.data.code === 500) {
                // 处理 500 错误
              }
            } catch (error) {
              // 处理错误
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 413) {
                  this.$message.error('上传的图片过大，服务器无法处理。请尝试减小图片的大小。');
                } else if (error.response.status === 401) {
                  this.$message.error('登录状态过期，请退出重新登录' + error.response.status);
                } else {
                  this.$message.error('发生错误' + error.response.status);
                }
              } else if (error.request) {
                // The request was made but no response was received
                this.$message.error('服务器没有响应。请检查你的网络连接。');
              } else {
                // Something happened in setting up the request that triggered an Error
                this.$message.error('请求发送时出错。');
              }
              this.buttonColor = '#F5F3C2';
            }

            retryCount++;
          }

          if (retryCount === maxRetries) {
            this.$message.error(`请求重试${maxRetries}次仍然失败,已跳过该请求。`);
            break;
          }
        }

        // 重绘后图片1分4 //用于最终中期合成
        if (infilledImages.length > 0) {

          // const base64Parts16 = await this.splitBase64ImageInto16Parts(this.orangeChaoFengImage);
          // console.log('base64Parts16 length:', base64Parts16.length);

          // 检查 infilledImages 数组的长度是否足够
          if (infilledImages.length < 2) {
            // console.error('infilledImages 数组的长度不足 2,无法继续执行合并操作。');
            return;
          }

          // 替换 base64Parts16 中对应的元素
          let base64Parts1, base64Parts2;
          try {
            base64Parts1 = await this.splitBase64ImageInto4Parts(infilledImages[0]);
            // console.log('base64Parts1 length:', base64Parts1.length);
          } catch (error) {
            // console.error('分割 infilledImages[1] 时出错:', error);
            return;
          }

          try {
            base64Parts2 = await this.splitBase64ImageInto4Parts(infilledImages[1]);
            // console.log('base64Parts2 length:', base64Parts2.length);
          } catch (error) {
            // console.error('分割 infilledImages[2] 时出错:', error);
            return;
          }


          base64Parts16[1] = base64Parts1[0];
          base64Parts16[2] = base64Parts1[1];
          base64Parts16[5] = base64Parts1[2];
          base64Parts16[6] = base64Parts1[3];


          base64Parts16[9] = base64Parts2[0];
          base64Parts16[10] = base64Parts2[1];
          base64Parts16[13] = base64Parts2[2];
          base64Parts16[14] = base64Parts2[3];

          // 调用 mergeBase64ImagesFrom16Parts 方法合成最终图像
          let middleImage;
          try {
            middleImage = await this.mergeBase64ImagesFrom16Parts(base64Parts16);
            mergedImage = middleImage;
          } catch (error) {
            console.error('合并 base64Parts16 时出错:', error);
            return;
          }

          // 将合成后的图像添加到抽屉中
          const newImage = {
            src: middleImage,
            name: `${this.formatDate(new Date())}_nai3.art_deep_scale_final.png`
          };

          this.addNewImage(newImage); // 生成的图添加到历史图片中
          if (!this.drawerOpened) {
            this.generImage = newImage.src;
            this.imageName = newImage.name;
          }
        }

        //第二次，拆分合成图为16块，取关键块合成中等图用于局部重绘
        // 调用splitBase64ImageInto16Parts方法
        const base64Parts162Part = await this.splitBase64ImageInto16Parts(mergedImage);

        // 将base64Parts16数组的元素按照指定规则分组
        const group2 = [base64Parts162Part[4], base64Parts162Part[5], base64Parts162Part[8], base64Parts162Part[9]];
        const group3 = [base64Parts162Part[6], base64Parts162Part[7], base64Parts162Part[10], base64Parts162Part[11]];

        const groups2 = [group2, group3];

        //创建对应mask
        // 遍历groups数组,合并每个子数组中的图片,并创建对应的黑白图片
        const mergedImages2 = [];
        const blackWhiteImages2 = [];
        for (let i = 0; i < groups2.length; i++) {
          const group2 = groups2[i];
          const mergedImage2 = await this.mergeBase64ImagesFrom4Parts(group2);
          mergedImages2.push(mergedImage2);

          const blackWhiteImage2 = await this.createBlackWhiteImage2(mergedImage2, i);
          blackWhiteImages2.push(blackWhiteImage2);
        }

        // 将合并后的图片添加到抽屉中
        for (const mergedImage2 of mergedImages2) {
          const newImage = {
            src: mergedImage,
            name: `${this.formatDate(new Date())}_nai3.art_deep_scale_merged.png`
          };
          this.addNewImage(newImage); // 生成的图添加到历史图片中
        }

        // console.log(mergedImages2);
        // console.log(blackWhiteImages2);
        // 将黑白图片添加到抽屉中
        for (const blackWhiteImage2 of blackWhiteImages2) {
          const newImage = {
            src: blackWhiteImage2,
            name: `${this.formatDate(new Date())}_nai3.art_deep_scale_black_white.png`
          };
          this.addNewImage(newImage); // 生成的图添加到历史图片中
        }


        // 开始处理左和右 2个 个对应的局部重绘
        const infilledImages2 = [];
        for (let i = 0; i < mergedImages2.length; i++) {
          this.form.parameters.image = mergedImages2[i];
          this.form.parameters.mask = blackWhiteImages2[i];
          this.form.action = 'infill';

          let retryCount = 0;
          const maxRetries = 3;

          while (retryCount < maxRetries) {
            this.form.parameters.width = this.i2iChaoFengWidth * 2;
            this.form.parameters.height = this.i2iChaoFengHeight * 2;
            this.form.model = 'nai-diffusion-3-inpainting';
            this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.mask = this.form.parameters.mask.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.cfg_rescale = this.usepromptRescale;
            this.form.parameters.uncond_scale = this.undesiredStrength / 100;
            this.form.parameters.strength = 0.8;

            // ddim采样不支持 smea
            if (this.form.parameters.sampler === 'ddim') {
              this.form.parameters.sm = false;
              this.form.parameters.sm_dyn = false;
            }

            this.form.naiOfficeTag = this.naiOfficeTag;

            this.form.input = this.processInput(this.form.input);


            try {
              const response = await axios.post('/api/user/ai/generate-image3', this.form, {
                headers: {
                  'authentication': this.$cookies.get('token')
                }
              });

              if (response.data.code === 200) {
                requestSuccess = true; // 请求成功,退出循环
                const newImage = {
                  src: 'data:image/png;base64,' + response.data.data,
                  name: this.riQi ? this.formatDate(new Date()) : response.data.msg
                };
                this.addNewImage(newImage); // 生成的图添加到历史图片中
                if (!this.drawerOpened) {
                  this.generImage = newImage.src;
                  this.imageName = newImage.name;
                }

                // 处理其他逻辑
                // ...

                // 将新生成的图片添加到 infilledImages 数组中
                infilledImages2.push(newImage.src);

                break;
              } else if (response.data.code === 500) {
                // 处理 500 错误
              }
            } catch (error) {
              // 处理错误
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 413) {
                  this.$message.error('上传的图片过大，服务器无法处理。请尝试减小图片的大小。');
                } else if (error.response.status === 401) {
                  this.$message.error('登录状态过期，请退出重新登录' + error.response.status);
                } else {
                  this.$message.error('发生错误' + error.response.status);
                }
              } else if (error.request) {
                // The request was made but no response was received
                this.$message.error('服务器没有响应。请检查你的网络连接。');
              } else {
                // Something happened in setting up the request that triggered an Error
                this.$message.error('请求发送时出错。');
              }
              this.buttonColor = '#F5F3C2';
            }

            retryCount++;
          }

          if (retryCount === maxRetries) {
            this.$message.error(`请求重试${maxRetries}次仍然失败,已跳过该请求。`);
            break;
          }
        }

        // 重绘后图片1分4 //用于最终中期合成
        if (infilledImages2.length > 0) {

          // const base64Parts16 = await this.splitBase64ImageInto16Parts(this.orangeChaoFengImage);
          // console.log('base64Parts16 length:', base64Parts16.length);

          // 检查 infilledImages 数组的长度是否足够
          if (infilledImages2.length < 2) {
            // console.error('infilledImages 数组的长度不足 2,无法继续执行合并操作。');
            return;
          }

          // 替换 base64Parts16 中对应的元素
          let base64Parts3, base64Parts4;
          try {
            base64Parts3 = await this.splitBase64ImageInto4Parts(infilledImages2[0]);
            // console.log('base64Parts1 length:', base64Parts3.length);
          } catch (error) {
            // console.error('分割 infilledImages[1] 时出错:', error);
            return;
          }

          try {
            base64Parts4 = await this.splitBase64ImageInto4Parts(infilledImages2[1]);
            // console.log('base64Parts2 length:', base64Parts4.length);
          } catch (error) {
            // console.error('分割 infilledImages[2] 时出错:', error);
            return;
          }

          base64Parts162Part[4] = base64Parts3[0];
          base64Parts162Part[5] = base64Parts3[1];
          base64Parts162Part[8] = base64Parts3[2];
          base64Parts162Part[9] = base64Parts3[3];

          base64Parts162Part[6] = base64Parts4[0];
          base64Parts162Part[7] = base64Parts4[1];
          base64Parts162Part[10] = base64Parts4[2];
          base64Parts162Part[11] = base64Parts4[3];


          // 调用 mergeBase64ImagesFrom16Parts 方法合成最终图像
          let finialImage;
          try {
            finialImage = await this.mergeBase64ImagesFrom16Parts(base64Parts162Part);
            mergedImage = finialImage;
          } catch (error) {
            console.error('合并 base64Parts16 时出错:', error);
            return;
          }

          // // 将合成后的图像添加到抽屉中
          // const newImage = {
          //   src: finialImage,
          //   name: `${this.formatDate(new Date())}_nai3.art_deep_scale_final.png`
          // };
          // this.generImages.unshift(newImage);
          // if (!this.drawerOpened) {
          //   this.generImage = newImage.src;
          //   this.imageName = newImage.name;
          // }

          //第三次拆开成为16份最后的修补
          // 调用splitBase64ImageInto16Parts方法
          const base64Parts163Part = await this.splitBase64ImageInto16Parts(mergedImage);

          // 将base64Parts16数组的元素按照指定规则分组
          const group5 = [base64Parts163Part[5], base64Parts163Part[6], base64Parts163Part[9], base64Parts163Part[10]];

          const groups3 = [group5];

          //创建对应mask
          // 遍历groups数组,合并每个子数组中的图片,并创建对应的黑白图片
          const mergedImages3 = [];
          const blackWhiteImages3 = [];
          for (let i = 0; i < groups3.length; i++) {
            const group3 = groups3[i];
            const mergedImage3 = await this.mergeBase64ImagesFrom4Parts(group3);
            mergedImages3.push(mergedImage3);

            const blackWhiteImage3 = await this.createBlackWhiteImage3(mergedImage3, i);
            blackWhiteImages3.push(blackWhiteImage3);
          }

          // 将合并后的图片添加到抽屉中
          for (const mergedImage3 of mergedImages3) {
            const newImage = {
              src: mergedImage,
              name: `${this.formatDate(new Date())}_nai3.art_deep_scale_merged.png`
            };
            this.addNewImage(newImage); // 生成的图添加到历史图片中
          }

          // console.log(mergedImages3);
          // console.log(blackWhiteImages3);
          // 将黑白图片添加到抽屉中
          for (const blackWhiteImage3 of blackWhiteImages3) {
            const newImage = {
              src: blackWhiteImage3,
              name: `${this.formatDate(new Date())}_nai3.art_deep_scale_black_white.png`
            };
            this.addNewImage(newImage); // 生成的图添加到历史图片中
          }


          // 开始处理中间1 个对应的局部重绘
          const infilledImages3 = [];
          for (let i = 0; i < mergedImages3.length; i++) {
            this.form.parameters.image = mergedImages3[i];
            this.form.parameters.mask = blackWhiteImages3[i];
            this.form.action = 'infill';

            let retryCount = 0;
            const maxRetries = 3;

            while (retryCount < maxRetries) {
              this.form.parameters.width = this.i2iChaoFengWidth * 2;
              this.form.parameters.height = this.i2iChaoFengHeight * 2;
              this.form.model = 'nai-diffusion-3-inpainting';
              this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
              this.form.parameters.mask = this.form.parameters.mask.replace(/^data:image\/(png|jpeg);base64,/, '');
              this.form.parameters.cfg_rescale = this.usepromptRescale;
              this.form.parameters.uncond_scale = this.undesiredStrength / 100;
              this.form.parameters.strength = 0.3;

              // ddim采样不支持 smea
              if (this.form.parameters.sampler === 'ddim') {
                this.form.parameters.sm = false;
                this.form.parameters.sm_dyn = false;
              }

              this.form.naiOfficeTag = this.naiOfficeTag;

              this.form.input = this.processInput(this.form.input);


              try {
                const response = await axios.post('/api/user/ai/generate-image3', this.form, {
                  headers: {
                    'authentication': this.$cookies.get('token')
                  },
                  timeout:180000
                });

                if (response.data.code === 200) {
                  requestSuccess = true; // 请求成功,退出循环
                  const newImage = {
                    src: 'data:image/png;base64,' + response.data.data,
                    name: this.riQi ? this.formatDate(new Date()) : response.data.msg
                  };
                  this.addNewImage(newImage); // 生成的图添加到历史图片中
                  if (!this.drawerOpened) {
                    this.generImage = newImage.src;
                    this.imageName = newImage.name;
                  }

                  // 处理其他逻辑
                  // ...

                  // 将新生成的图片添加到 infilledImages 数组中
                  infilledImages3.push(newImage.src);

                  break;
                } else if (response.data.code === 500) {
                  // 处理 500 错误
                }
              } catch (error) {
                // 处理错误
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  if (error.response.status === 413) {
                    this.$message.error('上传的图片过大，服务器无法处理。请尝试减小图片的大小。');
                  } else if (error.response.status === 401) {
                    this.$message.error('登录状态过期，请退出重新登录' + error.response.status);
                  } else {
                    this.$message.error('发生错误' + error.response.status);
                  }
                } else if (error.request) {
                  // The request was made but no response was received
                  this.$message.error('服务器没有响应。请检查你的网络连接。');
                } else {
                  // Something happened in setting up the request that triggered an Error
                  this.$message.error('请求发送时出错。');
                }
                this.buttonColor = '#F5F3C2';
              }

              retryCount++;
            }

            if (retryCount === maxRetries) {
              this.$message.error(`请求重试${maxRetries}次仍然失败,已跳过该请求。`);
              break;
            }
          }

          // 重绘后图片1分4 //用于最终中期合成
          if (infilledImages3.length > 0) {

            // const base64Parts16 = await this.splitBase64ImageInto16Parts(this.orangeChaoFengImage);
            // console.log('base64Parts163Part length:', base64Parts163Part.length);

            // 检查 infilledImages 数组的长度是否足够
            if (infilledImages3.length < 1) {
              // console.error('infilledImages 数组的长度不足 1,无法继续执行合并操作。');
              return;
            }

            // 替换 base64Parts16 中对应的元素
            let base64Parts5;
            try {
              base64Parts5 = await this.splitBase64ImageInto4Parts(infilledImages3[0]);
              // console.log('base64Parts3 length:', base64Parts3.length);
            } catch (error) {
              // console.error('分割 infilledImages[0] 时出错:', error);
              return;
            }


            base64Parts163Part[5] = base64Parts5[0];
            base64Parts163Part[6] = base64Parts5[1];
            base64Parts163Part[9] = base64Parts5[2];
            base64Parts163Part[10] = base64Parts5[3];




            // 调用 mergeBase64ImagesFrom16Parts 方法合成最终end图像
            let endFinialImage;
            try {
              endFinialImage = await this.mergeBase64ImagesFrom16Parts(base64Parts163Part);
              // mergedImage = endFinialImage;
            } catch (error) {
              console.error('合并 base64Parts16 时出错:', error);
              return;
            }

            // console.log(base64Parts163Part);
            // 将合成后的图像添加到抽屉中
            const newImage = {
              src: endFinialImage,
              name: `${this.formatDate(new Date())}_nai3.art_deep_scale_end_final.png`
            };
            this.addNewImage(newImage); // 生成的图添加到历史图片中
            if (!this.drawerOpened) {
              this.generImage = newImage.src;
              this.imageName = newImage.name;
            }
          }
        }
      }

      //===//===

      this.buttonColor = '#F5F3C2';
      this.buttonDisabled = false;
      this.imageLoading = false;
    },

    chaoFengCS() {

      this.splitBase64ImageInto4Parts(this.orangeChaoFengImage)
          .then(base64Parts => {
            // 在这里处理 4 个子图像的 base64 数据
            this.base641To4Parts = base64Parts;
            // console.log(base64Parts);
          })
          .catch(error => {
            // console.error('Failed to split image:', error);
          });

      // this.splitBase64ImageInto16Parts(this.orangeChaoFengImage)
      //     .then(base64Parts => {
      //       // 在这里处理 16 个子图像的 base64 数据
      //       this.base641To16Parts = base64Parts;
      //       console.log(base64Parts);
      //     })
      //     .catch(error => {
      //       console.error('Failed to split image:', error);
      //     });
      // console.log(this.base641To4Parts);
      // console.log(this.base641To16Parts);

    },
    chaoFengCS2() {

      // this.mergeBase64ImagesFrom4Parts(this.base641To4Parts);
      // this.mergeBase64ImagesFrom16Parts(this.base641To16Parts);
      // console.log(this.base64Form4To1Parts);
      // console.log(this.base64Form16To1Parts);

      // this.mergeBase64ImagesFrom4Parts(this.base641To4Parts)
      //     .then(mergedBase64 => {
      //       // 在这里处理合并后的 base64 数据
      //       console.log(mergedBase64);
      //     })
      //     .catch(error => {
      //       console.error('Failed to merge images:', error);
      //     })

      // this.mergeBase64ImagesFrom16Parts(this.base641To16Parts)
      //     .then(mergedBase64 => {
      //       // 在这里处理合并后的 base64 数据
      //       console.log(mergedBase64);
      //     })
      //     .catch(error => {
      //       console.error('Failed to merge images:', error);
      //     });
    },

    //创建对应黑白图Mask 用
    createBlackWhiteImage(base64String, index) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = img.width;
          const height = img.height;
          canvas.width = width;
          canvas.height = height;

          ctx.fillStyle = "black";
          ctx.fillRect(0, 0, width, height);

          let lineWidth = Math.floor(width * 0.1);
          let lineHeight = Math.floor(height * 0.1);

          switch (index) {
            case 0:
            case 1:
              ctx.fillStyle = "white";
              ctx.fillRect(width / 2 - lineWidth / 2, 0, lineWidth, height);
              break;
              // case 2:
              // case 3:
              //   ctx.fillStyle = "white";
              //   ctx.fillRect(0, height / 2 - lineHeight / 2, width, lineHeight);
              //   break;
          }

          resolve(canvas.toDataURL());
        };
        img.onerror = reject;
      });
    },
    createBlackWhiteImage2(base64String, index) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = img.width;
          const height = img.height;
          canvas.width = width;
          canvas.height = height;

          ctx.fillStyle = "black";
          ctx.fillRect(0, 0, width, height);

          let lineWidth = Math.floor(width * 0.1);
          let lineHeight = Math.floor(height * 0.1);

          switch (index) {
              // case 0:
              // case 1:
              //   ctx.fillStyle = "white";
              //   ctx.fillRect(width / 2 - lineWidth / 2, 0, lineWidth, height);
              //   break;
            case 0:
            case 1:
              ctx.fillStyle = "white";
              ctx.fillRect(0, height / 2 - lineHeight / 2, width, lineHeight);
              break;
          }

          resolve(canvas.toDataURL());
        };
        img.onerror = reject;
      });
    },
    createBlackWhiteImage3(base64String, index) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = img.width;
          const height = img.height;
          canvas.width = width;
          canvas.height = height;

          ctx.fillStyle = "black";
          ctx.fillRect(0, 0, width, height);

          let rectWidth = Math.floor(width * 0.2);
          let rectHeight = Math.floor(height * 0.2);

          switch (index) {
            case 0:
            case 1:
              ctx.fillStyle = "white";
              ctx.fillRect(width / 2 - rectWidth / 2, height / 2 - rectHeight / 2, rectWidth, rectHeight);
              break;
          }

          resolve(canvas.toDataURL());
        };
        img.onerror = reject;
      });
    },

    //图片16合1 //成品
    mergeBase64ImagesFrom16Parts(base64Parts) {
      return new Promise((resolve, reject) => {
        // 获取第一个子图像的尺寸,假设所有子图像尺寸相同
        const img = new Image();
        img.src = base64Parts[0];
        img.onload = () => {
          const width = img.width * 4;
          const height = img.height * 4;
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");

          let loadedCount = 0;
          for (let i = 0; i < 16; i++) {
            const img = new Image();
            img.src = base64Parts[i];
            img.onload = () => {
              const x = (i % 4) * img.width;
              const y = Math.floor(i / 4) * img.height;
              ctx.drawImage(img, x, y);
              loadedCount++;
              if (loadedCount === 16) {
                resolve(canvas.toDataURL());
              }
            }
          }
        };
        img.onerror = reject;
      });
    },

    //图片1分16 //用于提取进行局部接缝
    splitBase64ImageInto16Parts(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = img.width;
          const height = img.height;
          const subWidth = width / 4;
          const subHeight = height / 4;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);

          const base64Parts = [];
          for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 4; j++) {
              const left = j * subWidth;
              const top = i * subHeight;
              const imageData = ctx.getImageData(left, top, subWidth, subHeight);
              const tempCanvas = document.createElement("canvas");
              tempCanvas.width = subWidth;
              tempCanvas.height = subHeight;
              tempCanvas.getContext("2d").putImageData(imageData, 0, 0);
              base64Parts.push(tempCanvas.toDataURL());
            }
          }
          resolve(base64Parts);
        };
        img.onerror = reject;
      });
    },

    //图片4合1 //用于二次切割
    mergeBase64ImagesFrom4Parts(base64Parts) {
      return new Promise((resolve, reject) => {
        // 获取第一个子图像的尺寸,假设所有子图像尺寸相同
        const img = new Image();
        img.src = base64Parts[0];
        img.onload = () => {
          const width = img.width * 2;
          const height = img.height * 2;
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");

          let loadedCount = 0;
          for (let i = 0; i < 4; i++) {
            const img = new Image();
            img.src = base64Parts[i];
            img.onload = () => {
              const x = (i % 2) * img.width;
              const y = Math.floor(i / 2) * img.height;
              ctx.drawImage(img, x, y);
              loadedCount++;
              if (loadedCount === 4) {
                resolve(canvas.toDataURL());
              }
            }
          }
        };
        img.onerror = reject;
      });
    },


    //图片1分4 //用于图生图
    splitBase64ImageInto4Parts(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = img.width;
          const height = img.height;
          const subWidth = width / 2;
          const subHeight = height / 2;

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);

          const base64Parts = [];
          for (let i = 0; i < 2; i++) {
            for (let j = 0; j < 2; j++) {
              const left = j * subWidth;
              const top = i * subHeight;
              const imageData = ctx.getImageData(left, top, subWidth, subHeight);
              const tempCanvas = document.createElement("canvas");
              tempCanvas.width = subWidth;
              tempCanvas.height = subHeight;
              tempCanvas.getContext("2d").putImageData(imageData, 0, 0);
              base64Parts.push(tempCanvas.toDataURL());
            }
          }
          resolve(base64Parts);
        };
        img.onerror = reject;
      });
    },

    toChaoFeng() {
      if (this.generImage !== '') {
        // 获取图片容器
        const imageContainer = document.getElementById('imageContainerChaoFeng');

        // 创建一个新的 Image 对象
        const img = new Image();

        // 当图片被加载时,获取图片的宽度和高度
        img.onload = () => {
          let newWidth = img.width;
          let newHeight = img.height;

          // 如果开启了大图就,进行额外的放大

          // 创建一个新的 canvas 元素
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // 设置 canvas 的宽度和高度
          canvas.width = newWidth;
          canvas.height = newHeight;

          // 将图片绘制到 canvas 上
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // 将 canvas 转换为 base64 格式的图片
          const resizedImageSrc = canvas.toDataURL('image/png');

          // 填充图片容器的内容
          if (imageContainer) {
            imageContainer.innerHTML = `<img src="${resizedImageSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;
          }
        };

        // 设置图片的 src,开始加载图片
        img.src = this.generImage;
      }
    },

    handleUploadChaoFeng(event) {
      const file = event.target.files[0];

      // 检查文件是否存在并且是图片格式
      if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageSrc = e.target.result;

          // 创建一个新的 Image 对象
          const img = new Image();
          img.src = imageSrc;

          img.onload = () => {
            // 获取容器的宽高
            const imageContainer = document.getElementById('imageContainerChaoFeng');
            const containerWidth = imageContainer.offsetWidth;
            const containerHeight = imageContainer.offsetHeight;

            // 计算调整后的图片尺寸
            let newWidth, newHeight;

            const aspectRatio = img.naturalWidth / img.naturalHeight;
            if (containerWidth / containerHeight > aspectRatio) {
              newWidth = containerHeight * aspectRatio;
              newHeight = containerHeight;
            } else {
              newWidth = containerWidth;
              newHeight = containerWidth / aspectRatio;
            }

            // 将处理后的图片显示在容器中
            const resizedImg = document.createElement('img');
            resizedImg.src = imageSrc;
            resizedImg.style.width = `${newWidth}px`;
            resizedImg.style.height = `${newHeight}px`;
            resizedImg.style.display = 'block';
            resizedImg.style.margin = '0 auto'; // 将图片水平居中
            imageContainer.innerHTML = '';
            imageContainer.appendChild(resizedImg);

            // 存储原始图片数据到 orangeChaoFengImage
            this.orangeChaoFengImage = imageSrc;

            // 将原始图片的宽度和高度除以2,赋值给 i2iChaoFengWidth 和 i2iChaoFengHeight
            this.i2iChaoFengWidth = img.naturalWidth / 2;
            this.i2iChaoFengHeight = img.naturalHeight / 2;
          };
        };

        reader.readAsDataURL(file);
      } else {
        // 如果文件不存在或者不是图片格式，显示错误消息
        this.$message.error('上传的图片格式错误');
      }
    },

    convertImageTo640Size(base64) {
      return new Promise((resolve, reject) => {
        // 创建一个临时画布
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        // 创建一个图像对象
        const img = new Image();
        img.src = base64;

        // 在图像加载完成后进行处理
        img.onload = () => {
          // 设置画布的尺寸为 640x640
          tempCanvas.width = 640;
          tempCanvas.height = 640;

          // 计算缩放比例
          const scaleX = 640 / img.width;
          const scaleY = 640 / img.height;
          const scale = Math.min(scaleX, scaleY);

          // 计算缩放后的图像尺寸
          const scaledWidth = Math.floor(img.width * scale);
          const scaledHeight = Math.floor(img.height * scale);

          // 计算图像在画布上的位置
          const x = Math.floor((640 - scaledWidth) / 2);
          const y = Math.floor((640 - scaledHeight) / 2);

          // 在画布上绘制缩放后的图像
          tempCtx.drawImage(img, 0, 0, img.width, img.height, x, y, scaledWidth, scaledHeight);

          // 获取处理后的图像 base64 编码
          const convertedBase64 = tempCanvas.toDataURL('image/png');

          // 返回转换后的 base64
          resolve(convertedBase64);
        };

        // 如果图像加载失败
        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };
      });
    },


    convertImageTo1024Size(base64) {
      return new Promise((resolve, reject) => {
        // 创建一个临时画布
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        // 创建一个图像对象
        const img = new Image();
        img.src = base64;

        // 在图像加载完成后进行处理
        img.onload = () => {
          // 设置画布的尺寸为 1024x1024
          tempCanvas.width = 1024;
          tempCanvas.height = 1024;

          // 计算缩放比例
          const scaleX = 1024 / img.width;
          const scaleY = 1024 / img.height;
          const scale = Math.max(scaleX, scaleY);

          // 计算缩放后的图像尺寸
          const scaledWidth = Math.floor(img.width * scale);
          const scaledHeight = Math.floor(img.height * scale);

          // 计算图像在画布上的位置
          const x = Math.floor((1024 - scaledWidth) / 2);
          const y = Math.floor((1024 - scaledHeight) / 2);

          // 在画布上绘制缩放后的图像
          tempCtx.drawImage(img, 0, 0, img.width, img.height, x, y, scaledWidth, scaledHeight);

          // 获取处理后的图像 base64 编码
          const convertedBase64 = tempCanvas.toDataURL('image/png');

          // 返回转换后的 base64
          resolve(convertedBase64);
        };

        // 如果图像加载失败
        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };
      });
    },

    convertImageDataAsync(base64, reverseBrush) {
      return new Promise((resolve, reject) => {
        // 创建一个临时画布
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        // 从 base64 创建一个图像对象
        const img = new Image();
        img.src = base64;

        // 在图像加载完成后进行处理
        img.onload = () => {
          // 设置画布的尺寸与图像相同
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;

          // 将图像绘制到画布上
          tempCtx.drawImage(img, 0, 0);

          // 获取画布上的像素数据
          const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
          const data = imageData.data;

          // 对像素数据进行处理
          for (let i = 0; i < data.length; i += 4) {
            // 如果当前像素是红色（无论透明度如何）
            if (data[i] > data[i + 1] && data[i] > data[i + 2]) {
              if (reverseBrush) {
                // 如果 reverseBrush 为 true，将其变为黑色
                data[i] = 0;       // R
                data[i + 1] = 0;   // G
                data[i + 2] = 0;   // B
                data[i + 3] = 255; // A
              } else {
                // 如果 reverseBrush 为 false，将其变为白色
                data[i] = 255;     // R
                data[i + 1] = 255; // G
                data[i + 2] = 255; // B
                data[i + 3] = 255; // A
              }
            } else {
              if (reverseBrush) {
                // 如果 reverseBrush 为 true，变为白色
                data[i] = 255;     // R
                data[i + 1] = 255; // G
                data[i + 2] = 255; // B
                data[i + 3] = 255; // A
              } else {
                // 如果 reverseBrush 为 false，变为黑色
                data[i] = 0;       // R
                data[i + 1] = 0;   // G
                data[i + 2] = 0;   // B
                data[i + 3] = 255; // A
              }
            }
          }

          // 将处理后的像素数据放回画布
          tempCtx.putImageData(imageData, 0, 0);

          // 对处理后的图像进行高斯模糊
          const blurredImageData = this.gaussianBlur(tempCtx, 0, 0, tempCanvas.width, tempCanvas.height, 6);

          // 将模糊后的像素数据放回画布
          tempCtx.putImageData(blurredImageData, 0, 0);

          // 获取处理后的图像 base64 编码
          const convertedBase64 = tempCanvas.toDataURL('image/png');

          // 返回转换后的 base64
          resolve(convertedBase64);
        };

        // 如果图像加载失败
        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };
      });
    },


    gaussianBlur(ctx, x, y, width, height, radius) {
      const pixels = ctx.getImageData(x, y, width, height);
      const blurredPixels = ctx.createImageData(width, height);

      for (let i = 0; i < pixels.data.length; i += 4) {
        const x = (i / 4) % width;
        const y = Math.floor(i / 4 / width);

        const redSum = this.sumPixelValues(pixels.data, x, y, width, height, radius, 0);
        const greenSum = this.sumPixelValues(pixels.data, x, y, width, height, radius, 1);
        const blueSum = this.sumPixelValues(pixels.data, x, y, width, height, radius, 2);
        const alphaSum = this.sumPixelValues(pixels.data, x, y, width, height, radius, 3);

        const redAvg = redSum / (Math.PI * radius ** 2);
        const greenAvg = greenSum / (Math.PI * radius ** 2);
        const blueAvg = blueSum / (Math.PI * radius ** 2);
        const alphaAvg = alphaSum / (Math.PI * radius ** 2);

        blurredPixels.data[i] = redAvg;
        blurredPixels.data[i + 1] = greenAvg;
        blurredPixels.data[i + 2] = blueAvg;
        blurredPixels.data[i + 3] = alphaAvg;
      }

      return blurredPixels;
    },

    sumPixelValues(data, x, y, width, height, radius, offset) {
      let sum = 0;
      for (let i = -radius; i <= radius; i++) {
        for (let j = -radius; j <= radius; j++) {
          const newX = x + j;
          const newY = y + i;

          if (newX >= 0 && newX < width && newY >= 0 && newY < height) {
            const pixel = (newY * width + newX) * 4 + offset;
            sum += data[pixel];
          }
        }
      }
      return sum;
    },

    mergeImages(base64ImageOriginal, base64ImageOverlay, leftPercent, topPercent) {
      return new Promise((resolve, reject) => {
        const imgOriginal = new Image();
        const imgOverlay = new Image();

        // 加载原始图像
        imgOriginal.src = this.ensureBase64Prefix(base64ImageOriginal);
        imgOverlay.src = this.ensureBase64Prefix(base64ImageOverlay);

        let loadCount = 0;

        const checkAndProceed = () => {
          loadCount++;
          if (loadCount === 2) { // 确保两张图都已加载
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = imgOriginal.width;
            canvas.height = imgOriginal.height;

            // 首先绘制原始图像
            ctx.drawImage(imgOriginal, 0, 0);

            // 计算覆盖图的位置
            const overlayX = (leftPercent / 100) * imgOriginal.width - imgOverlay.width / 2;
            const overlayY = (topPercent / 100) * imgOriginal.height - imgOverlay.height / 2;

            // 绘制覆盖图
            ctx.drawImage(imgOverlay, overlayX, overlayY);

            // 输出合成图的Base64字符串
            const mergedImageDataURL = canvas.toDataURL('image/png');
            resolve(mergedImageDataURL);

            canvas.remove();
          }
        };

        imgOriginal.onload = checkAndProceed;
        imgOverlay.onload = checkAndProceed;

        imgOriginal.onerror = () => {
          reject(new Error('Original image loading failed'));
        };

        imgOverlay.onerror = () => {
          reject(new Error('Overlay image loading failed'));
        };
      });
    },
    ensureBase64Prefix(base64Image) {
      if (!base64Image.startsWith('data:image/png;base64,') && !base64Image.startsWith('data:image/jpeg;base64,')) {
        return 'data:image/png;base64,' + base64Image;
      }
      return base64Image;
    },

    cropImage(base64Image, centerXPercent, centerYPercent, cropWidth, cropHeight) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        // 检查并补全Base64前缀
        if (!base64Image.startsWith('data:image/png;base64,') && !base64Image.startsWith('data:image/jpeg;base64,')) {
          base64Image = 'data:image/png;base64,' + base64Image;
        }

        img.src = base64Image; // 从Base64字符串加载图像

        img.onload = () => {
          const tempCanvas = document.createElement('canvas');
          const ctx = tempCanvas.getContext('2d');

          tempCanvas.width = cropWidth;
          tempCanvas.height = cropHeight;

          const centerX = (centerXPercent / 100) * img.width;
          const centerY = (centerYPercent / 100) * img.height;

          const startX = centerX - cropWidth / 2;
          const startY = centerY - cropHeight / 2;

          const cutX = Math.max(startX, 0);
          const cutY = Math.max(startY, 0);

          const actualCropWidth = Math.min(img.width - cutX, cropWidth);
          const actualCropHeight = Math.min(img.height - cutY, cropHeight);

          ctx.drawImage(img, cutX, cutY, actualCropWidth, actualCropHeight, 0, 0, cropWidth, cropHeight);

          const croppedImageDataURL = tempCanvas.toDataURL('image/png');
          resolve(croppedImageDataURL); // 保存切割后的图像的Base64字符串

          tempCanvas.remove();
        };

        img.onerror = () => {
          console.error('Image loading failed');
          reject(new Error('Image loading failed'));
        };
      });
    },

    // cropImageAndCalculateCenter() {
    //   // 返回一个新的Promise对象
    //   return new Promise((resolve, reject) => {
    //     const img = new Image();
    //     img.src = this.mask; // 从Base64字符串加载图像
    //
    //     img.onload = () => {
    //       const tempCanvas = document.createElement('canvas');
    //       const ctx = tempCanvas.getContext('2d');
    //
    //       tempCanvas.width = 1216;
    //       tempCanvas.height = 832;
    //
    //       const centerX = (this.centerXPercent / 100) * img.width;
    //       const centerY = (this.centerYPercent / 100) * img.height;
    //
    //       const startX = centerX - tempCanvas.width / 2;
    //       const startY = centerY - tempCanvas.height / 2;
    //
    //       const cutX = Math.max(startX, 0);
    //       const cutY = Math.max(startY, 0);
    //
    //       const cutWidth = Math.min(img.width, cutX + tempCanvas.width) - cutX;
    //       const cutHeight = Math.min(img.height, cutY + tempCanvas.height) - cutY;
    //
    //       ctx.drawImage(img, cutX, cutY, cutWidth, cutHeight, 0, 0, tempCanvas.width, tempCanvas.height);
    //
    //       // 将canvas转换为Base64格式的图片，并保存到一个新的变量中
    //       const cutImageDataURL = tempCanvas.toDataURL('image/png');
    //       this.croppedMask = cutImageDataURL; // 保存切割后的图像的Base64字符串
    //
    //       tempCanvas.remove();
    //
    //       // 成功时调用resolve
    //       resolve(this.croppedMask);
    //     };
    //
    //     img.onerror = () => {
    //       console.error('Image loading failed');
    //       reject(new Error('Image loading failed'));
    //     };
    //   });
    // },

    BigInfill() {

    },

    // 启动选择框模式
    drawSelectionXiTu() {
      this.mode = 'selection';
      this.enableDrawingXiTu(); // 确保启用绘图功能但不清除画布
    },
    drawingModingXiTu() {
      this.mode = 'drawing';
      this.enableDrawingXiTu(); // 同上，启用绘图但保留已有绘制内容
    },

    // 启动选择框模式
    drawSelection() {
      this.mode = 'selection';
      this.enableDrawing(); // 确保启用绘图功能但不清除画布
    },
    drawingModing() {
      this.mode = 'drawing';
      this.enableDrawing(); // 同上，启用绘图但保留已有绘制内容
    },
    // cutImageFromCenter() {
    //   return new Promise((resolve, reject) => {
    //     const img = new Image();
    //     img.src = this.form.parameters.image; // 从Base64字符串加载图像
    //
    //     img.onload = () => {
    //       const tempCanvas = document.createElement('canvas');
    //       const ctx = tempCanvas.getContext('2d');
    //
    //       tempCanvas.width = 1216;
    //       tempCanvas.height = 832;
    //
    //       const centerX = (this.centerXPercent / 100) * img.width;
    //       const centerY = (this.centerYPercent / 100) * img.height;
    //
    //       const startX = centerX - tempCanvas.width / 2;
    //       const startY = centerY - tempCanvas.height / 2;
    //
    //       const cutX = Math.max(startX, 0);
    //       const cutY = Math.max(startY, 0);
    //
    //       const cutWidth = Math.min(img.width, cutX + tempCanvas.width) - cutX;
    //       const cutHeight = Math.min(img.height, cutY + tempCanvas.height) - cutY;
    //
    //       ctx.drawImage(img, cutX, cutY, cutWidth, cutHeight, 0, 0, tempCanvas.width, tempCanvas.height);
    //
    //       const cutImageDataURL = tempCanvas.toDataURL('image/png');
    //       this.croppedImage = cutImageDataURL; // 保存切割后的图像的Base64字符串
    //
    //       tempCanvas.remove();
    //
    //       resolve(this.croppedImage);
    //     };
    //
    //     img.onerror = () => {
    //       console.error('Image loading failed');
    //       reject(new Error('Image loading failed'));
    //     };
    //   });
    // },


    // 处理画布点击事件以绘制蓝色矩形框
    handleCanvasClick(e) {
      if (!this.isSelectingBox) return; // 如果不在选择框模式，不执行任何操作

      const rect = this.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      // 以点击点为中心，绘制矩形框
      this.ctx.strokeStyle = 'blue';
      this.ctx.lineWidth = 5;
      this.ctx.beginPath();
      this.ctx.rect(x - this.bigInfillWidth / 2, y - this.bigInfillHeight / 2, this.bigInfillWidth, this.bigInfillHeight);
      this.ctx.stroke();

      this.isSelectingBox = false; // 完成绘制后退出选择框模式
    },


    clearPrefixImage() {
      this.isVeber = false;
      this.form.parameters.reference_image = '';
      this.form.parameters.add_original_image = false;

      // 获取图片容器
      const imageContainer = document.getElementById('imageContainerVibe');

      // 清空图片容器的内容
      if (imageContainer) {
        imageContainer.innerHTML = '';
      }

      // 重置文件输入元素的状态
      document.getElementById('uploadBtnVibe').value = '';

    },
    sureXitu() {
      this.showDetailInfill = true;
    },

    enableDrawingXiTu() {

      this.isCacheMask = false;
      // 先检查主画布是否已经初始化
      if (!this.canvasInitialized) {
        this.enableDrawing();
      }

      // 检查并重新初始化副画布
      if (!this.canvasXiTuInitialized) {
        this.canvasXiTu = document.createElement('canvas');
        this.ctxXiTu = this.canvasXiTu.getContext('2d');
        const modalBody = document.querySelector('.modal-body');

        // 设置 modalBody 的相对定位,并设置为 flex 容器
        modalBody.style.position = 'relative';
        modalBody.style.display = 'flex';
        modalBody.style.justifyContent = 'center';
        modalBody.style.alignItems = 'center';

        const img = modalBody.querySelector('img');

        // 获取 modalBody 中图片的实际显示尺寸
        this.modalBodyImageWidth = img.offsetWidth;
        this.modalBodyImageHeight = img.offsetHeight;

        // 设置 canvasXiTu 的尺寸与 modalBody 中图片的显示尺寸一致
        this.canvasXiTu.width = this.modalBodyImageWidth;
        this.canvasXiTu.height = this.modalBodyImageHeight;

        // 调整 canvasXiTu 位置以覆盖图片
        this.canvasXiTu.style.position = 'absolute';
        this.canvasXiTu.style.left = '50%';
        this.canvasXiTu.style.top = '50%';
        this.canvasXiTu.style.transform = 'translate(-50%, -50%)';

        modalBody.appendChild(this.canvasXiTu);

        // 标记画布已经初始化
        this.canvasXiTuInitialized = true;
      }

      // 画笔事件绑定
      if (this.canvasXiTu) {
        this.canvasXiTu.onmousedown = this.startDrawingXiTu.bind(this);
        this.canvasXiTu.onmousemove = this.drawXiTu.bind(this);
        this.canvasXiTu.onmouseup = () => {
          this.stopDrawingXiTu();
          this.saveDrawingXiTu(); // 在绘图结束后自动保存
        };

        // 触摸事件绑定
        this.canvasXiTu.ontouchstart = (e) => {
          e.preventDefault();
          this.startDrawingXiTu(e.touches[0]);
        };
        this.canvasXiTu.ontouchmove = (e) => {
          e.preventDefault();
          this.drawXiTu(e.touches[0]);
        };
        this.canvasXiTu.ontouchend = () => {
          this.stopDrawingXiTu();
          this.saveDrawingXiTu(); // 在绘图结束后自动保存
        };
      }
    },


    startDrawingXiTu(e) {
      // 根据当前模式执行不同的逻辑
      if (this.mode === 'selection') {
        this.isDrawingXiTu = true;

        // 获取鼠标在画布上的位置
        const mouseX = e.clientX - this.canvasXiTu.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvasXiTu.getBoundingClientRect().top;

        // 假设原始矩形框尺寸为1216x832
        const originalRectWidth = this.bigInfillWidth;
        const originalRectHeight = this.bigInfillHeight;

        // 计算缩放比例
        const scaleX = this.modalBodyImageWidth / this.uploadedImageNaturalWidth;
        const scaleY = this.modalBodyImageHeight / this.uploadedImageNaturalHeight;

        // 应用相同的缩放比例到矩形框尺寸
        const scaledRectWidth = originalRectWidth * scaleX;
        const scaledRectHeight = originalRectHeight * scaleY;

        // 计算矩形框的初始起始位置,以鼠标中心为准
        let startX = mouseX - scaledRectWidth / 2;
        let startY = mouseY - scaledRectHeight / 2;

        // 确保矩形框不超出画布边界
        startX = Math.max(startX, 0);
        startY = Math.max(startY, 0);

        if (startX + scaledRectWidth > this.canvasXiTu.width) {
          startX = this.canvasXiTu.width - scaledRectWidth;
        }
        if (startY + scaledRectHeight > this.canvasXiTu.height) {
          startY = this.canvasXiTu.height - scaledRectHeight;
        }

        // 重新计算框的中心点相对于整个画布的百分比
        const centerX = startX + scaledRectWidth / 2;
        const centerY = startY + scaledRectHeight / 2;
        const centerXPercent = (centerX / this.canvasXiTu.width) * 100;
        const centerYPercent = (centerY / this.canvasXiTu.height) * 100;

        // 存储到data中
        this.centerXPercent = centerXPercent;
        this.centerYPercent = centerYPercent;

        // 清除之前的画布内容
        // this.ctxXiTu.clearRect(0, 0, this.canvasXiTu.width, this.canvasXiTu.height);

        this.ctxXiTu.strokeStyle = 'rgba(0, 255, 255, 1)'; // 青色
        this.ctxXiTu.lineWidth = 6;

        // 绘制矩形
        this.ctxXiTu.beginPath();
        this.ctxXiTu.rect(startX, startY, scaledRectWidth, scaledRectHeight);
        this.ctxXiTu.stroke();

        // 同步到主画布,并进行缩放
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.canvasXiTu, 0, 0, this.canvasXiTu.width, this.canvasXiTu.height, 0, 0, this.canvas.width, this.canvas.height);

        this.isDrawingXiTu = false; // 重置绘制状态
      } else if (this.mode === 'drawing') {
        this.isDrawingXiTu = true;
        this.ctxXiTu.beginPath();
        this.ctxXiTu.moveTo(e.clientX - this.canvasXiTu.getBoundingClientRect().left, e.clientY - this.canvasXiTu.getBoundingClientRect().top);
        this.lastX = e.clientX;
        this.lastY = e.clientY;

        // 同步到主画布,并进行缩放
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.canvasXiTu, 0, 0, this.canvasXiTu.width, this.canvasXiTu.height, 0, 0, this.canvas.width, this.canvas.height);
      }
    },


    drawXiTu(e) {
      if (!this.isDrawingXiTu) return;

      if (this.mode === 'selection') {
        // 选择框模式下的绘图逻辑
        const mouseX = e.clientX - this.canvasXiTu.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvasXiTu.getBoundingClientRect().top;

        const originalRectWidth = this.bigInfillWidth;
        const originalRectHeight = this.bigInfillHeight;

        const scaleX = this.modalBodyImageWidth / this.uploadedImageNaturalWidth;
        const scaleY = this.modalBodyImageHeight / this.uploadedImageNaturalHeight;

        const scaledRectWidth = originalRectWidth * scaleX;
        const scaledRectHeight = originalRectHeight * scaleY;

        const startX = mouseX - scaledRectWidth / 2;
        const startY = mouseY - scaledRectHeight / 2;

        // this.ctxXiTu.clearRect(0, 0, this.canvasXiTu.width, this.canvasXiTu.height);

        this.ctxXiTu.strokeStyle = 'rgba(0, 255, 255, 1)'; // 青色
        this.ctxXiTu.lineWidth = 6;

        this.ctxXiTu.beginPath();
        this.ctxXiTu.rect(startX, startY, scaledRectWidth, scaledRectHeight);
        this.ctxXiTu.stroke();

        // 同步到主画布,并进行缩放
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.canvasXiTu, 0, 0, this.canvasXiTu.width, this.canvasXiTu.height, 0, 0, this.canvas.width, this.canvas.height);
      } else if (this.mode === 'drawing') {
        // 绘图模式下的绘图逻辑
        const mouseX = e.clientX - this.canvasXiTu.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvasXiTu.getBoundingClientRect().top;

        this.ctxXiTu.strokeStyle = 'rgba(255, 0, 0, 0.5)'; // 红色半透明
        this.ctxXiTu.lineWidth = this.brushSize;

        this.ctxXiTu.lineTo(mouseX, mouseY);
        this.ctxXiTu.stroke();

        this.ctxXiTu.fillStyle = 'rgba(255, 0, 0, 1)'; // 红色
        this.ctxXiTu.beginPath();
        this.ctxXiTu.arc(mouseX, mouseY, this.brushSize / 2, 0, Math.PI * 2, true);
        this.ctxXiTu.closePath();
        this.ctxXiTu.fill();

        this.lastX = mouseX;
        this.lastY = mouseY;

        // 同步到主画布,并进行缩放
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.canvasXiTu, 0, 0, this.canvasXiTu.width, this.canvasXiTu.height, 0, 0, this.canvas.width, this.canvas.height);
      }
    },

    stopDrawingXiTu() {
      this.isDrawingXiTu = false;
    },

    saveDrawingXiTu() {
      // 创建一个临时画布来处理图像
      let tempCanvas = document.createElement('canvas');
      let tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = this.canvasXiTu.width;
      tempCanvas.height = this.canvasXiTu.height;

      // 将当前画布的内容复制到临时画布
      tempCtx.drawImage(this.canvasXiTu, 0, 0);

      // 设置线宽为 1
      tempCtx.lineWidth = 1;

      // 转换为 Base64 格式的 PNG 图片
      this.mask = tempCanvas.toDataURL('image/png');

      // 清理临时画布
      tempCanvas = null;

      // 同步到主画布
      this.saveDrawing();
    },


    sureInputFormDrou() {
      this.showSuitcase = false;
      this.processData();
      //
      this.droubout = '';
      // 添加代码
      this.$message({
        message: '导入成功，导入的词已经拼接在input后面',
        type: 'success'
      });
    },
    processData() {
      const metadata = this.droubout;
      // 使用正则表达式匹配并处理元数据
      const processedMetadata = metadata
          .replace(/\?\s|(\d+\.?\d*)k\s/g, ',')
          .replace(/(\d+\.?\d*)M\s/g, '$1,000,000')
          .replace(/(\d+\.?\d*)k/g, '$1,000')
          .replace(/\d+[,|.]\d+|\d+/g, '')
          .replace(/\s+/g, ' ') // 多余的空格替换为一个空格
          .trim(); // 删除首尾空格
      const metadataList = processedMetadata.split('\n'); // 按行拆分处理后的元数据字符串
      let output = ''; // 初始化输出字符串
      let artistFound = false; // 初始化artist是否找到的标志

      console.log(metadataList);

      // 遍历处理后的元数据列表
      for (const item of metadataList) {
        // 如果当前行不为空
        if (item.trim() !== '') {
          // 如果当前行包含"artist"，则给该行加上"artist:"前缀，并将artistFound标志设置为true
          if (item.toLowerCase().includes('artist')) {
            output += `${item}, `;
            artistFound = true;
          } else {
            // 如果当前行不包含"artist"，且artistFound标志为true，则给该行加上"artist:"前缀
            if (artistFound) {
              output += `artist:${item}, `;
            } else {
              // 如果当前行不包含"artist"，且artistFound标志为false，则直接加上该行内容
              output += `${item}, `;
            }
          }
        }
      }
      // 去除最后一个逗号和空格
      output = output.slice(0, -2);
      // 替换连续的两个英文逗号为一个英文逗号
      output = output.replace(/,,/g, ',');
      this.form.input = this.form.input + output; // 更新处理后的数据
    },


    getArtistsString() {
      let artists = this.getRandomArtists();
      if (this.artPrefix) {
        artists = this.addPrefixToArtists(artists, 'artist:');
      }
      if (this.artRandomPower) {
        //随机调权
        artists = this.addRandomBrackets(artists);
      }
      return this.artistsToString(artists);
    },
    updateLowTop() {
      this.artLower = this.valueArt[0];
      this.artTop = this.valueArt[1];
    },
    artistsToString(artists) {
      return artists.join(', ') + ', ';
    },
    addRandomBrackets(artists) {
      return artists.map(artist => {
        const num = Math.floor(Math.random() * 3); // 只产生0, 1, 2三个数
        switch (num) {
          case 0:
            return artist; // 不添加括号
          case 1:
            return `[${artist}]`; // 添加 []
          case 2:
            return `{${artist}}`; // 添加 {}
          default:
            return artist; // 默认情况下不添加括号（实际上不会发生，因为num只能是0, 1, 2）
        }
      });
    },
    addPrefixToArtists(artists, prefix) {
      // 遍历数组，对每个元素添加前缀
      return artists.map(artist => `${prefix}${artist}`);
    },
    fisherYatesShuffle(array) {
      let count = array.length;
      while (count) {
        let index = Math.floor(Math.random() * count--);
        [array[count], array[index]] = [array[index], array[count]];
      }
    },
    getRandomArtists() {
      // 生成一个在 artLower 和 artTop 之间的随机数
      const num = Math.floor(Math.random() * (this.artTop - this.artLower + 1)) + this.artLower;

      // 根据 artRandom12660 和 artRandom6482 的值来决定从哪个数组中抽取字符串
      let artists = [];
      if (this.artRandom12660 && this.artRandom6482) {
        // 从两个数组中都抽取
        artists = [...this.names12660, ...this.names6482];
      } else if (this.artRandom12660) {
        // 只从 names12660 中抽取
        artists = this.names12660;
      } else if (this.artRandom6482) {
        // 只从 names6482 中抽取
        artists = this.names6482;
      } else if (this.artRandom883) {
        //只从精选库抽取
        artists = this.names883;
      } else {
        // 如果两个都为 false，返回空数组
        return [];
      }

      // 使用 Fisher-Yates shuffle 算法随机打乱数组
      this.fisherYatesShuffle(artists);

      // 取前 num 个元素
      return artists.slice(0, num);
    },

    //双击下载选中的图片
    onImageClick() {
      const currentTime = new Date().getTime();
      if (this.lastClickTime && currentTime - this.lastClickTime <= this.doubleClickDelay) {
        this.downloadImage();
      }
      this.lastClickTime = currentTime;
    },

    bindDrawingEvents(canvas, ctx) {
      let isDrawing = false;
      let lastX = 0;
      let lastY = 0;

      // 鼠标按下开始绘制
      canvas.onmousedown = (e) => {
        isDrawing = true;
        [lastX, lastY] = [e.offsetX, e.offsetY];
      };

      // 鼠标移动进行绘制
      canvas.onmousemove = (e) => {
        if (!isDrawing) return;
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(e.offsetX, e.offsetY);
        ctx.stroke();
        [lastX, lastY] = [e.offsetX, e.offsetY];
      };

      // 鼠标释放结束绘制
      canvas.onmouseup = () => {
        isDrawing = false;
        ctx.closePath();
        this.saveDrawingXiTu();
      };

      // 鼠标离开元素区域结束绘制
      canvas.onmouseleave = () => {
        isDrawing = false;
      };

      // 触摸事件监听器支持触摸屏设备
      canvas.ontouchstart = (e) => {
        isDrawing = true;
        [lastX, lastY] = [e.touches[0].clientX, e.touches[0].clientY];
      };

      canvas.ontouchmove = (e) => {
        if (!isDrawing) return;
        e.preventDefault(); // 阻止滚动
        ctx.beginPath();
        ctx.moveTo(lastX - canvas.offsetLeft, lastY - canvas.offsetTop);
        ctx.lineTo(e.touches[0].clientX - canvas.offsetLeft, e.touches[0].clientY - canvas.offsetTop);
        ctx.stroke();
        [lastX, lastY] = [e.touches[0].clientX, e.touches[0].clientY];
      };

      canvas.ontouchend = () => {
        isDrawing = false;
        this.saveDrawingXiTu();
      };

      canvas.ontouchcancel = () => {
        isDrawing = false;
      };
    },

    handleClose() {
      // 用户确认关闭操作
      this.form.action = 'infill'; // 更新表单操作为infill
      // console.log(this.mask2); // 控制台输出当前的mask2值，用于调试
      // console.log(this.form.parameters.mask); // 控制台输出form中mask的当前值，用于调试
      // this.form.parameters.mask = this.mask2; // 将mask2的值保存到表单的mask参数中
      this.showDetailInfill = false; // 关闭模态框
      this.saveDrawingXiTu(); // 调用saveDrawingXiTu方法保存画板状态
    },

    processInput(input) {
      // 去除回车键
      // let result = input.replace(/[\r\n]+/g, '');
      // 把中文逗号替换成英文逗号
      return input.replace(/，/g, ',');
    },
    handleBigSamplerChange() {
      if (this.form.input.parameters.sampler === 'k_euler') {
        this.subform.input.parameters.sampler = 'k_euler';
      }
      if (this.form.input.parameters.sampler === 'k_dpmpp_2s_ancestral') {
        this.subform.input.parameters.sampler = 'k_dpmpp_2s_ancestral';
      }
    },
    savePrivateSet() {
      let generateConfig = {
        steps: this.form.parameters.steps,
        guidance: this.guidance,
        sampler: this.form.parameters.sampler,
        promptRescale: this.promptRescale,
        undesiredStrength: this.undesiredStrength,
        useOfficialPositiveWords: this.useOfficialPositiveWords,
        useOfficialNegativeWords: this.useOfficialNegativeWords,
        customZhidong: this.customZhidong,
        size: this.size,
        zhidonDown: this.zhidonDown,
        isWaiGua: this.isWaiGua
      };

      axios.put('/api/user/saveGenerateConfig', generateConfig, {
        headers: {
          'authentication': this.$cookies.get('token')
        }
      })
          .then(response => {
            if (response.data.code === 200) {
              // 处理成功的逻辑
              this.$message({
                message: '保存成功',
                type: 'success'
              });
            } else {
              // 处理失败的逻辑，例如显示错误信息
              this.$message({
                message: '保存失败',
                type: 'error'
              });
            }
          })
          .catch(error => {
            // 处理错误信息
            this.buttonColor = '#F5F3C2';
            this.$message({
              message: '保存失败',
              type: 'error'
            });
          })
          .finally(() => {
          });
    },
    brushSet() {
      this.showbrushSet = true;
    },
    pushToUse() {
      this.form.input = this.nowImageExtra.prompt;
      this.form.parameters.negative_prompt = this.nowImageExtra.uc;
      this.showNowImageExtra = false;
      this.$message({
        message: '当前图tag成功推送到使用',
        type: 'success'
      });
    },
    extractMetadataFromCurrentImage() {
      // 将 base64 编码的图片数据转换为文本
      const base64Image = this.generImage.split(",")[1];
      const imageText = atob(base64Image);

      // 添加一个解码Unicode转义序列的辅助函数
      const decodeUnicode = (str) => {
        return str
            // 首先，将Unicode转义序列转换为相应的字符
            .replace(/\\u([\d\w]{4})/gi, (match, grp) => {
              return String.fromCharCode(parseInt(grp, 16));
            })
            // 然后，只移除转义双引号前的反斜杠
            .replace(/\\"/g, '"')
            // 最后，确保将转义的换行符 '\\n' 替换为实际的换行符 '\n'
            .replace(/\\n/g, '\n');
      };

      // 使用正则表达式提取元数据
      const promptMatch = imageText.match(/"prompt": "(.*?)"/);
      const seedMatch = imageText.match(/"seed": (.*?),/);
      const ucMatch = imageText.match(/"uc": "(.*?)"/);

      // 创建一个新的对象来存储元数据
      const nowImageExtra = {};

      if (promptMatch) {
        // Decode Unicode sequences and remove unnecessary suffix
        nowImageExtra.prompt = decodeUnicode(promptMatch[1])
            .replace(/, best quality, amazing quality, very aesthetic, absurdres$/, '');
      }

      if (seedMatch) {
        nowImageExtra.seed = seedMatch[1];
      }

      if (ucMatch && ucMatch[1]) {
        // Decode Unicode sequences and remove unnecessary prefix
        nowImageExtra.uc = decodeUnicode(ucMatch[1])
            .replace(/^nsfw, lowres, {bad}, error, fewer, extra, missing, worst quality, jpeg artifacts, bad quality, watermark, unfinished, displeasing, chromatic aberration, signature, extra digits, artistic error, username, scan, \[abstract\], /, '');
      } else {
        nowImageExtra.uc = '';
      }

      // 如果没有提取到元数据，显示一条消息
      if (!promptMatch && !seedMatch && (!ucMatch || !ucMatch[1])) {
        this.$message({
          message: '当前生成没有图,提取失败',
          type: 'error'
        });
      } else {
        // 将元数据存储在 Vue.js 数据对象中
        this.nowImageExtra = nowImageExtra;
      }
    },

    showImageExitra() {
      this.showNowImageExtra = true;
      this.extractMetadataFromCurrentImage();
    },
    saveAddvanceSet() {
      this.addvanceSetting = false;
    },
    advanceSet() {
      this.addvanceSetting = true;
    },
    getHuodongInfo() {
      // axios.get('/api/admin/config/isOpenActivity', {
      //   headers: {
      //     'authentication': this.$cookies.get('token')
      //   }
      // })
      //     .then(response => {
      //       this.huodong = response.data.data;
      //     })
      //     .catch(error => {
      //       // 处理错误信息
      //
      //       this.buttonColor = '#F5F3C2';
      //     })
      //     .finally(() => {});
    },
    handleRandomCheckChange() {
      let randomResult = this.getCustomRandom();
    },
    getCustomRandom() {
      let result = [];

      // 对数组进行排序，权重大的在前面
      this.customRodomParen.sort((a, b) => b.weight - a.weight);

      for (let item of this.customRodomParen) {
        // 如果状态为1（锁定），或没有子项，跳过
        if (item.status === '1' || !item.children || item.children.length === 0) continue;

        // 对于所有状态，我们只使用未锁定的子项
        let availableChildren = item.children.filter(child => child.status === '0');

        // 如果没有可用的子项，跳过
        if (availableChildren.length === 0) continue;

        if (item.categoryNumber > 0) {
          let selectedItems = [];

          // 状态3：按顺序轮询抽取
          if (item.status === '3') {
            item.lastIndex = item.lastIndex || 0;
            for (let i = 0; i < item.categoryNumber; i++) {
              selectedItems.push(availableChildren[item.lastIndex % availableChildren.length].value);
              item.lastIndex++;
            }
          }
          // 状态0和2：随机抽取
          else {
            let tempAvailableChildren = [...availableChildren]; // 创建一个副本以避免修改原数组
            if (item.categoryNumber >= tempAvailableChildren.length) {
              selectedItems = tempAvailableChildren.map(child => child.value);
            } else {
              for (let i = 0; i < item.categoryNumber; i++) {
                let randomIndex = Math.floor(Math.random() * tempAvailableChildren.length);
                selectedItems.push(tempAvailableChildren[randomIndex].value);
                tempAvailableChildren.splice(randomIndex, 1); // 避免重复抽取
              }
            }
          }

          // 状态2：进行随机"调权"
          if (item.status === '2') {
            selectedItems = selectedItems.map(item => {
              const randomStructure = Math.floor(Math.random() * 5);
              switch (randomStructure) {
                case 0: return `{${item}}`;
                case 1: return `[${item}]`;
                case 2: return `{{${item}}}`;
                case 3: return `[[${item}]]`;
                default: return item; // 不变
              }
            });
          }

          // 将选中的项目添加到结果中
          result.push(...selectedItems);
        }
      }

      // 将结果用逗号连接
      return result.join(', ');
    },






    handleCategoryRandomChanged(newCategoryRandom) {
      this.customRodomParen = newCategoryRandom;
    },
    handleBigSizeChange() {
      const sizeMap = {
        '长图一': { width: '3072', height: '1024' },
        '宽图一': { width: '1024', height: '3072' },
        '正方一': { width: '1728', height: '1728' },
        '长图二': { width: '4096', height: '768' },
        '宽图二': { width: '768', height: '4096' },
        '长图三': { width: '2048', height: '1536' },
        '宽图三': { width: '1536', height: '2048' },
        '宽图四': { width: '1088', height: '1920' },
        '长图四': { width: '1920', height: '1088' },
        '正方二': { width: '1472', height: '1472' },
        '长图1:2': { width: '2432', height: '1216' },
        '宽图1:2': { width: '1216', height: '2432' },
        '抱枕1:3': { width: '960', height: '2880' }
      };

      const size = sizeMap[this.size];
      if (size) {
        this.form.parameters.width = size.width;
        this.selectWidth = size.width;
        this.form.parameters.height = size.height;
        this.selectHeight = size.height;
      }
      // 在这里可以添加其他处理选择变化的逻辑
    },

    savePrefixAndSuffix() {
      this.prefixAndSuffix = false;
    },
    setprefixAndSuffix() {
      this.prefixAndSuffix = true;
    },
    saveOfficeRandomSet() {
      this.OfficeRandomTags = false;
    },
    RandomTagsinOffice() {
      let outOfficeRandom = "";
      let categoryMap = {
        "发型": this.hairRandom,
        "服装": this.clothingRandom,
        "身体": this.bodyRandom,
        "背景": this.backgroundRandom,
        "风格": this.styleRandom,
        "视角": this.viewRandom,
        "表情": this.expressionRandom,
        "五官": this.fiveRandom,
        "动作": this.actionRandom
      };
      for (let category in this.allOfficeRandom) {
        // Skip this category if its corresponding variable is false
        if (!categoryMap[category]) {
          continue;
        }
        let items = this.allOfficeRandom[category];
        if (!Array.isArray(items)) {
          continue;
        }
        if (items.length === 0) {
          continue;
        }
        let randomIndex = Math.floor(Math.random() * items.length);
        if (isNaN(randomIndex)) {
          continue;
        }
        let randomItem = items[randomIndex];
        if (randomItem === undefined) {
          continue;
        }
        outOfficeRandom += randomItem.value + ",";
      }
      // Remove the trailing comma
      // outOfficeRandom = outOfficeRandom.slice(0, -1);
      this.outOfficeRandom = outOfficeRandom;
    },
    handleCheckChange() {
      this.clearimg2img();
      if (this.bigPicture) {
        // 如果大图模式被选中，设置默认选项为'长图四'
        this.size = '长图四';
        this.handleBigSizeChange(); // 确保应用这个新的尺寸
      } else {
        // 如果大图模式未被选中，可以设置为常规尺寸的默认值
        this.size = '长图'; // 或者你希望的其他默认值
        this.handleSizeChange(); // 确保应用这个新的尺寸
      }
    },
    getOfficeRandom() {
      fetch('/officialRandom.json')
          .then(response => response.json())
          .then(data => {
            this.allOfficeRandom = data.data;
          })
          .catch(error => {
            console.error('Error loading officialRandom data:', error);
          });
    },

    setCustomRandom() {

    },
    setOfficeRandom() {
      this.OfficeRandomTags = true;
    },
    // enableDrawing 方法
    // 修改后的 enableDrawing 方法
    enableDrawing() {

      //删除缓存
      this.isCacheMask = false;

      if (!this.canvasInitialized) {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        const imageContainer = document.getElementById('imageContainer');

        // 设置 imageContainer 的相对定位，并设置为 flex 容器
        imageContainer.style.position = 'relative';
        imageContainer.style.display = 'flex';
        imageContainer.style.justifyContent = 'center';
        imageContainer.style.alignItems = 'center';

        const img = imageContainer.querySelector('img');

        // 确保 canvas 的尺寸与图片的显示尺寸一致
        this.canvas.width = this.cavenImageWidth;
        this.canvas.height = this.cavenImageHeight;

        // 调整 canvas 位置以覆盖图片
        this.canvas.style.position = 'absolute';
        this.canvas.style.left = '50%';  // 设置为 50%，然后使用 transform 进行偏移
        this.canvas.style.top = '50%';
        this.canvas.style.transform = 'translate(-50%, -50%)';

        imageContainer.appendChild(this.canvas);

        // 标记画布已经初始化
        this.canvasInitialized = true;
      }

      // 画笔事件绑定
      this.canvas.onmousedown = this.startDrawing.bind(this);
      this.canvas.onmousemove = this.draw.bind(this);
      this.canvas.onmouseup = () => {
        this.stopDrawing();
        this.saveDrawing();  // 在绘图结束后自动保存
      };

      // 触摸事件绑定
      this.canvas.ontouchstart = (e) => {
        e.preventDefault();
        this.startDrawing(e.touches[0]);
      };
      this.canvas.ontouchmove = (e) => {
        e.preventDefault();
        this.draw(e.touches[0]);
      };
      this.canvas.ontouchend = () => {
        this.stopDrawing();
        this.saveDrawing();  // 在绘图结束后自动保存
      };
    },

    startDrawing(e) {
      // 根据当前模式执行不同的逻辑
      if (this.mode === 'selection') {
        this.isDrawing = true;

        // 获取鼠标在画布上的位置
        const mouseX = e.clientX - this.canvas.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvas.getBoundingClientRect().top;

        // 假设原始矩形框尺寸为1216x832
        const originalRectWidth = this.bigInfillWidth;
        const originalRectHeight = this.bigInfillHeight;

        // 使用之前计算显示尺寸与原始尺寸比例的逻辑
        const scaleX = this.cavenImageWidth / this.uploadedImageNaturalWidth;
        const scaleY = this.cavenImageHeight / this.uploadedImageNaturalHeight;

        // 应用相同的缩放比例到矩形框尺寸
        const scaledRectWidth = originalRectWidth * scaleX;
        const scaledRectHeight = originalRectHeight * scaleY;

        // 计算矩形框的初始起始位置，以鼠标中心为准
        let startX = mouseX - scaledRectWidth / 2;
        let startY = mouseY - scaledRectHeight / 2;

        // 确保矩形框不超出画布边界
        startX = Math.max(startX, 0);
        startY = Math.max(startY, 0);

        if (startX + scaledRectWidth > this.canvas.width) {
          startX = this.canvas.width - scaledRectWidth;
        }
        if (startY + scaledRectHeight > this.canvas.height) {
          startY = this.canvas.height - scaledRectHeight;
        }

        // 重新计算框的中心点相对于整个画布的百分比
        const centerX = startX + scaledRectWidth / 2;
        const centerY = startY + scaledRectHeight / 2;
        const centerXPercent = (centerX / this.canvas.width) * 100;
        const centerYPercent = (centerY / this.canvas.height) * 100;

        // 存储到data中
        this.centerXPercent = centerXPercent;
        this.centerYPercent = centerYPercent;

        // console.log(this.centerXPercent);
        // console.log(this.centerYPercent);

        // 清除之前的画布内容
        // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.ctx.strokeStyle = 'rgba(0, 255, 255, 1)'; // 青色
        this.ctx.lineWidth = 2;

        // 绘制矩形
        this.ctx.beginPath();
        this.ctx.rect(startX, startY, scaledRectWidth, scaledRectHeight);
        this.ctx.stroke();

        this.isDrawing = false; // 重置绘制状态
      } else if (this.mode === 'drawing') {
        this.isDrawing = true;
        this.ctx.beginPath();
        this.ctx.moveTo(e.clientX - this.canvas.getBoundingClientRect().left, e.clientY - this.canvas.getBoundingClientRect().top);
        this.lastX = e.clientX;
        this.lastY = e.clientY;
      }
    },

    draw(e) {
      if (!this.isDrawing) return;

      if (this.mode === 'selection') {
        // 选择框模式下的绘图逻辑
        const mouseX = e.clientX - this.canvas.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvas.getBoundingClientRect().top;

        const originalRectWidth = this.bigInfillWidth;
        const originalRectHeight = this.bigInfillHeight;

        const scaleX = this.cavenImageWidth / this.uploadedImageNaturalWidth;
        const scaleY = this.cavenImageHeight / this.uploadedImageNaturalHeight;

        const scaledRectWidth = originalRectWidth * scaleX;
        const scaledRectHeight = originalRectHeight * scaleY;

        const startX = mouseX - scaledRectWidth / 2;
        const startY = mouseY - scaledRectHeight / 2;

        // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.ctx.strokeStyle = 'rgba(0, 255, 255, 1)'; // 青色
        this.ctx.lineWidth = 2;

        this.ctx.beginPath();
        this.ctx.rect(startX, startY, scaledRectWidth, scaledRectHeight);
        this.ctx.stroke();

      } else if (this.mode === 'drawing') {
        // 绘图模式下的绘图逻辑
        const mouseX = e.clientX - this.canvas.getBoundingClientRect().left;
        const mouseY = e.clientY - this.canvas.getBoundingClientRect().top;

        this.ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'; // 红色半透明
        this.ctx.lineWidth = this.brushSize;

        this.ctx.lineTo(mouseX, mouseY);
        this.ctx.stroke();

        this.ctx.fillStyle = 'rgba(255, 0, 0, 1)'; // 红色
        this.ctx.beginPath();
        this.ctx.arc(mouseX, mouseY, this.brushSize / 2, 0, Math.PI * 2, true);
        this.ctx.closePath();
        this.ctx.fill();

        this.lastX = mouseX;
        this.lastY = mouseY;

        // 同步到副画布
        if (this.canvasXiTuInitialized && this.ctxXiTu) {
          this.ctxXiTu.strokeStyle = 'rgba(255, 0, 0, 0.5)';
          this.ctxXiTu.lineWidth = this.brushSize;
          this.ctxXiTu.lineTo(mouseX * this.canvasXiTu.width / this.canvas.width, mouseY * this.canvasXiTu.height / this.canvas.height);
          this.ctxXiTu.stroke();

          this.ctxXiTu.fillStyle = 'rgba(255, 0, 0, 1)';
          this.ctxXiTu.beginPath();
          this.ctxXiTu.arc(mouseX * this.canvasXiTu.width / this.canvas.width, mouseY * this.canvasXiTu.height / this.canvas.height, this.brushSize / 2, 0, Math.PI * 2, true);
          this.ctxXiTu.closePath();
          this.ctxXiTu.fill();
        }
      }
    },

    stopDrawing() {
      this.isDrawing = false;
    },


    saveDrawing() {
      // 创建一个临时画布来处理图像
      let tempCanvas = document.createElement('canvas');
      let tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = this.i2iwidth;
      tempCanvas.height = this.i2iheight;

      // 使用 drawImage 方法进行缩放
      tempCtx.drawImage(
          this.canvas,
          0,
          0,
          this.canvas.width,
          this.canvas.height,
          0,
          0,
          tempCanvas.width,
          tempCanvas.height
      );

      // 转换为 Base64 格式的 PNG 图片
      this.mask = tempCanvas.toDataURL('image/png');

      // 备份原mask
      this.orangeMask = tempCanvas.toDataURL('image/png');

      // 清理临时画布
      tempCanvas = null;

      // 同步到副画布
      if (this.canvasXiTuInitialized && this.ctxXiTu) {
        const img = new Image();
        img.onload = () => {
          // 使用 drawImage 方法进行缩放
          const scaleFactor = Math.min(
              this.canvasXiTu.width / img.width,
              this.canvasXiTu.height / img.height
          );
          const scaledWidth = img.width * scaleFactor;
          const scaledHeight = img.height * scaleFactor;
          this.ctxXiTu.clearRect(0, 0, this.canvasXiTu.width, this.canvasXiTu.height);
          this.ctxXiTu.drawImage(
              img,
              0,
              0,
              img.width,
              img.height,
              (this.canvasXiTu.width - scaledWidth) / 2,
              (this.canvasXiTu.height - scaledHeight) / 2,
              scaledWidth,
              scaledHeight
          );
        };
        img.src = this.mask;
      }
    },

    resetDrawing() {
      //清理缓存Mask
      this.isCacheMask = false;

      // 清除主画布
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      // 清除副画布
      if (this.canvasXiTuInitialized && this.ctxXiTu) {
        this.ctxXiTu.clearRect(0, 0, this.canvasXiTu.width, this.canvasXiTu.height);
      }

      // 重置保存的涂抹区域
      this.mask = null;
      this.orangeMask = null;
    },
    saveOfficeTagSet() {
      this.tagsOffice = false;
    },
    officeInputSet() {
      this.tagsOffice = true;
    },
    updateForm(newForm) {
      this.form = newForm;
    },
    saveDiaryDialog() {
      this.tagsDiarytitle = '';
      this.tagsDiaryVisible = true;
    },
    clearAllpromot() {
      this.form.input = '';
      this.form.parameters.negative_prompt = '';
      this.$refs.deskDiary.clearSelectedWords();
    },
    clearInput() {
      this.form.input = '';
    },
    saveDiary() {
      this.tagsDiaryVisible = false;

      // 创建一个对象，包含输入的文本和其他参数
      const diaryData = {
        tags: this.form.input,
        untags: this.form.parameters.negative_prompt,
        name: this.tagsDiarytitle,
      }

      if (this.form.input === '') {
        this.$message.warning("正面描述词不能为空");
        return;
      }
      if (this.tagsDiarytitle === '') {
        this.$message.warning("标题不能为空");
        return;
      }

      // 使用请求体传递日记数据
      axios.post('/api/user/diaryTag/save', diaryData, {
        headers: {
          'authentication': this.$cookies.get('token')
        }
      })
          .then(response => {
            if (response.data.code === 200) {
              // 获取返回的id，并添加到 diaryData 中
              diaryData.id = response.data.data;

              this.$message({
                message: '保存成功',
                type: 'success',
              });
              this.diaroyDrawer = false;
              // 更新子组件的 filteredDiaryData
              this.$refs.deskDiary.updateFilteredDiaryData(diaryData);
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          })
          .finally(() => {
          });
    },
    closeDairy() {
      this.diaroyDrawer = false;
    },
    toimg2img() {
      if (this.generImage !== '') {
        this.form.parameters.image = this.generImage;
        // 获取图片容器
        const imageContainer = document.getElementById('imageContainer');

        this.actionType = 'img2img';

        // 创建一个新的Image对象
        let img = new Image();
        // 当图片被加载时，获取图片的宽度和高度
        img.onload = () => {
          this.i2iwidth = img.width;
          this.i2iheight = img.height;

          let newWidth = this.i2iwidth;
          let newHeight = this.i2iheight;

          // 如果开启了大图就，进行额外的放大，图生图
          if (this.bigPicture && this.actionType === 'img2img') {
            let scaleFactor = Math.sqrt(3145728 / (newHeight * newWidth));
            scaleFactor = Math.floor(scaleFactor * 2) / 2; // 将放大倍数取整到最近的0.5

            newWidth *= scaleFactor;
            newHeight *= scaleFactor;

            // 如果不是64的倍数，进行放大或缩小为64的倍数
            if (newWidth % 64 !== 0) {
              if (newWidth % 64 >= 32) {
                newWidth = Math.ceil(newWidth / 64) * 64;
              } else {
                newWidth = Math.floor(newWidth / 64) * 64;
              }
            }
            if (newHeight % 64 !== 0) {
              if (newHeight % 64 >= 32) {
                newHeight = Math.ceil(newHeight / 64) * 64;
              } else {
                newHeight = Math.floor(newHeight / 64) * 64;
              }
            }

            this.i2iwidth = newWidth;
            this.i2iheight = newHeight;
          }

          // 创建一个新的 canvas 元素
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // 设置 canvas 的宽度和高度
          canvas.width = this.i2iwidth;
          canvas.height = this.i2iheight;

          // 将图片绘制到 canvas 上
          ctx.drawImage(img, 0, 0, this.i2iwidth, this.i2iheight);

          // 将 canvas 转换为 base64 格式的图片
          const resizedImageSrc = canvas.toDataURL('image/png');

          // 将修改尺寸后的图以base64赋值给this.form.parameters.image
          this.form.parameters.image = resizedImageSrc;

          //备份原图
          this.orangeImage = resizedImageSrc;

          // 填充图片容器的内容
          if (imageContainer) {
            imageContainer.innerHTML = `<img src="${resizedImageSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;
          }

          // 延迟获取图片的显示尺寸
          setTimeout(() => {
            const displayedImg = imageContainer.querySelector('img');
            const displayedWidth = displayedImg.offsetWidth;
            const displayedHeight = displayedImg.offsetHeight;
            this.cavenImageWidth = displayedWidth;
            this.cavenImageHeight = displayedHeight;
          }, 0);
        };
        // 设置图片的src，开始加载图片
        img.src = this.form.parameters.image;
        this.isLiveImage = true;
      }

    },
    toinfill() {
      this.clearimg2img();
      if (this.generImage !== '') {
        this.form.parameters.image = this.generImage;
        this.orangeImage = this.generImage;
        this.actionType = 'infill';

        // 获取主画布容器和副画布容器
        const imageContainer = document.getElementById('imageContainer');
        const modalBody = document.querySelector('.modal-body');

        // 创建一个新的Image对象
        let img = new Image();
        // 当图片被加载时，获取图片的宽度和高度
        img.onload = () => {
          this.i2iwidth = img.width;
          this.i2iheight = img.height;

          const standardSizes = ["832x1216", "1216x832", "1024x1024", "1280x768", "768x1280", "768x1024", "1024x768", "1280x640", "640x1280"];
          const imageSize = `${this.i2iwidth}x${this.i2iheight}`;
          const isStandardSize = standardSizes.includes(imageSize);
          const isLargeSize = this.i2iwidth * this.i2iheight > 1024 * 1024;

          if (!isStandardSize && isLargeSize) {
            if (this.bigPicture || this.bigInfillModeEnabled) {
              // 如果开启了大图模式，保持原图尺寸
              this.i2iwidth = img.width;
              this.i2iheight = img.height;

              if (this.bigInfillModeEnabled) {
                // 需要将图像转换为Base64格式
                let canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                let imgBase64 = canvas.toDataURL('image/png');

                this.form.parameters.image = imgBase64;
                this.orangeImage = imgBase64; // 如果需要原图URL作为备份
                // 或者使用Base64作为备份，如果是这样，则 this.orangeImage = imgBase64;
                this.isLiveImage = true;
              }
            } else {
              // 如果没有开启大图模式，提示用户
              this.$message({
                message: '超出标准图重绘尺寸，请开启大图模式',
                type: 'warning'
              });
            }
          }

          // 填充主画布容器和副画布容器的内容
          if (imageContainer && modalBody) {
            const imgSrc = this.form.parameters.image;
            imageContainer.innerHTML = `<img src="${imgSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;
            modalBody.innerHTML = `<img src="${imgSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;
          }

          // 延迟获取图片的显示尺寸
          setTimeout(() => {
            const displayedImg = imageContainer.querySelector('img');
            const displayedWidth = displayedImg.offsetWidth;
            const displayedHeight = displayedImg.offsetHeight;
            this.cavenImageWidth = displayedWidth;
            this.cavenImageHeight = displayedHeight;
          }, 0);
        };
        // 设置图片的src，开始加载图片
        img.src = this.form.parameters.image;
        this.isLiveImage = true;
      }
    },

    clearimg2img() {

      //删除缓存
      this.isCacheMask = false;

      // 清空存储图片的变量
      this.form.parameters.image = '';
      this.form.parameters.mask = '';
      this.mask = '';
      this.mask2 = '';
      this.isLiveImage = false;
      this.form.action = 'generate';
      this.actionType = 'generate';

      // 重置绘图相关的状态
      this.canvasInitialized = false;
      this.canvasXiTuInitialized = false; // 重置副画布初始化状态
      this.isDrawing = false; // 重置绘图状态
      this.isDrawingXiTu = false; // 重置副画布绘图状态
      this.lastX = null; // 重置最后一次绘图的坐标
      this.lastY = null;

      // 处理主画布
      if (this.canvas) {
        // 如果之前有创建过画布,则从 DOM 中移除
        const imageContainer = document.getElementById('imageContainer');
        if (imageContainer && this.canvas.parentNode === imageContainer) {
          imageContainer.removeChild(this.canvas);
        }
        this.canvas = null;
        this.ctx = null;
      }

      // 处理副画布
      if (this.canvasXiTu) {
        // 如果之前有创建过副画布,则从 DOM 中移除
        const modalBody = document.querySelector('.modal-body');
        if (modalBody && this.canvasXiTu.parentNode === modalBody) {
          modalBody.removeChild(this.canvasXiTu);
        }
        // 不要将 this.canvasXiTu 设置为 null
      }

      // 重置文件输入元素的状态
      document.getElementById('uploadBtn').value = '';
      document.getElementById('uploadBtnJu').value = '';

      // 获取图片容器
      const imageContainer = document.getElementById('imageContainer');

      // 清空图片容器的内容
      if (imageContainer) {
        imageContainer.innerHTML = '';
      }

      // 清除画布上的绘图（如果已经存在画布和上下文）
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
      if (this.ctxXiTu) {
        this.ctxXiTu.clearRect(0, 0, this.canvasXiTu.width, this.canvasXiTu.height);
      }

      // 重置上一次绘图
      this.lastDrawing = null;
    },


    useKamil() {
      if (this.buttonkamilDisabled) {
        return;
      }
      if (this.buttonDisabled) {
        this.$message({
          message: '请停止跑图后再使用卡密!',
          type: 'warning',
        });
        return; // If the button is disabled, do not execute further code
      }
      this.buttonkamilDisabled = true;

      // 使用查询参数传递卡密数据
      const params = new URLSearchParams();
      params.append('value', this.kamil);

      axios.put(`/api/user/useKamil?value=${encodeURIComponent(this.kamil)}`, null, {
        headers: {
          'authentication': this.$cookies.get('token'),
        }
      })
          .then(response => {
            if (response.data.code === 200) {
              this.$message({
                message: '卡密使用成功!',
                type: 'success',
              });
              this.showShopping = false;
              this.personalCenterFormVisible = false;
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          })
          .finally(() => {
            this.buttonkamilDisabled = false;
            this.getUserInfo();
          });
    },

    handleUploadVibe(event) {
      const file = event.target.files[0];

      // 检查文件是否存在,并且是图片格式
      if (file) {
        // 如果不是 jpg 或 png 格式,显示错误消息
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
          this.$message.error('上传的图片格式错误');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageSrc = e.target.result;

          // 如果 vibe448 为 true,则压缩图片
          if (this.vibe448) {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              let width = img.width;
              let height = img.height;

              if (width > height) {
                if (width > 448) {
                  height *= 448 / width;
                  width = 448;
                }
              } else {
                if (height > 448) {
                  width *= 448 / height;
                  height = 448;
                }
              }

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
              const compressedSrc = canvas.toDataURL('image/jpeg', 0.7);
              this.handleImageUpload(compressedSrc);
            };
          } else {
            this.handleImageUpload(imageSrc);
          }
        };

        reader.readAsDataURL(file);
      } else {
        // 如果文件不存在或者不是图片格式,显示错误消息
        this.$message.error('上传的图片格式错误');
      }
    },

    handleImageUpload(imageSrc) {
      // 将 base64 格式的图片添加到 uploadedImages 数组中
      this.uploadedImages.push(imageSrc);

      // 将 base64 格式的图片添加到 reference_image_multiple 数组中,去掉前缀
      this.form.parameters.reference_image_multiple.push(imageSrc.replace(new RegExp('^data:image\\/(png|jpeg);base64,'), ''));

      // 将默认的 reference_information_extracted 和 reference_strength 值添加到对应的数组中
      this.form.parameters.reference_information_extracted_multiple.push(this.defaultInformationExtracted);
      this.form.parameters.reference_strength_multiple.push(this.defaultReferenceStrength);

      // 创建一个新的 div 容器来显示所有上传的图片
      const allImagesContainer = document.getElementById('allImagesContainer');
      const newImgContainer = document.createElement('div');
      newImgContainer.style.display = 'inline-block';
      newImgContainer.style.margin = '10px';
      newImgContainer.style.width = '150px'; // 设置容器宽度
      newImgContainer.style.height = '150px'; // 设置容器高度
      newImgContainer.style.overflow = 'hidden'; // 裁剪溢出部分

      const newImg = document.createElement('img');
      newImg.src = imageSrc;
      newImg.style.maxWidth = '100%';
      newImg.style.maxHeight = '100%';
      newImg.style.width = 'auto';
      newImg.style.height = 'auto';
      newImg.style.display = 'block';
      newImg.style.objectFit = 'contain';

      newImgContainer.appendChild(newImg);
      allImagesContainer.appendChild(newImgContainer);

      // 在处理完图片之后,重置 input 元素以确保再次上传同一图片时 onchange 事件能够触发
      event.target.value = '';
    },

    removeImage(index) {
      // 从数组中移除对应的图片和相关数据
      this.uploadedImages.splice(index, 1);
      this.form.parameters.reference_image_multiple.splice(index, 1);
      this.form.parameters.reference_information_extracted_multiple.splice(index, 1);
      this.form.parameters.reference_strength_multiple.splice(index, 1);

      // 移除已显示的图片
      const allImagesContainer = document.getElementById('allImagesContainer');
      allImagesContainer.removeChild(allImagesContainer.children[index]);
    },
    clearAllImages() {
      // 清空所有数组
      this.uploadedImages = [];
      this.form.parameters.reference_image_multiple = [];
      this.form.parameters.reference_information_extracted_multiple = [];
      this.form.parameters.reference_strength_multiple = [];

      // 清空容器中的所有图片
      const allImagesContainer = document.getElementById('allImagesContainer');
      allImagesContainer.innerHTML = '';
    },
    formatTooltipReference(val) {
      this.form.parameters.reference_information_extracted_multiple[this.form.parameters.reference_information_extracted_multiple.length - 1] = val / 100;
      return val / 100;
    },
    formatTooltipReferenceStrength(val) {
      this.form.parameters.reference_strength_multiple[this.form.parameters.reference_strength_multiple.length - 1] = val / 100;
      return val / 100;
    },




    handleUpload(event) {
      const file = event.target.files[0];

      // 根据上传按钮的 ID 设置动作类型
      if (event.target.id === 'uploadBtn') {
        this.actionType = 'img2img';
        // this.form.parameters.add_original_image = true;
      } else if (event.target.id === 'uploadBtnJu') {
        this.actionType = 'infill';
        // this.form.parameters.add_original_image = true;
      }else if (event.target.id === 'uploadBtnKuo') {
        this.actionType = 'infill';
        // this.form.parameters.add_original_image = true;
      }

      // 检查文件是否存在，并且是图片格式
      if (file) {
        // 如果不是 jpg 或 png 格式，显示错误消息
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
          this.$message.error('上传的图片格式错误'); // 使用 Element UI 的消息组件显示错误
          return; // 终止函数执行
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          const imageSrc = e.target.result;
          this.form.parameters.image = imageSrc;

          // 创建一个新的 Image 对象
          const img = new Image();

          // 设置图片的 src 属性为读取到的结果
          img.src = imageSrc;

          this.isLiveImage = true;

          // console.log("emmmm");

          // 为 Image 对象添加 onload 事件处理函数
          img.onload = () => {

            this.uploadedImageNaturalWidth = img.naturalWidth;
            this.uploadedImageNaturalHeight = img.naturalHeight;

            let newWidth, newHeight;


            // 判断图片尺寸是否符合要求
            if (
                (img.naturalWidth === 832 && img.naturalHeight === 1216) ||
                (img.naturalWidth === 1216 && img.naturalHeight === 832) ||
                (img.naturalWidth === 1024 && img.naturalHeight === 1024) ||
                (img.naturalWidth === 1280 && img.naturalHeight === 768) ||
                (img.naturalWidth === 768 && img.naturalHeight === 1280)
            ) {
              newWidth = img.naturalWidth;
              newHeight = img.naturalHeight;
            } else {
              // 计算按比例缩小后的尺寸
              const aspectRatio = img.naturalWidth / img.naturalHeight;

              if (img.naturalWidth > img.naturalHeight) {
                newWidth = 1024;
                newHeight = Math.floor(1024 / aspectRatio);
              } else {
                newHeight = 1024;
                newWidth = Math.floor(1024 * aspectRatio);
              }


              // 如果不是64的倍数，进行放大或缩小为64的倍数
              if (newWidth % 64 !== 0) {
                if (newWidth % 64 >= 32) {
                  newWidth = Math.ceil(newWidth / 64) * 64;
                } else {
                  newWidth = Math.floor(newWidth / 64) * 64;
                }
              }
              if (newHeight % 64 !== 0) {
                if (newHeight % 64 >= 32) {
                  newHeight = Math.ceil(newHeight / 64) * 64;
                } else {
                  newHeight = Math.floor(newHeight / 64) * 64;
                }
              }
            }


            // 如果开启了大图就，进行额外的放大，图生图
            if (this.bigPicture && this.actionType === 'img2img') {
              let scaleFactor = Math.sqrt(3145728 / (newHeight * newWidth));
              scaleFactor = Math.floor(scaleFactor * 2) / 2; // 将放大倍数取整到最近的0.5

              newWidth *= scaleFactor;
              newHeight *= scaleFactor;

              // 如果不是64的倍数，进行放大或缩小为64的倍数
              if (newWidth % 64 !== 0) {
                if (newWidth % 64 >= 32) {
                  newWidth = Math.ceil(newWidth / 64) * 64;
                } else {
                  newWidth = Math.floor(newWidth / 64) * 64;
                }
              }
              if (newHeight % 64 !== 0) {
                if (newHeight % 64 >= 32) {
                  newHeight = Math.ceil(newHeight / 64) * 64;
                } else {
                  newHeight = Math.floor(newHeight / 64) * 64;
                }
              }
            }

            // 如果开启了大图就，限制放松到大图，局部重绘
            if (this.bigPicture && this.actionType === 'infill') {

              newWidth = img.naturalWidth;
              newHeight = img.naturalHeight;

              if (newWidth * newHeight > 3145728) {
                let scaleFactor = Math.sqrt(3145728 / (newHeight * newWidth));
                scaleFactor = Math.floor(scaleFactor * 2) / 2; // 将放大倍数取整到最近的0.5

                newWidth *= scaleFactor;
                newHeight *= scaleFactor;
              }

              // 如果不是64的倍数，进行放大或缩小为64的倍数
              if (newWidth % 64 !== 0) {
                if (newWidth % 64 >= 32) {
                  newWidth = Math.ceil(newWidth / 64) * 64;
                } else {
                  newWidth = Math.floor(newWidth / 64) * 64;
                }
              }
              if (newHeight % 64 !== 0) {
                if (newHeight % 64 >= 32) {
                  newHeight = Math.ceil(newHeight / 64) * 64;
                } else {
                  newHeight = Math.floor(newHeight / 64) * 64;
                }
              }
            }

            //如果开启了大图截取局部重绘
            if (this.bigInfillModeEnabled) {
              newWidth = img.naturalWidth;
              newHeight = img.naturalHeight;
            }

            // 创建一个新的 canvas 元素
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // 设置 canvas 的宽度和高度
            canvas.width = newWidth;
            canvas.height = newHeight;

            // 将图片绘制到 canvas 上
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将 canvas 转换为 base64 格式的图片
            const resizedImageSrc = canvas.toDataURL(file.type);

            // 将新的宽度和高度赋值给 this.i2iwidth 和 this.i2iheight
            this.i2iwidth = newWidth;
            this.i2iheight = newHeight;

            // 将修改尺寸后的图以base64赋值给this.form.parameters.image
            this.form.parameters.image = resizedImageSrc;

            //备份原图
            this.orangeImage = resizedImageSrc;

            // 将图片显示在 imageContainer 中
            const imageContainer = document.getElementById('imageContainer');
            imageContainer.innerHTML = `<img src="${resizedImageSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;

            // 同时，将处理后的图片显示在对话框中
            const modalBody = document.querySelector('.modal-body');
            modalBody.innerHTML = `<img src="${resizedImageSrc}" style="max-width: 100%; max-height: 100%; width: auto; height: auto; display: block; object-fit: contain;" />`;


            // 延迟获取图片的显示尺寸
            setTimeout(() => {
              const displayedImg = imageContainer.querySelector('img');
              const displayedWidth = displayedImg.offsetWidth;
              const displayedHeight = displayedImg.offsetHeight;

              this.cavenImageWidth = displayedWidth;
              this.cavenImageHeight = displayedHeight;
            }, 0);
          };
        };

        reader.readAsDataURL(file);
      } else {
        // 如果文件不存在或者不是图片格式，显示错误消息
        this.$message.error('上传的图片格式错误');
      }
    },
    openPersonalCenterForm() {
      this.personalCenterFormVisible = true;
    },
    openChangePasswordForm() {
      this.changePasswordFormVisible = true;
    },
    validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    changePassword() {
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          if (this.buttonDisabled) {
            return;
          }
          this.buttonDisabled = true;

          // 创建一个对象，包含旧密码和新密码
          const passwordData = {
            oldPassword: this.passwordForm.oldPassword,
            newPassword: this.passwordForm.newPassword
          };

          axios.put('/api/user/updatePassword', passwordData, { // 使用请求体传递密码数据
            headers: {
              'authentication': this.$cookies.get('token')
            }
          })
              .then(response => {
                if (response.data.code === 200) {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                  this.changePasswordFormVisible = false;
                } else {
                  this.$message.error(response.data.msg);
                }
              })
              .catch(error => {
                if (error.response) {
                  this.$message.error(error.response.data.msg);
                } else {
                  this.$message.error(error.message);
                }
              })
              .finally(() => {
                this.buttonDisabled = false;
              });
        } else {
          this.$message({
            message: '表单验证未通过',
            type: 'warning',
          });
          return false;
        }
      });
    },
    inputPromt() {
      this.form.input = this.form.input + ',' + this.outputText;
    },
    async translateText(from, to) {
      if (!this.inputText) {
        return;
      }

      try {
        const response = await axios.get('/api/user/baiduTranslate', {
          params: {
            from: from,
            to: to,
            inputText: this.inputText
          },
          headers: {
            'authentication': this.$cookies.get('token')
          }
        });

        // 解析 JSON 字符串
        const responseData = JSON.parse(response.data.data);

        // 确保 responseData 和 responseData.trans_result 存在，并且 trans_result 是一个非空数组
        if (responseData && responseData.trans_result && responseData.trans_result.length > 0) {
          // 访问 trans_result 数组的第一个元素的 dst 属性
          // console.log(responseData.trans_result[0].dst);
          this.outputText = responseData.trans_result[0].dst;
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    },

    clearText() {
      this.inputText = '';
      this.outputText = '';
    },
    downloadAllImages() {
      // 检查generImages数组是否为空
      if (this.generImages.length === 0) {
        this.$message.error('没有生成任何图片，无法一键打包下载');
        return;
      }
      this.$message.success('开始打包下载!');
      let zip = new JSZip();
      for (let i = 0; i < this.generImages.length; i++) {
        let img = this.generImages[i].src.replace(/^data:image\/(png|jpg);base64,/, "");
        zip.file(this.generImages[i].name + ".png", img, {base64: true});
      }
      zip.generateAsync({type: "blob"}).then((content) => {
        let zipName = this.formatDate(new Date()); // 使用当前时间作为ZIP文件的名称
        saveAs(content, zipName + "nai3.art-images.zip");
      });
      this.$message.success('打包下载完成!');
    },
    selectImage(index) {
      this.selectedIndex = index;
      this.generImage = this.generImages[index].src;
      this.imageName = this.generImages[index].name;
    },
    handleMouseDown(index, event) {
      if (event.button === 0) { // 只响应鼠标左键
        this.longPressTimer = setTimeout(() => {
          this.longPressIndex = index;
          this.showDeleteConfirm();
        }, 500); // 长按0.5秒触发删除确认
      }
    },
    handleMouseUp() {
      clearTimeout(this.longPressTimer);
      this.longPressTimer = null;
    },
    handleMouseLeave() {
      clearTimeout(this.longPressTimer);
      this.longPressTimer = null;
    },
    showDeleteConfirm() {
      this.$confirm('确定要删除这张图片吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteImage(this.longPressIndex);
      }).catch(() => {
        this.longPressIndex = -1;
      });
    },
    deleteImage(index) {
      // 从 generImages 中删除图片
      this.generImages.splice(index, 1);

      // 从 visibleImages 中删除图片
      const indexInVisible = this.visibleImages.findIndex(image => image === this.generImages[index]);
      if (indexInVisible !== -1) {
        this.visibleImages.splice(indexInVisible, 1);
      }

      // 重置加载状态
      this.resetLoadState();
    },
    extractMetadata(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      // 添加一个解码Unicode转义序列的辅助函数
      const decodeUnicode = (str) => {
        return str
            // 首先，将Unicode转义序列转换为相应的字符
            .replace(/\\u([\d\w]{4})/gi, (match, grp) => {
              return String.fromCharCode(parseInt(grp, 16));
            })
            // 然后，只移除转义双引号前的反斜杠
            .replace(/\\"/g, '"')
            // 最后，确保将转义的换行符 '\\n' 替换为实际的换行符 '\n'
            .replace(/\\n/g, '\n');
      };

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const promptMatch = contents.match(/"prompt": "(.*?)"/);
        const seedMatch = contents.match(/"seed": (.*?),/);
        const ucMatch = contents.match(/"uc": "(.*?)"/);

        if (promptMatch) {
          // Decode Unicode sequences
          let decodedPrompt = decodeUnicode(promptMatch[1]);
          // 去除input中不需要的后缀
          decodedPrompt = decodedPrompt.replace(/, best quality, amazing quality, very aesthetic, absurdres$/, '');
          this.form.input = decodedPrompt;
        }

        if (seedMatch && this.isSeed) {
          this.form.parameters.seed = seedMatch[1];
        }

        if (ucMatch && ucMatch[1]) {
          // Decode Unicode sequences
          let decodedUc = decodeUnicode(ucMatch[1]);
          // 去除uc中不需要的前缀
          decodedUc = decodedUc.replace(/^nsfw, lowres, {bad}, error, fewer, extra, missing, worst quality, jpeg artifacts, bad quality, watermark, unfinished, displeasing, chromatic aberration, signature, extra digits, artistic error, username, scan, \[abstract\], /, '');
          this.form.parameters.negative_prompt = decodedUc;
        } else {
          this.form.parameters.negative_prompt = '';
        }

        if (!promptMatch && !seedMatch && (!ucMatch || !ucMatch[1])) {
          this.$message({
            message: '该图片不是Nai的ai原图，没有元数据，提取失败',
            type: 'error'
          });
        }
      };

      reader.readAsText(file);
      event.target.value = null; // Clear the input value after reading
    },


    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async startGenerating() {

      console.log("ee")
      if (this.bigPicture && this.isLiangXuChecked) {
        this.$message({
          message: '自动模式不支持跑大图',
          type: 'warning'
        });
        this.isLiangXuChecked = false;
        this.stopGenerating();
        return;
      }
      if (this.form.action === 'infill'&&!this.isKuoTu) {
        this.saveDrawing();
        this.form.parameters.mask = this.mask;
      }
      if ((this.form.action === 'infill' && this.form.parameters.mask === '')&&(!this.isKuoTu)) {
        this.$message({
          message: '使用局部重回功能请先涂抹',
          type: 'warning'
        });
        this.isLiangXuChecked = false;
        this.stopGenerating();
        return;
      }
      // 创建并启动 Web Worker
      if (!this.worker) {
        this.worker = new Worker('/worker/worker.js');
        this.worker.onmessage = this.handleWorkerMessage;
      }
      this.worker.postMessage({ action: 'start' });

    },

    handleWorkerMessage(event) {
      if (event.data.type === 'generate') {
        this.generateImage();
      }
    },
    // handleWorkerMessage(event) {
    //   if (event.data.type === 'generate') {
    //     setTimeout(() => {
    //       this.generateImage();
    //     }, 3000);  // 3000毫秒 = 3秒
    //   }
    // },
    async generateImage() {
      if ((this.zhidongSize > 0&&(!this.isqueueModeEnabled))
          ||((this.isqueueModeEnabled)&&((this.nonEmptyQueueLength>=this.isnonEmptyQueueLength)))) {
        if (this.point.totalPoints === 0 && this.huodong !== '1') {
          this.$message({
            message: '点数不足',
            type: 'warning'
          });
          this.showShopping = true;
          this.isLiangXuChecked = false;
          this.stopGenerating();
          return;
        }
        if (this.form.input === '' && !this.officeRandom) {
          this.$message({
            message: '请输入tag',
            type: 'warning'
          });
          this.isLiangXuChecked = false;
          this.stopGenerating();
          return;
        }

        if (this.buttonDisabled) {
          return; // If the button is disabled, do not execute further code
        }

        this.RandomTagsinOffice();

        //开启随机 普通 画师 自定义随机
        let inputParts = [];

        if (this.officeRandom) {
          inputParts.push(this.outOfficeRandom);
        }

        if (this.customRandom) {
          inputParts.push(this.getCustomRandom());
        }

        if (this.officeRandomArtist) {
          inputParts.push(this.getArtistsString());
        }

        if (this.officeRandom || this.customRandom || this.officeRandomArtist) {
          this.form.input = this.RandomPrefix + inputParts.join('') + this.RandomSuffix;
        }

        // 开始轮询模式
        if (this.isLunXun && this.tags.length > 0) {
          const index = this.customZhidong - this.zhidongSize;
          if (index >= 0 && index < this.tags.length) {
            if(index === 0) {
              this.chacheInput = this.form.input;
            }
            this.form.input = this.chacheInput;

            this.form.input = this.form.input.replace('@@', this.tags[index]);
          } else {
            console.warn('索引超出了tags数组的范围');
          }
        }

        //是否使用官方正负面
        this.naiOfficeTag = this.naiOfficeTag || []; // 确保naiOfficeTag是一个数组

        if (this.useOfficialPositiveWords === true) {
          if (!this.naiOfficeTag.includes(1)) { // 如果数组中没有数字1
            this.naiOfficeTag.push(1); // 向数组添加数字1
          }
        } else {
          let index = this.naiOfficeTag.indexOf(1); // 查找数字1在数组中的位置
          if (index !== -1) { // 如果找到了数字1
            this.naiOfficeTag.splice(index, 1); // 从数组中删除数字1
          }
        }

        if (this.useOfficialNegativeWords === true) {
          if (!this.naiOfficeTag.includes(2)) { // 如果数组中没有数字1
            this.naiOfficeTag.push(2); // 向数组添加数字1
          }
        } else {
          let index = this.naiOfficeTag.indexOf(2); // 查找数字1在数组中的位置
          if (index !== -1) { // 如果找到了数字1
            this.naiOfficeTag.splice(index, 2); // 从数组中删除数字1
          }
        }
        this.buttonDisabled = true; // Disable the button

        this.buttonColor = '#ffffffb3';
        this.imageLoading = true;

        if (this.form.parameters.image !== '' && this.form.parameters.image !== null) {
          if (this.i2iheight < 64) {
            this.i2iheight = 64;
          }
          if (this.i2iwidth < 64) {
            this.i2iwidth = 64;
          }

          if (this.actionType === 'img2img') {
            this.form.parameters.width = this.i2iwidth;
            this.form.parameters.height = this.i2iheight;
            this.form.action = 'img2img';   //jpeg
            this.form.model = 'nai-diffusion-3';
            this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.cfg_rescale = this.usepromptRescale;
            this.form.parameters.uncond_scale = this.undesiredStrength / 100;
            this.subform.parameters.reference_image = this.form.parameters.reference_image;
            this.subform.parameters.reference_information_extracted = this.form.parameters.reference_information_extracted;
            this.subform.parameters.reference_strength = this.form.parameters.reference_strength;
            this.subform.parameters.add_original_image = this.form.parameters.add_original_image;
            this.subform.parameters.dynamic_thresholding = this.form.parameters.dynamic_thresholding;

            //enhance功能
            if(this.isEnhance&&(!this.bigPicture)){
              let tempWidth = Math.round(this.form.parameters.width * 1.5 / 64) * 64;
              let tempHeight = Math.round(this.form.parameters.height * 1.5 / 64) * 64;

              this.form.parameters.width = tempWidth;
              this.form.parameters.height = tempHeight;
            }

            // 删除 this.form 的 mask 属性
            delete this.form.mask;
          } else if (this.actionType === 'infill') {
            this.form.parameters.width = this.i2iwidth;
            this.form.parameters.height = this.i2iheight;
            this.form.action = 'infill';
            this.form.model = 'nai-diffusion-3-inpainting';
            this.form.isUseBigInfill = this.bigInfillModeEnabled;

            if(this.isKuoTu){
              // console.log("kuotu");
              const originalBase64 = this.orangeImage;
              const shrunkImageData = await this.shrinkImageAndEmbedInCanvas(originalBase64);
              const blackWhiteImageData = await this.generateBlackWhiteImageWithBlackRect(originalBase64);
              // console.log(shrunkImageData);
              // console.log(blackWhiteImageData);
              this.form.parameters.image = shrunkImageData;
              this.form.parameters.mask = blackWhiteImageData;
            }else{

              this.form.parameters.image = this.orangeImage;
              this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
              if (this.mask2 === '') {
                this.form.parameters.mask = this.mask.replace(/^data:image\/(png|jpeg);base64,/, '');
              } else {
                this.form.parameters.mask = this.mask2.replace(/^data:image\/(png|jpeg);base64,/, '');
              }
              if (this.bigInfillModeEnabled) {
                await this.cropImage(this.orangeImage, this.centerXPercent, this.centerYPercent, this.bigInfillWidth, this.bigInfillHeight).then(async croppedImage => {
                  // 处理截取后的图像
                  this.form.parameters.image = croppedImage;
                  // console.log(croppedImage);

                  //640x640 时进行拉伸放大截取图
                  if (this.bigInfillWidth === 640 && this.bigInfillHeight === 640 && this.isLaSheng) {
                    if(!this.isCacheMask){
                      const croppedImage1024 = await this.convertImageTo1024Size(this.form.parameters.image)
                      this.form.parameters.image = croppedImage1024;
                      this.cacheMaske = croppedImage1024;
                      this.isCacheMask = true;
                    }else{
                      this.form.parameters.mask = this.cacheMaske;
                    }

                  }

                }).catch(error => {
                  // 处理错误
                  if (error.code === 'ECONNABORTED') {
                    // 请求超时
                    this.$message.error('请求超时,请检查网络连接');
                  }
                  console.error(error);
                });
                await this.cropImage(this.orangeMask, this.centerXPercent, this.centerYPercent, this.bigInfillWidth, this.bigInfillHeight).then(async croppedImage => {
                  // 处理截取后的图像
                  // console.log(croppedImage);
                  const convertedBase64 = await this.convertImageDataAsync(croppedImage, this.reverseBrush);
                  this.form.parameters.mask = convertedBase64;
                  // console.log(this.form.parameters.mask);

                  //640x640 时进行拉伸放大mask图
                  if(this.bigInfillWidth===640 && this.bigInfillHeight===640 && this.isLaSheng) {
                    const convertedBase641024 = await this.convertImageTo1024Size(convertedBase64)

                    this.form.parameters.mask = convertedBase641024;
                  }

                }).catch(error => {
                  // 处理错误
                  console.error(error);
                });

                this.form.parameters.width = this.bigInfillWidth;
                this.form.parameters.height = this.bigInfillHeight;

                if(this.bigInfillWidth===640 && this.bigInfillHeight===640 && this.isLaSheng) {
                  this.form.parameters.width = 1024;
                  this.form.parameters.height = 1024;
                }

                // console.log("----1")
              } else {
                // console.log("----2")
                // console.log(this.mask);
                if(!this.isCacheMask){
                  const convertedBase64 = await this.convertImageDataAsync(this.mask, this.reverseBrush);
                  // console.log(convertedBase64);
                  this.form.parameters.mask = convertedBase64.replace(/^data:image\/(png|jpeg);base64,/, '');
                  this.cacheMaske = convertedBase64;
                  this.isCacheMask = true;
                }else{
                  this.form.parameters.mask = this.cacheMaske;
                }
              }
            }

            this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
            this.form.parameters.mask = this.form.parameters.mask.replace(/^data:image\/(png|jpeg);base64,/, '');


            // console.log("---3")
            this.form.parameters.strength = this.Strength / 100;
            this.form.parameters.cfg_rescale = this.usepromptRescale;
            this.form.parameters.uncond_scale = this.undesiredStrength / 100;

          }
        }

        if (this.actionType === 'generate') {
          this.subform.input = this.form.input;
          this.subform.action = 'generate';
          this.subform.model = 'nai-diffusion-3';
          this.subform.parameters.steps = this.form.parameters.steps;
          this.subform.parameters.scale = this.form.parameters.scale;
          this.subform.parameters.seed = this.form.parameters.seed;
          this.subform.parameters.width = this.selectWidth;
          this.subform.parameters.height = this.selectHeight;
          this.subform.parameters.sm = this.form.parameters.sm;
          this.subform.parameters.sm_dyn = this.form.parameters.sm_dyn;
          this.subform.parameters.negative_prompt = this.form.parameters.negative_prompt;
          this.subform.parameters.cfg_rescale = this.usepromptRescale; // 修改为前端的值
          this.subform.parameters.uncond_scale = this.undesiredStrength / 100;
          this.subform.parameters.sampler = this.form.parameters.sampler;

          this.subform.parameters.add_original_image = this.form.parameters.add_original_image;
          this.subform.parameters.dynamic_thresholding = this.form.parameters.dynamic_thresholding;
          this.subform.parameters.n_samples = this.form.parameters.n_samples;
          this.subform.parameters.strength = this.form.parameters.strength;
          this.subform.parameters.noise = this.form.parameters.noise;
          this.subform.parameters.ucPreset = this.form.parameters.ucPreset;
          this.subform.parameters.qualityToggle = this.form.parameters.qualityToggle;
          this.subform.parameters.dynamic_thresholding = this.form.parameters.dynamic_thresholding;
          this.subform.parameters.controlnet_strength = this.form.parameters.controlnet_strength;
          this.subform.parameters.legacy = this.form.parameters.legacy;
          this.subform.parameters.noise_schedule = this.form.parameters.noise_schedule;
          this.subform.parameters.legacy_v3_extend = this.form.parameters.legacy_v3_extend;
          this.subform.parameters.params_version = this.form.parameters.params_version;

          this.subform.parameters.reference_image_multiple = this.form.parameters.reference_image_multiple;
          this.subform.parameters.reference_information_extracted_multiple = this.form.parameters.reference_information_extracted_multiple;
          this.subform.parameters.reference_strength_multiple = this.form.parameters.reference_strength_multiple;

          this.subform.parameters.skip_cfg_above_sigma = this.form.parameters.skip_cfg_above_sigma;

          this.form = {};
          this.form = this.subform;

          // ===
          if (this.form.parameters.reference_image === '') {
            delete this.form.parameters.reference_image;
            this.form.parameters.add_original_image = false;
          }
        }


        //ddim采样不支持 smea
        if (this.form.parameters.sampler === 'ddim') {
          this.form.parameters.sm = false;
          this.form.parameters.sm_dyn = false;
        }


        this.form.naiOfficeTag = this.naiOfficeTag;

        //队列判定
        if (this.isqueueModeEnabled) {
          if (this.queueItems.length > 0) {
            const currentIndex = this.isnonEmptyQueueLength - 1;
            const firstQueueItem = this.queueItems[currentIndex].trim();
            if (firstQueueItem !== '') {
              this.form.input = `${this.RandomPrefix}${firstQueueItem}${this.RandomSuffix}`;

              // 获取当前队列项的宽高级别
              const widthHeightLevel = this.queueWidthHeight[currentIndex];

              // 根据级别设置宽度和高度
              switch(widthHeightLevel) {
                case '1':
                  this.form.parameters.width = 832;
                  this.form.parameters.height = 1216;
                  break;
                case '2':
                  this.form.parameters.width = 1216;
                  this.form.parameters.height = 832;
                  break;
                case '3':
                  this.form.parameters.width = 1024;
                  this.form.parameters.height = 1024;
                  break;
                case '4':
                  this.form.parameters.width = 1280;
                  this.form.parameters.height = 768;
                  break;
                case '5':
                  this.form.parameters.width = 768;
                  this.form.parameters.height = 1280;
                  break;
                case '6':
                  this.form.parameters.width = 768;
                  this.form.parameters.height = 1024;
                  break;
                case '7':
                  this.form.parameters.width = 1024;
                  this.form.parameters.height = 768;
                  break;
                case '8':
                  this.form.parameters.width = 1280;
                  this.form.parameters.height = 640;
                  break;
                case '9':
                  this.form.parameters.width = 640;
                  this.form.parameters.height = 1280;
                  break;
                default:
                  console.log('未知的宽高级别');
                  // 可以设置一个默认值
                  this.form.parameters.width = 832;
                  this.form.parameters.height = 1216;
              }
            } else {
              this.form.input = "空";
              // 当队列项为空时，可以设置默认的宽高
              this.form.parameters.width = 832;
              this.form.parameters.height = 1216;
            }
          }
        }




        this.form.input = this.processInput(this.form.input);



        if (!this.isWaiGua) {
          await this.sleep(1000);
        }

        axios.post('/api/user/ai/generate-image3', this.form, {
          headers: {
            'authentication': this.$cookies.get('token')
          },
          timeout: 180000 // 设置30秒的超时时间
        })
            .then(async response => {
              // 处理响应结果
              if (response.data.code === 200) {
                //轮询模式下保证200响应才扣自动次数
                if(this.isLunXun){
                  this.zhidongSize--;
                }



                const newImage = {
                  src: 'data:image/png;base64,' + response.data.data,
                  name: this.riQi ? this.formatDate(new Date()) : response.data.msg
                };

                // 如果启用了bigInfillMode，则合成原始图像和生成的新图像
                if (this.bigInfillModeEnabled && this.actionType==='infill' ) {
                  try {

                    if (this.bigInfillWidth === 640 && this.bigInfillHeight === 640 && this.isLaSheng) {
                      newImage.src = await this.convertImageTo640Size(newImage.src);
                    }

                    const mergedImageBase64 = await this.mergeImages(
                        this.orangeImage,
                        newImage.src,
                        this.centerXPercent,
                        this.centerYPercent
                    );
                    // 创建一个包含合成图的对象，并添加到generImages数组中
                    // console.log("开始合成");
                    const mergedImageObj = {
                      src: mergedImageBase64,
                      name:
                          this.riQi
                              ? this.formatDate(new Date()) + " - Merged"
                              : "Merged - " + response.data.msg,
                    };
                    // this.generImages.unshift(mergedImageObj); // 添加到数组的开头
                    if(this.is)
                      this.addNewImage(mergedImageObj);
                    if (!this.drawerOpened) {
                      this.generImage = mergedImageObj.src; // 可选：如果需要，也更新UI显示合成的图像
                      this.imageName = mergedImageObj.name;
                    }
                  } catch (error) {
                    console.error("图像合成失败:", error);
                  }
                }else{
                  // this.generImages.unshift(newImage);

                  // if (!this.zhidonDown) {  //自动下载模式不存储图到历史记录和预览
                  //   this.addNewImage(newImage);
                  //   if (!this.drawerOpened) { // 只有当抽屉关闭时，才更新generImage和imageName
                  //     this.generImage = newImage.src;
                  //     this.imageName = newImage.name;
                  //   }
                  // }
                  this.addNewImage(newImage);
                  if (!this.drawerOpened) { // 只有当抽屉关闭时，才更新generImage和imageName
                    this.generImage = newImage.src;
                    this.imageName = newImage.name;
                  }
                }

                // 如果zhidonDown为true，自动下载新生成的图片
                if (this.zhidonDown) {
                  this.downloadImageDirectly(newImage.src, newImage.name);
                }
              }else if (response.data.code === 500) {
                this.$message.error(response.data.msg);
              } else if (response.data.code === 208) {
                this.$message.error("无法连接到官方服务器");
                this.buttonColor = '#F5F3C2';
              }

              if((!this.isLunXun)){
                this.zhidongSize--;
              }

              //任务队处理
              if((this.isqueueModeEnabled)){
                this.isqueueRepeatCount++;
                if(this.isqueueRepeatCount>this.queueRepeatCount){
                  this.isqueueRepeatCount = 1;
                  this.isnonEmptyQueueLength++;
                }
              }

              this.getUserInfo();
              this.buttonColor = '#F5F3C2';

            })
            .catch(error => {
              // 处理错误信息
              // 如果错误来自服务器的响应
              if (error.response) {
                // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                if (error.response.status === 413) {
                  this.$message.error('上传的图片过大，服务器无法处理。请尝试减小图片的大小。');
                } else if (error.response.status === 401) {
                  this.$message.error('登录状态过期，请退出重新登录' + error.response.status);
                } else {
                  this.$message.error('发生错误' + error.response.status);
                }
              } else if (error.request) {
                // 请求已发出，但没有收到响应
                this.$message.error('服务器没有响应。请检查你的网络连接。');
              } else {
                // 发生了一些问题，导致了请求的发送
                this.$message.error('请求发送时出错。');
              }
              this.buttonColor = '#F5F3C2';
            })
            .finally(() => {
              this.buttonDisabled = false; // 请求完成后恢复按钮可点击状态
              this.imageLoading = false;
            });
      } else {
        this.stopGenerating();
      }
    },



    downloadImageDirectly2(src, name) {
      // 将Base64编码的图片转换为Blob对象
      fetch(src)
          .then(res => res.blob())
          .then(blob => {
            if (blob.size < 1024) {
              // 如果图片大小小于1KB，则不进行下载
              // this.$message.error('图片大小小于1k，停止下载');
            } else {
              // 图片大小适合，执行下载操作
              this.$message.success('开始下载图片...');
              let link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = name;
              document.body.appendChild(link); // 添加link到body以确保Firefox兼容性
              link.click();
              document.body.removeChild(link); // 下载后从body移除link
              this.$message.success('图片下载成功!');
            }
          })
          .catch(error => {
            // console.error('下载图片出错:', error);
            // this.$message.error('下载图片失败');
          });
    },
    stopGenerating() {
      if (this.worker) {
        this.worker.postMessage({ action: 'stop' });
      }
      this.isLiangXuChecked = false;
      this.zhidongSize = this.customZhidong;

      this.isqueueModeEnabled = false;
      this.isqueueRepeatCount = 1;
      this.isnonEmptyQueueLength = 1;
    },

    inputTag() {

    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // 月份是从0开始的，所以需要+1
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      // 如果月份、日期、小时、分钟或秒数小于10，前面补充一个'0'
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      return year + '-' + month + '-' + day + '-' + hours + '-' + minutes + '-' + seconds;
    },
    getUserInfo() {
      axios.get('/api/user/info', {
        headers: {
          'authentication': this.$cookies.get('token')
        }
      })
          .then(response => {

            this.point.totalPoints = response.data.data.points;
            this.level = response.data.data.level;
            this.point.bigPoints = response.data.data.bigPoints;
            this.userId = response.data.data.id;
            this.extraPoints = response.data.data.extraPoints;

            if (response.data.data.expireTime) {
              let date = new Date(response.data.data.expireTimeShanghai); // 直接使用毫秒级的时间戳
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要+1
              let day = ("0" + date.getDate()).slice(-2);
              this.expireTime = `${year}/${month}/${day}`;
            } else {
              this.expireTime = null;
            }

            // 解析 invitationCode 字段
            let invitationCode = JSON.parse(response.data.data.invitationCode);

            // 只有在初始化时执行
            if (this.isInitialized) {
              // 将值分别赋给相应的变量
              this.form.parameters.steps = invitationCode.steps;
              this.guidance = invitationCode.guidance;
              this.form.parameters.sampler = invitationCode.sampler;
              this.promptRescale = invitationCode.promptRescale;
              this.undesiredStrength = invitationCode.undesiredStrength;
              this.useOfficialPositiveWords = invitationCode.useOfficialPositiveWords;
              this.useOfficialNegativeWords = invitationCode.useOfficialNegativeWords;
              this.customZhidong = invitationCode.customZhidong;
              this.size = invitationCode.size;
              this.zhidonDown = invitationCode.zhidonDown;
              this.isWaiGua = invitationCode.isWaiGua;

              this.handleSizeChange();

              //如果没有使用过保存设置到服务器，那么会导致下面的代码因为报错无法执行
              if (response.data.data.id === 12) {
                this.customZhidong = 500;
                this.customZhidongMax = 500;
              }else if (response.data.data.id === 613) {
                this.customZhidong = 300;
                this.customZhidongMax = 300;
              }else if (response.data.data.id === 1309) {
                this.customZhidong = 300;
                this.customZhidongMax = 300;
              }else if (response.data.data.id === 4974) {
                this.customZhidong = 300;
                this.customZhidongMax = 300;
              }else if (response.data.data.id === 4734) {
                this.customZhidong = 500;
                this.customZhidongMax = 500;
              }
              this.isInitialized = false; // 执行后设置为 false
            }
          })
          .catch(error => {
            // 处理错误信息

            this.buttonColor = '#F5F3C2';
          })
          .finally(() => {
          });
    },
    unUserd() {
      this.form.parameters.negative_prompt = 'nsfw, lowres, {bad}, error, fewer, extra,' +
          'nsfw, lowres, {bad}, error, fewer, extra,' +
          ' missing, worst quality, jpeg artifacts, bad quality, watermark, unfinished,' +
          ' displeasing, chromatic' +
          ' aberration, signature, extra digits, artistic error,' +
          ' username, scan, [abstract]'
    },
    tips() {

      const h = this.$createElement;

      this.$notify({
        title: '点数进Q群：663577906，待实现功能：',
        message: h('i', {style: 'color: teal'}, '1,历史记录打包下载成zip包，2，翻译功能，3，tag日记本,4，tag分享，5，tag漂流瓶')
      })
    },
    showDrawer() {
      // 显示抽屉的逻辑代码
    },
    logout() {
      // 在用户点击退出后执行页面跳转
      this.$parent.showNav = false;
      this.$cookies.remove('token');
      this.$cookies.remove('satoken');
      this.$router.push('/');
    },
    async gener() {

      // console.log(this.$configTags);  // 输出配置数据
      // 初始化音频播放
      if(this.firstAudio){
        this.initAudioPlay();
        this.firstAudio = false;
      }

      //emotion模式提示
      if (this.enableImageUtils && this.utilsImageForm.req_type === 'emotion') {
        const emotionPresets = [
          'neutral', 'happy', 'sad', 'angry', 'scared', 'surprised', 'tired', 'excited',
          'nervous', 'thinking', 'confused', 'shy', 'disgusted', 'smug', 'bored',
          'laughing', 'irritated', 'aroused', 'embarrassed', 'worried', 'love',
          'determined', 'hurt', 'playful'
        ];

        const prompt = this.utilsImageForm.prompt;
        const hasValidEmotion = emotionPresets.some(emotion => prompt.includes(`${emotion};;`));

        if (!hasValidEmotion) {
          this.$message.error('emotion模式至少要求包含一个预设并且两个;;不能去掉');
          return; // 阻止后续操作
        }
      }

      // console.log(this.lastDrawing);

      // console.log(this.mask);

      this.convertImageDataAsync(this.mask, this.reverseBrush)
          .then(endMask => {
            this.endMask = endMask;
            // console.log(this.endMask);
            // 其他操作...
          })
          .catch(error => {
            console.error(error);
          });



      if (this.bigPicture && this.point.bigPoints <= 0) {
        this.$message({
          message: '大图点数不够，请到q群663577906',
          type: 'warning'
        });
        this.showShopping = true;
        return;
      }
      if (this.point.totalPoints === 0 && this.point.bigPoints <= 0) {
        this.$message({
          message: '点数不足',
          type: 'warning'
        });
        this.showShopping = true;
        return;
      }
      if (this.form.input === '' && !this.officeRandom && !this.customRandom && !this.officeRandomArtist) {
        this.$message({
          message: '请输入tag',
          type: 'warning'
        });
        return;
      }

      if (this.form.action === 'infill') {

        if (this.mask2 === ''&&!this.isKuoTu) {
          // console.log("mask2==null")
          this.saveDrawing();
          this.form.parameters.mask = this.mask;
        }
        // console.log(this.form.parameters.mask);

      }
      if ((this.form.action === 'infill' && this.form.parameters.mask === '')&&(!this.isKuoTu)) {
        this.$message({
          message: '使用局部重回功能请先涂抹',
          type: 'warning'
        });
        return;
      }

      // if (this.isTipsInitialized) {
      //   this.$message({
      //     message: '更新日志：新增深度超份目前支持2种尺寸，上线测试，离线超份，一张消耗2点大图点数或者2点超份点数(商店更新)————小破站',
      //     // message: '更新日志：更新了下同步算法解决了局部重绘非常慢的问题————小破站',
      //     // message: '更新日志：由于大图使用过多，导致大图经常出现402，所以大图点数下调至70，和140并且到期后清除（关键官方不会叠加导致用户大图点数累计过多）没有补偿————小破站',
      //     // message: '更新日志：？官网消息：维护中12h？(不论是小图模式还是大图模式只要步数超过28步就必定额外扣一点*大图点数*!!)<教程更新>~~~如果遇到503，说明服务器刚刚更新了，请退出重新登录就ok~~~@@@自动模式上限制已更新至50，可以调到50，$$$局部重绘pc使用缓存功能，同一词涂抹，后续出图会比第一张快10s————小破站',
      //     // message: '更新日志：(′▽`〃)重大更新，修复经常报错问题!!!优化vibe功能，&&&历史记录已优化，图多时候再也不用担心查看历史记录卡了。更新vibe叠图功能注意叠图时候传的图压缩下大小（可能导致网络速度慢）大图模式防止图崩请开启smea!!新加精选画师库，优化截取局部重绘，商店更新。————小破站',
      //     // message: '被算尽的艺术还算艺术嘛————小破站',
      //     // message: '小破站，无限可能!',
      //     // message: '网络恢复，地址中不要加www，手机电脑wifi流量速度全部都正常了，正常速度在10-30s一张！速度不正常就清理浏览器缓存或者换浏览器或关闭梯子',
      //     // message: 'cdn被攻击导致网络坏掉，请用等明天修复，或者使用代理访问地址https://www.nai3.art/home',
      //     type: 'success'
      //   });
      //   this.isTipsInitialized = false;
      // }

      if (this.buttonDisabled) {
        return; // If the button is disabled, do not execute further code
      }

      this.RandomTagsinOffice();


      //开启随机 普通 画师 自定义随机
      let inputParts = [];

      if (this.officeRandom) {
        inputParts.push(this.outOfficeRandom);
      }

      if (this.customRandom) {
        inputParts.push(this.getCustomRandom());
      }

      if (this.officeRandomArtist) {
        inputParts.push(this.getArtistsString());
      }

      if (this.officeRandom || this.customRandom || this.officeRandomArtist) {
        this.form.input = this.RandomPrefix + inputParts.join('') + this.RandomSuffix;
      }


      //"使用官方正面词汇"和"使用官方负面词汇"
      this.naiOfficeTag = this.naiOfficeTag || []; // 确保naiOfficeTag是一个数组

      if (this.useOfficialPositiveWords === true) {
        if (!this.naiOfficeTag.includes(1)) { // 如果数组中没有数字1
          this.naiOfficeTag.push(1); // 向数组添加数字1
        }
      } else {
        let index = this.naiOfficeTag.indexOf(1); // 查找数字1在数组中的位置
        if (index !== -1) { // 如果找到了数字1
          this.naiOfficeTag.splice(index, 1); // 从数组中删除数字1
        }
      }

      if (this.useOfficialNegativeWords === true) {
        if (!this.naiOfficeTag.includes(2)) { // 如果数组中没有数字1
          this.naiOfficeTag.push(2); // 向数组添加数字1
        }
      } else {
        let index = this.naiOfficeTag.indexOf(2); // 查找数字1在数组中的位置
        if (index !== -1) { // 如果找到了数字1
          this.naiOfficeTag.splice(index, 2); // 从数组中删除数字1
        }
      }
      this.buttonDisabled = true; // Disable the button

      this.buttonColor = '#ffffffb3';
      this.imageLoading = true;

      if (this.form.parameters.image !== '' && this.form.parameters.image !== null) {
        if (this.i2iheight < 64) {
          this.i2iheight = 64;
        }
        if (this.i2iwidth < 64) {
          this.i2iwidth = 64;
        }

        if (this.actionType === 'img2img') {
          this.form.parameters.width = this.i2iwidth;
          this.form.parameters.height = this.i2iheight;
          this.form.action = 'img2img';   //jpeg
          this.form.model = 'nai-diffusion-3';
          this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
          this.form.parameters.cfg_rescale = this.usepromptRescale;
          this.form.parameters.uncond_scale = this.undesiredStrength / 100;


          //enhance功能
          if(this.isEnhance&&(!this.bigPicture)){
            let tempWidth = Math.round(this.form.parameters.width * 1.5 / 64) * 64;
            let tempHeight = Math.round(this.form.parameters.height * 1.5 / 64) * 64;

            this.form.parameters.width = tempWidth;
            this.form.parameters.height = tempHeight;
          }

          // 删除 this.form 的 mask 属性
          delete this.form.mask;
        } else if (this.actionType === 'infill') {

          this.form.parameters.width = this.i2iwidth;
          this.form.parameters.height = this.i2iheight;



          this.form.action = 'infill';
          this.form.model = 'nai-diffusion-3-inpainting';
          this.form.isUseBigInfill = this.bigInfillModeEnabled;



          if(this.isKuoTu){
            // console.log("kuotu");
            const originalBase64 = this.orangeImage;


            const shrunkImageData = await this.shrinkImageAndEmbedInCanvas(originalBase64);
            const blackWhiteImageData = await this.generateBlackWhiteImageWithBlackRect(originalBase64);
            // console.log(shrunkImageData);
            // console.log(blackWhiteImageData);
            this.form.parameters.image = shrunkImageData;
            this.form.parameters.mask = blackWhiteImageData;

          }else{

            this.form.parameters.image = this.orangeImage;
            this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');

            if (this.bigInfillModeEnabled) {
              await this.cropImage(this.orangeImage, this.centerXPercent, this.centerYPercent, this.bigInfillWidth, this.bigInfillHeight).then(async croppedImage => {
                // 处理截取后的图像
                this.form.parameters.image = croppedImage;

                //640x640 时进行拉伸放大截取图
                if (this.bigInfillWidth === 640 && this.bigInfillHeight === 640 && this.isLaSheng) {
                  const croppedImage1024 = await this.convertImageTo1024Size(this.form.parameters.image)
                  this.form.parameters.image = croppedImage1024;
                }

              }).catch(error => {
                // 处理错误
                console.error(error);
              });
              await this.cropImage(this.orangeMask, this.centerXPercent, this.centerYPercent, this.bigInfillWidth, this.bigInfillHeight).then(async croppedImage => {
                // 处理截取后的图像
                // console.log(croppedImage);
                let convertedBase64;
                if(!this.isCacheMask){
                  convertedBase64 = await this.convertImageDataAsync(croppedImage, this.reverseBrush);
                  this.form.parameters.mask = convertedBase64;
                  this.cacheMaske = convertedBase64;
                  this.isCacheMask = true;
                }else{
                  this.form.parameters.mask = this.cacheMaske;
                }



                //640x640 时进行拉伸放大mask图
                if(this.bigInfillWidth===640 && this.bigInfillHeight===640 && this.isLaSheng) {
                  if(!this.isCacheMask){
                    const convertedBase641024 = await this.convertImageTo1024Size(convertedBase64)

                    this.form.parameters.mask = convertedBase641024;
                    this.cacheMaske = convertedBase641024;
                    this.isCacheMask = true;
                  }else{
                    this.form.parameters.mask = this.cacheMaske;
                  }
                }

                // console.log(this.form.parameters.mask);
              }).catch(error => {
                // 处理错误
              });

              this.form.parameters.width = this.bigInfillWidth;
              this.form.parameters.height = this.bigInfillHeight;

              if(this.bigInfillWidth===640 && this.bigInfillHeight===640 && this.isLaSheng) {
                this.form.parameters.width = 1024;
                this.form.parameters.height = 1024;
              }

            } else {
              if(!this.isCacheMask){
                const convertedBase64 = await this.convertImageDataAsync(this.mask, this.reverseBrush);
                this.form.parameters.mask = convertedBase64.replace(/^data:image\/(png|jpeg);base64,/, '');
                this.cacheMaske = convertedBase64;
                this.isCacheMask = true;
              }else{
                this.form.parameters.mask = this.cacheMaske;
              }
            }

            if (this.mask2 !== '')  {
              if(!this.isCacheMask){
                const convertedBase64 = await this.convertImageDataAsync(this.mask2, this.reverseBrush);
                this.form.parameters.mask = convertedBase64.replace(/^data:image\/(png|jpeg);base64,/, '');
                this.cacheMaske = convertedBase64;
                this.isCacheMask = true;
              }else{
                this.form.parameters.mask = this.cacheMaske;
              }
            }
          }


          this.form.parameters.image = this.form.parameters.image.replace(/^data:image\/(png|jpeg);base64,/, '');
          this.form.parameters.mask = this.form.parameters.mask.replace(/^data:image\/(png|jpeg);base64,/, '');



          this.form.parameters.strength = this.Strength / 100;
          this.form.parameters.cfg_rescale = this.usepromptRescale;
          this.form.parameters.uncond_scale = this.undesiredStrength / 100;

        }
      }

      if (this.actionType === 'generate') {
        this.subform.input = this.form.input;
        this.subform.action = 'generate';
        this.subform.model = 'nai-diffusion-3';
        this.subform.parameters.steps = this.form.parameters.steps;
        this.subform.parameters.scale = this.form.parameters.scale;
        this.subform.parameters.seed = this.form.parameters.seed;
        this.subform.parameters.width = parseInt(this.selectWidth, 10);
        this.subform.parameters.height = parseInt(this.selectHeight, 10);
        this.subform.parameters.sm = this.form.parameters.sm;
        this.subform.parameters.sm_dyn = this.form.parameters.sm_dyn;
        this.subform.parameters.negative_prompt = this.form.parameters.negative_prompt;
        this.subform.parameters.cfg_rescale = this.usepromptRescale;
        this.subform.parameters.uncond_scale = this.undesiredStrength / 100;
        this.subform.parameters.sampler = this.form.parameters.sampler;

        this.subform.parameters.add_original_image = this.form.parameters.add_original_image;
        this.subform.parameters.dynamic_thresholding = this.form.parameters.dynamic_thresholding;
        this.subform.parameters.noise_schedule = this.form.parameters.noise_schedule;

        this.subform.parameters.reference_image_multiple = this.form.parameters.reference_image_multiple;
        this.subform.parameters.reference_information_extracted_multiple = this.form.parameters.reference_information_extracted_multiple;
        this.subform.parameters.reference_strength_multiple = this.form.parameters.reference_strength_multiple;

        this.subform.parameters.skip_cfg_above_sigma = this.form.parameters.skip_cfg_above_sigma;

        this.form = {};
        this.form = this.subform;
        //===
        if (this.form.parameters.reference_image === '') {
          delete this.form.parameters.reference_image;
          this.form.parameters.add_original_image = false;
        }
      }

      //ddim采样不支持 smea
      if (this.form.parameters.sampler === 'ddim') {
        this.form.parameters.sm = false;
        this.form.parameters.sm_dyn = false;
      }

      this.form.naiOfficeTag = this.naiOfficeTag;
      this.form.input = this.processInput(this.form.input);

      // console.log(this.form.action);
      //
      // console.log(this.mask);

      this.buttonDisabled = true;
      this.buttonColor = "#ffffffb3";
      this.imageLoading = true;

      // if (!this.isWaiGua) {
      //   await this.sleep(2500);
      // }

      let body;
      let url;
      // 图像工具
      if (this.enableImageUtils) {
        body = this.utilsImageForm;
        url = "/api/user/ai/augment-image"
      } else {
        body = this.form;
        url = "/api/user/ai/generate-image3"
      }

      let retries = 0; // Number of retries
      const retryRequest = () => {
        axios
            .post(url, body, {
              headers: {
                authentication: this.$cookies.get("token"),
              },
              timeout: 1800000, // 设置 60 秒的超时时间
            })
            .then(async (response) => {
              // Handle response result
              if (response.data.code === 200) {
                const newImage = {
                  src: "data:image/png;base64," + response.data.data,
                  name: this.riQi ? this.formatDate(new Date()) : response.data.msg,
                };
                this.addNewImage(newImage); // 生成的图添加到历史图片中
                if (!this.drawerOpened) {
                  this.generImage = newImage.src;
                  this.imageName = newImage.name;
                }

                // 如果启用了bigInfillMode，则合成原始图像和生成的新图像
                if (this.bigInfillModeEnabled) {
                  try {

                    if(this.bigInfillWidth===640 && this.bigInfillHeight===640 && this.isLaSheng){
                      newImage.src =  await this.convertImageTo640Size(newImage.src);
                    }

                    const mergedImageBase64 = await this.mergeImages(
                        this.orangeImage,
                        newImage.src,
                        this.centerXPercent,
                        this.centerYPercent
                    );
                    // 创建一个包含合成图的对象，并添加到generImages数组中
                    // console.log("开始合成");
                    const mergedImageObj = {
                      src: mergedImageBase64,
                      name:
                          this.riQi
                              ? this.formatDate(new Date()) + " - Merged"
                              : "Merged - " + response.data.msg,
                    };
                    // this.generImages.unshift(mergedImageObj); // 添加到数组的开头
                    this.addNewImage(mergedImageObj); // 添加合成的图片到 generImages 数组中
                    if (!this.drawerOpened) {
                      this.generImage = mergedImageObj.src; // 可选：如果需要，也更新UI显示合成的图像
                      this.imageName = mergedImageObj.name;
                    }
                  } catch (error) {
                    console.error("图像合成失败:", error);
                  }
                }

                this.buttonColor = "#F5F3C2";
                if (this.zhidonDown) {
                  this.downloadImageDirectly(newImage.src, newImage.name);
                }
                this.getUserInfo();
              } else if (response.data.code === 500) {
                retries--;
                if (retries > 0) {
                  await this.sleep(1000);
                  retryRequest(); // Retry if there are remaining attempts
                } else {
                  this.$message.error(response.data.msg);
                  this.buttonColor = "#F5F3C2";
                }
              }
            })
            .catch((error) => {
              // 处理错误信息
              // 如果错误来自服务器的响应
              if (error.response) {
                // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                if (error.response.status === 413) {
                  this.$message.error(
                      "上传的图片过大，服务器无法处理。请尝试减小图片的大小。"
                  );
                } else if (error.response.status === 401) {
                  this.$message.error(
                      "登录状态过期，请退出重新登录" + error.response.status
                  );
                } else {
                  this.$message.error("发生错误" + error.response.status);
                }
              } else if (error.request) {
                // 请求已发出，但没有收到响应
                this.$message.error("服务器没有响应。请检查你的网络连接。");
              } else {
                // 发生了一些问题，导致了请求的发送
                this.$message.error("请求发送时出错。");
              }
              this.buttonColor = "#F5F3C2";
            })
            .finally(() => {
              this.buttonDisabled = false;
              this.imageLoading = false;
              if (this.bigInfillModeEnabled) {
                // this.form.parameters.image = this.orangeImage;
                // this.form.parameters.mask = this.orangeMask;
              }
            });
      };

      retryRequest(); // Initial call to start the process

      // console.log(this.mask);
    },
    // downloadImage() {
    //   if (!this.generImage) { // 如果没有图片，就不执行任何操作
    //     return;
    //   }
    //
    //   this.$message.success('开始下载图片!');
    //
    //   let link = document.createElement('a');
    //   // console.log(this.generImage);
    //   link.href = this.generImage;
    //   link.download = this.imageName; // 下载选中的图片
    //
    //   this.$message.success('图片下载成功!');
    //   link.click();
    // },
    downloadImage() {
      if (!this.generImage) {
        return;
      }
      this.$message.success('开始下载图片!');
      // 将 base64 编码的图片数据转换为 Blob 对象
      const byteCharacters = atob(this.generImage.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });

      // 创建一个临时的 URL 对象
      const url = URL.createObjectURL(blob);

      // 创建一个下载链接
      const link = document.createElement('a');
      link.href = url;
      link.download = this.imageName;

      // 将链接添加到文档中
      document.body.appendChild(link);

      // 模拟点击链接进行下载
      link.click();

      // 下载完成后，从文档中移除链接并释放 URL 对象
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      this.$message.success('图片下载成功!');
    },
    showQuest() {
      const tutorialLink = 'https://pxp8w0fi496.feishu.cn/wiki/WtahwylpXihHE2kS7NVcemARnPh?from=from_copylink';
      const message = `当前模型：nai3，导航栏为：退出，会员等级，点数，大图点数，模型。交流群663577906，交流tag和充值点数。<a href="${tutorialLink}" target="_blank">教程文档</a>`;

      this.$alert(message, '提示', {
        confirmButtonText: '确定',
        closeOnPressEscape: true,
        closeOnClickModal: true,
        dangerouslyUseHTMLString: true, // 这一行是新增的
        callback: action => {}
      });
    },


    formatTooltip(val) {
      this.form.parameters.scale = val / 10;
      return val / 10;
    },
    formatTooltipStrength(val) {
      this.form.parameters.strength = val / 100;
      return val / 100;
    },
    formatTooltipNoise(val) {
      this.form.parameters.noise = val / 100;
      return val / 100;
    },
    formatTooltipRescale(val) {
      const scaledValue = (val / 100).toFixed(2);
      this.usepromptRescale = parseFloat(scaledValue);
      this.form.parameters.cfg_rescale = parseFloat(scaledValue);
      return scaledValue;
    },
    formatTooltipUnContStrength(val) {
      this.form.parameters.uncond_scale = val / 100;
      return val;
    },

    handleSizeChange() {
      const sizeMap = {
        '长图': { width: '832', height: '1216' },
        '宽图': { width: '1216', height: '832' },
        '正方形': { width: '1024', height: '1024' },
        '等比1080长图': { width: '1280', height: '768' },
        '等比1080竖图': { width: '768', height: '1280' },
        '等比1024竖图小图': { width: '768', height: '1024' },
        '等比1024长图小图': { width: '1024', height: '768' },
        '1:2长图': { width: '1280', height: '640' },
        '1:2宽图': { width: '640', height: '1280' },
        '抱枕1:3': { width: '576', height: '1728' },
        '抱枕1:2.5': { width: '640', height: '1600' },
        '竖条1500': { width: '704', height: '1472' },
        '横条1500': { width: '1472', height: '704' },
        '测试': { width: '128', height: '128' }
      };

      const size = sizeMap[this.size];
      if (size) {
        this.form.parameters.width = size.width;
        this.selectWidth = size.width;
        this.form.parameters.height = size.height;
        this.selectHeight = size.height;
      }
      // 在这里可以添加其他处理选择变化的逻辑
    },


    toggleDynCheckbox() {
      if (!this.form.parameters.sm) {
        this.form.parameters.sm_dyn = false;
      }
    },

  }
};
</script>

<style scoped>

.page-container {

}

.floating-window {
  position: fixed;
  left: 30%;
  top: 1vh;
  width: 16%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 3px;
  overflow-y: auto;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.tag-list {
  display: flex;
  flex-direction: column;
}

.tag-item {
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer; /* 添加鼠标指针样式 */
}

.tag-key {
  font-weight: bold;
  color: #d0d0d0;
  margin-right: 4px;
}

.tag-value {
  color: #d0d0d0;
}

.loading {
  text-align: center;
  color: #d0d0d0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 900px;  /* 设置对话框的最大宽度 */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  color: #aaa;
  font-size: 14px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

>>> .my-textarea textarea {
  height: 20vh !important;
}

::v-deep .custom-textarea textarea {
  height: 100px;
  width: 360px;
}
.custom-checkbox {
  height: 3.5vh;
  background-color: transparent;
  color: white;
  border: 2px solid #646970;
  padding-left: 1px;
  padding-right: 1px;
  line-height: 3.5vh;
}
.custom-checkbox:hover {
  color: #409EFF;
}
/* 隐藏滚动条轨道 */
::-webkit-scrollbar-track {
  background: transparent!important; /* 设置轨道为透明 */
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 5px!important; /* 设置滚动条的宽度 */
}

/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5)!important; /* 设置滑块为半透明白色 */
  border-radius: 4px!important; /* 设置滑块的圆角 */
}
#imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1e2d2b; /* 可以提供视觉反馈的边框样式 */
}
.selected {
  border: 2px solid cyan;
}

/* 电脑端样式 */
@media (min-width: 768px) {
  .el-drawer {
    background-color: transparent!important;
  }

  .home {
    display: flex;
  }
  .main-container {
    flex: auto;
    background-color: #1d2327;
    width: 100%;
    height: 100vh;
    position: relative; /* 为绝对定位的子元素提供参考 */
  }
  .flex-container {
    display: flex;
  }
  .sidebar {
    background-color: #1d2327;
    width: 30%;
    height: 100vh;
    box-sizing: border-box;
    border-width: 0px 2px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .header {
    background-color: #1d2327;
    height: 5vh;
  }
  .header-content {

  }
  .exit {
    width: 10%;
    box-sizing: border-box;
    border-width: 0px 2px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .logout {
    color: white;
    line-height: 5vh;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .divlimitPoints{
    width: 25%;
    margin-right: auto;
  }
  .limitePoints{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    color: white;
    line-height: 5vh;
  }
  .divPoints{
    width: 30%;
    margin-right: auto;
    box-sizing: border-box;
    border-width: 0px 2px 0px 2px;
    border-style: solid;
    border-color: #646970;
  }
  .points {
    color: white;
    line-height: 5vh;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .divMoxing{
    width: 30%;
    margin-left: auto;
  }
  .moxing{
    color: white;
    line-height: 5vh;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .hear-container-left-top{
    background-color: #1d2327;
    height: 85vh;
    box-sizing: border-box;
    border-width: 2px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;
  }

  .header-item-Prompt {
    background: #1d2327;
  }
  .divprompt {
    padding: 0px;
    box-sizing: border-box;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .prompt{
    width: 95%;
    height: 20vh;
    padding: 10px;
    font-size: 16px;
    border: 0px solid #ccc;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    resize: none;
    background: #13152c;
    color: white;
  }
  .divUndesired{
    padding-top: 0px;
    box-sizing: border-box;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .divFangyidesired{
    padding-top: 0px;
    box-sizing: border-box;
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    border-color: #646970;
  }
  .Undesired{
    width: 95%;
    height: 10vh;
    padding: 10px;
    font-size: 16px;
    border: 0px solid #ccc;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    resize: none;
    background: #13152c;
    color: white;
  }
  .div-hear-step{
    padding-top: 0px;
    box-sizing: border-box;
    border-width: 2px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .div-em-step{
    display: flex
  }
  .div-in-step{
    width: 20%;
    margin: auto;
    color: white;
  }
  .h3-step{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .step-div {
    width: 80%;
  }
  .step-slider{
    width: 80%;
    margin: auto;
  }
  .div-div-guidance{
    display: flex;
    box-sizing: border-box;
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .div-guidance{
    width: 20%;
    margin: auto;
    color: white;
  }
  .h6-guidance{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .div-guidance-slider{
    width: 80%;
  }
  .slider-guidance{
    width: 80%; margin: auto;
  }
  .div-div-el-seed{
    box-sizing: border-box;
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .el-row-seed{
    height: 5vh
  }
  .el-col-seed{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    box-sizing: border-box;
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .seed{
    width: 83%;
    height: 2vh;
    padding: 10px;
    font-size: 12px;
    border: 0px solid #ccc;
    border-radius: 5px;
    overflow-y: auto;
    resize: none;
    background: #13152c;
    color: white;
  }
  .div-select-size{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    box-sizing: border-box;
    border-width: 0px 0px 0px 2px;
    border-style: solid;
    border-color: #646970;
  }
  .select-size{
    width: 100%;
    background-color: #13152c;
    height: 5vh;
    color: white;
  }
  .select-euler{
    width: 100%;
    background-color: #13152c;
    height: 5vh;
    color: white;
  }
  .div-upload-icon{
    width: 100%;
    height: 5vh;
    box-sizing: border-box;
    border-width: 0px 0px 0px 2px;
    border-style: solid;
    border-color: #646970;
  }
  .upload-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color:#13152c
  }
  .div-smea-checkbox{
    box-sizing: border-box;
    border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .div-smea-row{
    background-color: #13152c;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .labal-smea{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .labal-dyn{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .div-div-zhidong{
    height: 5vh;
    box-sizing: border-box;
    border-width: 0px 0px 0px 2px;
    border-style: solid;
    border-color: #646970;
    display: flex;
    align-items: center;
  }
  .div-labal-zhidong{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
  .div-hear-gener{
    background-color: #13152c;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: #646970;
  }
  .div-gener{
    display: flex;
    background-color: #F5F3C2;
    width: 95%;
    height: 6vh;
    border-radius: 5px;
    align-items: center;
  }
  .h4-gener{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    color: #2c3e50;
  }
  .img-background-div-div{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
    height: 100vh;
    background-color: #13152c;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .img-background-div{
    display: flex;
    height: 100vh;
  }
  .img-gener{
    max-width: 95%;
    max-height: 95%;
    object-fit: contain;
    align-self: center
  }
  .img-hisor{
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    padding: 25px;
    cursor: pointer;
  }
}

/* 公共样式 */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.5em auto;
  border: 1px solid #13152c;
  border-radius: 0;
  color: white;
  padding: 0.5em;
  width: 100%;
  -webkit-border-radius: 0;
}
/* 修改滑动条颜色和轨道透明度 */
textarea::-webkit-scrollbar {
  width: 6px; /* 设置滑动条的宽度 */
}

textarea::-webkit-scrollbar-track {
  background-color: transparent; /* 设置滑动条的轨道为透明 */
}


textarea::-webkit-scrollbar-thumb {
  background-color: #999; /* 设置滑块的背景颜色 */
  border-radius: 6px; /* 设置滑块的圆角 */
}
/* 修改滑动条颜色和轨道透明度 */
::-webkit-scrollbar {
  width: 6px; /* 设置滑动条宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: transparent !important; /* 设置滑动条颜色 */
}

::-webkit-scrollbar-track {
  background-color: transparent !important; /* 设置滑动条的轨道为透明 */
}

::-webkit-scrollbar-thumb {
  background-color: #13152c !important; /* 设置滑块的背景颜色 */
  border-radius: 6px; /* 设置滑块的圆角 */
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #666; /* 设置滑块的鼠标悬停时的背景颜色 */
}
select:focus{
  outline: none; /* 取消默认的焦点边框 */
}

textarea:focus {
  outline: none; /* 取消默认的焦点边框 */
  border-color: blue; /* 设置文本域的边框颜色为蓝色 */
}
input:focus {
  outline: none; /* 取消默认的焦点边框 */
  border-color: blue; /* 设置文本域的边框颜色为蓝色 */
}
.el-slider__bar {
  background-color: #13152c !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
}
.el-slider__runway {
  width: 100%;
  height: 6px;
  margin: 16px 0;
  background-color: #13152c !important;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}
.el-slider__button {
  width: 14px !important;
  height: 14px !important;
  border: 2px solid #F5F3C2 !important;
  background-color: #F5F3C2 !important;
  border-radius: 50%;
  transition: .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
